<div class="authentication-wrapper authentication-2 px-4">
    <div class="authentication-inner py-5">
        <!-- Form -->
        <div class="card">
            <div class="p-4 p-sm-5" *ngIf="user">
                <div
                    class="display-1 lnr lnr-checkmark-circle text-center text-success mb-4"
                ></div>

                <p class="text-center text-big mb-4">
                    Please verify your email by following the link sent to
                    {{ user.email }}.
                </p>
                <small
                    >Not you?
                    <a href="javascript:void(0)" (click)="authService.logout()">
                        Login as another user</a
                    ></small
                >

                <button
                    type="button"
                    class="btn btn-primary btn-block"
                    [disabled]="!user"
                    (click)="resendEmail()"
                >
                    Resend Email
                </button>
            </div>
        </div>
        <!-- / Form -->
    </div>
</div>
