// @flow
import { Injectable, Injector } from '@angular/core';
import { ItemService } from './item.service';

import type { PlanFrontend as Plan } from '../../server/api/plan/plan';
export type { PlanFrontend as Plan } from '../../server/api/plan/plan';

@Injectable()
export class PlanService extends ItemService<Plan> {
    modelName = 'plans';

    constructor(injector: Injector) {
        super(injector);
    }
}
