// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

import type { SubscriptionFrontend as Subscription } from '../../server/api/subscription/subscription';
export type { SubscriptionFrontend as Subscription } from '../../server/api/subscription/subscription';

@Injectable()
export class SubscriptionService extends BaseService<Subscription> {
    modelName = 'subscriptions';

    constructor(injector: Injector) {
        super(injector);
    }

    generateInvoice(subscription: Subscription) {
        return this.http.post<void>(
            `/api/subscriptions/${subscription._id}/invoice`,
            {}
        );
    }

    cancel(id: string): Observable<Subscription> {
        return this.http.post<Subscription>(
            `/api/${this.modelName}/${id}/cancel`,
            {}
        );
    }

    cancelItem(id: string, itemId: string): Observable<Subscription> {
        return this.http.post<Subscription>(
            `/api/${this.modelName}/${id}/cancelItem`,
            { itemId: itemId }
        );
    }
    
    reactivateItem(id: string, itemId: string): Observable<Subscription> {
        return this.http.post<Subscription>(
            `/api/${this.modelName}/${id}/reactivateItem`,
            { itemId: itemId }
        );
    }

    getNext(): Observable<Subscription> {
        return this.http.get<Subscription>(`/api/${this.modelName}/next`);
    }

    getSummary(type: 'client' | 'admin') {
        return this.http.get(`/api/${this.modelName}/${type}/summary`);
    }

    getTopClients() {
        return this.http.get(`/api/${this.modelName}/admin/topClients`);
    }
}
