<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">


    <!-- Sidenav toggle (see demo.css) -->
    <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center" *ngIf="sidenavToggle">
        <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
            <i class="ion ion-md-menu text-large align-middle"></i>
        </a>
    </div>
    <!-- Brand demo (see demo.css) -->
    <!--<a routerLink="/" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">-->
    <!--<span class="app-brand-logo demo">-->
    <!--<object type="image/svg+xml" data="assets/img/bling_logo.svg" class="logo" style="width:100%;height:100%;">-->
    <!--B'ling Logo-->
    <!--</object>-->
    <!--</span>-->
    <!--</a>-->

    <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2">

        <div class="navbar-nav align-items-lg-center">
            <!--Account Select-->
            <div ngbDropdown  class="demo-navbar-user nav-item">
                <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
                    <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                        <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{currentAccount.name}}</span>
                    </span>
                </a>
                <div ngbDropdownMenu  ng-if="user">
                    <div [perfectScrollbar] style="max-height: 70vh;">
                        <span class="dropdown-item" style="cursor:pointer" *ngFor="let account of user.accounts | accountFilter:searchAccount" (click)="setCurrentAccount(account)">
                            <div>
                                <img [src]="'api/accounts/'+currentAccount.ref+'/image'" alt class="ui-w-30">
                                {{account.name}}
                            </div>
                        </span>
                    </div>
                    <div class="dropdown-divider"></div>
                    <input type="text" style="padding:0.5em;" class="form-control" [(ngModel)]="searchAccount" placeholder="Search Account..."/>
                </div>
            </div>

        </div>
        <div class="navbar-nav align-items-lg-center ml-auto">
            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-notifications nav-item mr-lg-3"  [hidden]="!('manage' | able:'RemoteAuth')" #notificationsDropdown="ngbDropdown">
                <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
                    <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                    <span class="badge badge-primary indicator" [class.badge-dot]="activeTasks.length"></span>
                    <span class="d-lg-none align-middle">&nbsp; Notifications</span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        {{activeTasks.length}} New Notifications
                    </div>
                    <div class="list-group list-group-flush" style='max-height: 60vh;'>
                        <span class="list-group-item list-group-item-action media align-items-center" style="display:flex;align-items:center" *ngFor="let task of activeTasks">
                            <!-- <span class="fa-stack fa-2x m-1">
                                <i class="fa fa-circle fa-stack-2x" style="color:#748091;"></i>
                                <i class="fas fa-user-lock fa-stack-1x" style="color:white;"></i>
                            </span> -->
                            <div class="media-body line-height-condenced" style="flex-grow:1;">
                                <div class="text-body">Access Requested by {{task.userName}}</div>
                                <div class="text-light small mt-1">
                                    {{task.userName}} requested access to {{(task.data.authAction && (task.data.authAction !== 'Unknown' || task.data.authAction !== 'None')) ? (task.data.authAction | lowercase) + ' ' : ''}}{{task.doorName}} on {{task.siteName}}
                                </div>
                                <!--<div class="text-light small mt-1">12h ago</div>-->
                            </div>
                            <div  *ngIf="task.status === 'Requested'">
                                <circle-timer [start]="$any(task.createdAt)" [time]="task.data.expiresAfterSeconds"></circle-timer>
                            </div>
                            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;" *ngIf="task.status === 'Requested'">
                                <button class='btn btn-primary m-1' style="width:100%" (click)="grantAccess(task._id)" [disabled]="task.user === user._id">GRANT</button>
                                <button class='btn btn-secondary m-1' style="width:100%" (click)="denyAccess(task._id)" [disabled]="task.user === user._id">DENY</button>
                            </div>
                            <div style="align-items:center;" *ngIf="task.status !== 'Requested'">
                                <b>
                                    {{task.status | uppercase}}
                                </b>
                                <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1" *ngIf="task.status === 'Expired' || task.status === 'Granted' || task.status === 'Denied'" (click)="clearTask(task)">Clear</a>
                            </div>
                        </span>
                    </div>
                    <!--<a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Load More</a>-->
                </div>
            </div>

            <!--<div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-messages nav-item mr-lg-3">-->
            <!--<a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">-->
            <!--<i class="ion ion-ios-mail navbar-icon align-middle"></i>-->
            <!--<span class="badge badge-primary badge-dot indicator"></span>-->
            <!--<span class="d-lg-none align-middle">&nbsp; Messages</span>-->
            <!--</a>-->
            <!--<div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">-->
            <!--<div class="bg-primary text-center text-white font-weight-bold p-3">-->
            <!--4 New Messages-->
            <!--</div>-->
            <!--<div class="list-group list-group-flush">-->
            <!--<a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">-->
            <!--<img src="assets/img/avatars/6-small.png" class="d-block ui-w-40 rounded-circle" alt>-->
            <!--<div class="media-body ml-3">-->
            <!--<div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>-->
            <!--<div class="text-light small mt-1">-->
            <!--Mae Gibson &nbsp;·&nbsp; 58m ago-->
            <!--</div>-->
            <!--</div>-->
            <!--</a>-->

            <!--<a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">-->
            <!--<img src="assets/img/avatars/4-small.png" class="d-block ui-w-40 rounded-circle" alt>-->
            <!--<div class="media-body ml-3">-->
            <!--<div class="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>-->
            <!--<div class="text-light small mt-1">-->
            <!--Kenneth Frazier &nbsp;·&nbsp; 1h ago-->
            <!--</div>-->
            <!--</div>-->
            <!--</a>-->

            <!--<a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">-->
            <!--<img src="assets/img/avatars/5-small.png" class="d-block ui-w-40 rounded-circle" alt>-->
            <!--<div class="media-body ml-3">-->
            <!--<div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>-->
            <!--<div class="text-light small mt-1">-->
            <!--Nelle Maxwell &nbsp;·&nbsp; 2h ago-->
            <!--</div>-->
            <!--</div>-->
            <!--</a>-->

            <!--<a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">-->
            <!--<img src="assets/img/avatars/11-small.png" class="d-block ui-w-40 rounded-circle" alt>-->
            <!--<div class="media-body ml-3">-->
            <!--<div class="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>-->
            <!--<div class="text-light small mt-1">-->
            <!--Belle Ross &nbsp;·&nbsp; 5h ago-->
            <!--</div>-->
            <!--</div>-->
            <!--</a>-->
            <!--</div>-->

            <!--<a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all messages</a>-->
            <!--</div>-->
            <!--</div>-->

            <!-- Divider -->
            <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
                <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
                    <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                        <img [src]="user.img" alt class="d-block ui-w-30 rounded-circle" crossorigin>
                        <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{user.name}}</span>
                    </span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</a>
                    <a [routerLink]="['/accPlaceholder/users/settings']" class="dropdown-item"><i class="ion ion-md-settings text-lightest"></i> &nbsp; User settings</a>
                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0)" class="dropdown-item" (click)="authService.logout()"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</a>
                </div>
            </div>
        </div>
    </div>
</nav>

