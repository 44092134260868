// @flow
import { Injectable, Injector } from '@angular/core';
import { ItemService } from './item.service';

import type { AddonFrontend as Addon } from '../../server/api/addon/addon';
export type { AddonFrontend as Addon } from '../../server/api/addon/addon';

@Injectable()
export class AddonService extends ItemService<Addon> {
    modelName = 'addons';

    constructor(injector: Injector) {
        super(injector);
    }
}
