// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

import { WebhookMethods, WebhookEvents } from 'client/app/app.constants';
import type { WebhookFrontend as Webhook } from 'server/api/webhook/webhook';
export type { WebhookFrontend as Webhook } from 'server/api/webhook/webhook';

@Injectable()
export class WebhookService extends BaseService<Webhook> {
    modelName = 'webhooks';

    constructor(injector: Injector) {
        super(injector);
    }
}
export { WebhookMethods, WebhookEvents };
