/** Angular Imports */
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

/** Modules Imports */
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng9PasswordStrengthBarModule } from 'ng9-password-strength-bar';

/** App Modules */
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { DirectivesModule } from '../../components/directives.module';
import { SafePipeModule } from '../../components/safe-pipe/safePipe.module';
import { TOTPModule } from '../../components/totp/totp.module';

/** Components Imports */
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { EmailRequestComponent } from './email-request/email-request.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RegisterComponent } from './register/register.component';

// *******************************************************************************
//

@NgModule({
    imports: [
        AngularSvgIconModule,
        AuthenticationRoutingModule,
        CommonModule,
        DirectivesModule,
        FontAwesomeModule,
        FormsModule,
        Ng9PasswordStrengthBarModule,
        SafePipeModule,
        TOTPModule,
    ],
    declarations: [
        EmailConfirmComponent,
        EmailRequestComponent,
        LockScreenComponent,
        LoginComponent,
        PasswordResetComponent,
        RegisterComponent,
    ],
})
export class AuthenticationModule {}
