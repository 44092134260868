(function UMDish(name, context, definition, plugins) {
  context[name] = definition.call(context);

  for (var i = 0; i < plugins.length; i++) {
    plugins[i](context[name]);
  }

  if (typeof module !== "undefined" && module.exports) {
    module.exports = context[name];
  } else if (typeof define === "function" && define.amd) {
    define(function reference() {
      return context[name];
    });
  }
})("Primus", this || {}, function wrapper() {
  var define,
      module,
      exports,
      Primus = function () {
    function r(e, n, t) {
      function o(i, f) {
        if (!n[i]) {
          if (!e[i]) {
            var c = "function" == typeof require && require;
            if (!f && c) return c(i, !0);
            if (u) return u(i, !0);
            var a = new Error("Cannot find module '" + i + "'");
            throw a.code = "MODULE_NOT_FOUND", a;
          }

          var p = n[i] = {
            exports: {}
          };
          e[i][0].call(p.exports, function (r) {
            var n = e[i][1][r];
            return o(n || r);
          }, p, p.exports, r, e, n, t);
        }

        return n[i].exports;
      }

      for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);

      return o;
    }

    return r;
  }()({
    1: [function (_dereq_, module, exports) {
      'use strict';
      /**
       * Create a function that will cleanup the instance.
       *
       * @param {Array|String} keys Properties on the instance that needs to be cleared.
       * @param {Object} options Additional configuration.
       * @returns {Function} Destroy function
       * @api public
       */

      module.exports = function demolish(keys, options) {
        var split = /[, ]+/;
        options = options || {};
        keys = keys || [];
        if ('string' === typeof keys) keys = keys.split(split);
        /**
         * Run addition cleanup hooks.
         *
         * @param {String} key Name of the clean up hook to run.
         * @param {Mixed} selfie Reference to the instance we're cleaning up.
         * @api private
         */

        function run(key, selfie) {
          if (!options[key]) return;
          if ('string' === typeof options[key]) options[key] = options[key].split(split);
          if ('function' === typeof options[key]) return options[key].call(selfie);

          for (var i = 0, type, what; i < options[key].length; i++) {
            what = options[key][i];
            type = typeof what;

            if ('function' === type) {
              what.call(selfie);
            } else if ('string' === type && 'function' === typeof selfie[what]) {
              selfie[what]();
            }
          }
        }
        /**
         * Destroy the instance completely and clean up all the existing references.
         *
         * @returns {Boolean}
         * @api public
         */


        return function destroy() {
          var selfie = this,
              i = 0,
              prop;
          if (selfie[keys[0]] === null) return false;
          run('before', selfie);

          for (; i < keys.length; i++) {
            prop = keys[i];

            if (selfie[prop]) {
              if ('function' === typeof selfie[prop].destroy) selfie[prop].destroy();
              selfie[prop] = null;
            }
          }

          if (selfie.emit) selfie.emit('destroy');
          run('after', selfie);
          return true;
        };
      };
    }, {}],
    2: [function (_dereq_, module, exports) {
      'use strict';
      /**
       * Returns a function that when invoked executes all the listeners of the
       * given event with the given arguments.
       *
       * @returns {Function} The function that emits all the things.
       * @api public
       */

      module.exports = function emits() {
        var self = this,
            parser;

        for (var i = 0, l = arguments.length, args = new Array(l); i < l; i++) {
          args[i] = arguments[i];
        } //
        // If the last argument is a function, assume that it's a parser.
        //


        if ('function' !== typeof args[args.length - 1]) return function emitter() {
          for (var i = 0, l = arguments.length, arg = new Array(l); i < l; i++) {
            arg[i] = arguments[i];
          }

          return self.emit.apply(self, args.concat(arg));
        };
        parser = args.pop();
        /**
         * The actual function that emits the given event. It returns a boolean
         * indicating if the event was emitted.
         *
         * @returns {Boolean}
         * @api public
         */

        return function emitter() {
          for (var i = 0, l = arguments.length, arg = new Array(l + 1); i < l; i++) {
            arg[i + 1] = arguments[i];
          }
          /**
           * Async completion method for the parser.
           *
           * @param {Error} err Optional error when parsing failed.
           * @param {Mixed} returned Emit instructions.
           * @api private
           */


          arg[0] = function next(err, returned) {
            if (err) return self.emit('error', err);
            arg = returned === undefined ? arg.slice(1) : returned === null ? [] : returned;
            self.emit.apply(self, args.concat(arg));
          };

          parser.apply(self, arg);
          return true;
        };
      };
    }, {}],
    3: [function (_dereq_, module, exports) {
      'use strict';

      var has = Object.prototype.hasOwnProperty,
          prefix = '~';
      /**
       * Constructor to create a storage for our `EE` objects.
       * An `Events` instance is a plain object whose properties are event names.
       *
       * @constructor
       * @private
       */

      function Events() {} //
      // We try to not inherit from `Object.prototype`. In some engines creating an
      // instance in this way is faster than calling `Object.create(null)` directly.
      // If `Object.create(null)` is not supported we prefix the event names with a
      // character to make sure that the built-in object properties are not
      // overridden or used as an attack vector.
      //


      if (Object.create) {
        Events.prototype = Object.create(null); //
        // This hack is needed because the `__proto__` property is still inherited in
        // some old browsers like Android 4, iPhone 5.1, Opera 11 and Safari 5.
        //

        if (!new Events().__proto__) prefix = false;
      }
      /**
       * Representation of a single event listener.
       *
       * @param {Function} fn The listener function.
       * @param {*} context The context to invoke the listener with.
       * @param {Boolean} [once=false] Specify if the listener is a one-time listener.
       * @constructor
       * @private
       */


      function EE(fn, context, once) {
        this.fn = fn;
        this.context = context;
        this.once = once || false;
      }
      /**
       * Add a listener for a given event.
       *
       * @param {EventEmitter} emitter Reference to the `EventEmitter` instance.
       * @param {(String|Symbol)} event The event name.
       * @param {Function} fn The listener function.
       * @param {*} context The context to invoke the listener with.
       * @param {Boolean} once Specify if the listener is a one-time listener.
       * @returns {EventEmitter}
       * @private
       */


      function addListener(emitter, event, fn, context, once) {
        if (typeof fn !== 'function') {
          throw new TypeError('The listener must be a function');
        }

        var listener = new EE(fn, context || emitter, once),
            evt = prefix ? prefix + event : event;
        if (!emitter._events[evt]) emitter._events[evt] = listener, emitter._eventsCount++;else if (!emitter._events[evt].fn) emitter._events[evt].push(listener);else emitter._events[evt] = [emitter._events[evt], listener];
        return emitter;
      }
      /**
       * Clear event by name.
       *
       * @param {EventEmitter} emitter Reference to the `EventEmitter` instance.
       * @param {(String|Symbol)} evt The Event name.
       * @private
       */


      function clearEvent(emitter, evt) {
        if (--emitter._eventsCount === 0) emitter._events = new Events();else delete emitter._events[evt];
      }
      /**
       * Minimal `EventEmitter` interface that is molded against the Node.js
       * `EventEmitter` interface.
       *
       * @constructor
       * @public
       */


      function EventEmitter() {
        this._events = new Events();
        this._eventsCount = 0;
      }
      /**
       * Return an array listing the events for which the emitter has registered
       * listeners.
       *
       * @returns {Array}
       * @public
       */


      EventEmitter.prototype.eventNames = function eventNames() {
        var names = [],
            events,
            name;
        if (this._eventsCount === 0) return names;

        for (name in events = this._events) {
          if (has.call(events, name)) names.push(prefix ? name.slice(1) : name);
        }

        if (Object.getOwnPropertySymbols) {
          return names.concat(Object.getOwnPropertySymbols(events));
        }

        return names;
      };
      /**
       * Return the listeners registered for a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @returns {Array} The registered listeners.
       * @public
       */


      EventEmitter.prototype.listeners = function listeners(event) {
        var evt = prefix ? prefix + event : event,
            handlers = this._events[evt];
        if (!handlers) return [];
        if (handlers.fn) return [handlers.fn];

        for (var i = 0, l = handlers.length, ee = new Array(l); i < l; i++) {
          ee[i] = handlers[i].fn;
        }

        return ee;
      };
      /**
       * Return the number of listeners listening to a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @returns {Number} The number of listeners.
       * @public
       */


      EventEmitter.prototype.listenerCount = function listenerCount(event) {
        var evt = prefix ? prefix + event : event,
            listeners = this._events[evt];
        if (!listeners) return 0;
        if (listeners.fn) return 1;
        return listeners.length;
      };
      /**
       * Calls each of the listeners registered for a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @returns {Boolean} `true` if the event had listeners, else `false`.
       * @public
       */


      EventEmitter.prototype.emit = function emit(event, a1, a2, a3, a4, a5) {
        var evt = prefix ? prefix + event : event;
        if (!this._events[evt]) return false;
        var listeners = this._events[evt],
            len = arguments.length,
            args,
            i;

        if (listeners.fn) {
          if (listeners.once) this.removeListener(event, listeners.fn, undefined, true);

          switch (len) {
            case 1:
              return listeners.fn.call(listeners.context), true;

            case 2:
              return listeners.fn.call(listeners.context, a1), true;

            case 3:
              return listeners.fn.call(listeners.context, a1, a2), true;

            case 4:
              return listeners.fn.call(listeners.context, a1, a2, a3), true;

            case 5:
              return listeners.fn.call(listeners.context, a1, a2, a3, a4), true;

            case 6:
              return listeners.fn.call(listeners.context, a1, a2, a3, a4, a5), true;
          }

          for (i = 1, args = new Array(len - 1); i < len; i++) {
            args[i - 1] = arguments[i];
          }

          listeners.fn.apply(listeners.context, args);
        } else {
          var length = listeners.length,
              j;

          for (i = 0; i < length; i++) {
            if (listeners[i].once) this.removeListener(event, listeners[i].fn, undefined, true);

            switch (len) {
              case 1:
                listeners[i].fn.call(listeners[i].context);
                break;

              case 2:
                listeners[i].fn.call(listeners[i].context, a1);
                break;

              case 3:
                listeners[i].fn.call(listeners[i].context, a1, a2);
                break;

              case 4:
                listeners[i].fn.call(listeners[i].context, a1, a2, a3);
                break;

              default:
                if (!args) for (j = 1, args = new Array(len - 1); j < len; j++) {
                  args[j - 1] = arguments[j];
                }
                listeners[i].fn.apply(listeners[i].context, args);
            }
          }
        }

        return true;
      };
      /**
       * Add a listener for a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @param {Function} fn The listener function.
       * @param {*} [context=this] The context to invoke the listener with.
       * @returns {EventEmitter} `this`.
       * @public
       */


      EventEmitter.prototype.on = function on(event, fn, context) {
        return addListener(this, event, fn, context, false);
      };
      /**
       * Add a one-time listener for a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @param {Function} fn The listener function.
       * @param {*} [context=this] The context to invoke the listener with.
       * @returns {EventEmitter} `this`.
       * @public
       */


      EventEmitter.prototype.once = function once(event, fn, context) {
        return addListener(this, event, fn, context, true);
      };
      /**
       * Remove the listeners of a given event.
       *
       * @param {(String|Symbol)} event The event name.
       * @param {Function} fn Only remove the listeners that match this function.
       * @param {*} context Only remove the listeners that have this context.
       * @param {Boolean} once Only remove one-time listeners.
       * @returns {EventEmitter} `this`.
       * @public
       */


      EventEmitter.prototype.removeListener = function removeListener(event, fn, context, once) {
        var evt = prefix ? prefix + event : event;
        if (!this._events[evt]) return this;

        if (!fn) {
          clearEvent(this, evt);
          return this;
        }

        var listeners = this._events[evt];

        if (listeners.fn) {
          if (listeners.fn === fn && (!once || listeners.once) && (!context || listeners.context === context)) {
            clearEvent(this, evt);
          }
        } else {
          for (var i = 0, events = [], length = listeners.length; i < length; i++) {
            if (listeners[i].fn !== fn || once && !listeners[i].once || context && listeners[i].context !== context) {
              events.push(listeners[i]);
            }
          } //
          // Reset the array, or remove it completely if we have no more listeners.
          //


          if (events.length) this._events[evt] = events.length === 1 ? events[0] : events;else clearEvent(this, evt);
        }

        return this;
      };
      /**
       * Remove all listeners, or those of the specified event.
       *
       * @param {(String|Symbol)} [event] The event name.
       * @returns {EventEmitter} `this`.
       * @public
       */


      EventEmitter.prototype.removeAllListeners = function removeAllListeners(event) {
        var evt;

        if (event) {
          evt = prefix ? prefix + event : event;
          if (this._events[evt]) clearEvent(this, evt);
        } else {
          this._events = new Events();
          this._eventsCount = 0;
        }

        return this;
      }; //
      // Alias methods names because people roll like that.
      //


      EventEmitter.prototype.off = EventEmitter.prototype.removeListener;
      EventEmitter.prototype.addListener = EventEmitter.prototype.on; //
      // Expose the prefix.
      //

      EventEmitter.prefixed = prefix; //
      // Allow `EventEmitter` to be imported as module namespace.
      //

      EventEmitter.EventEmitter = EventEmitter; //
      // Expose the module.
      //

      if ('undefined' !== typeof module) {
        module.exports = EventEmitter;
      }
    }, {}],
    4: [function (_dereq_, module, exports) {
      if (typeof Object.create === 'function') {
        // implementation from standard node.js 'util' module
        module.exports = function inherits(ctor, superCtor) {
          if (superCtor) {
            ctor.super_ = superCtor;
            ctor.prototype = Object.create(superCtor.prototype, {
              constructor: {
                value: ctor,
                enumerable: false,
                writable: true,
                configurable: true
              }
            });
          }
        };
      } else {
        // old school shim for old browsers
        module.exports = function inherits(ctor, superCtor) {
          if (superCtor) {
            ctor.super_ = superCtor;

            var TempCtor = function () {};

            TempCtor.prototype = superCtor.prototype;
            ctor.prototype = new TempCtor();
            ctor.prototype.constructor = ctor;
          }
        };
      }
    }, {}],
    5: [function (_dereq_, module, exports) {
      'use strict';

      var regex = new RegExp('^((?:\\d+)?\\.?\\d+) *(' + ['milliseconds?', 'msecs?', 'ms', 'seconds?', 'secs?', 's', 'minutes?', 'mins?', 'm', 'hours?', 'hrs?', 'h', 'days?', 'd', 'weeks?', 'wks?', 'w', 'years?', 'yrs?', 'y'].join('|') + ')?$', 'i');
      var second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24,
          week = day * 7,
          year = day * 365;
      /**
       * Parse a time string and return the number value of it.
       *
       * @param {String} ms Time string.
       * @returns {Number}
       * @api private
       */

      module.exports = function millisecond(ms) {
        var type = typeof ms,
            amount,
            match;
        if ('number' === type) return ms;else if ('string' !== type || '0' === ms || !ms) return 0;else if (+ms) return +ms; //
        // We are vulnerable to the regular expression denial of service (ReDoS).
        // In order to mitigate this we don't parse the input string if it is too long.
        // See https://nodesecurity.io/advisories/46.
        //

        if (ms.length > 10000 || !(match = regex.exec(ms))) return 0;
        amount = parseFloat(match[1]);

        switch (match[2].toLowerCase()) {
          case 'years':
          case 'year':
          case 'yrs':
          case 'yr':
          case 'y':
            return amount * year;

          case 'weeks':
          case 'week':
          case 'wks':
          case 'wk':
          case 'w':
            return amount * week;

          case 'days':
          case 'day':
          case 'd':
            return amount * day;

          case 'hours':
          case 'hour':
          case 'hrs':
          case 'hr':
          case 'h':
            return amount * hour;

          case 'minutes':
          case 'minute':
          case 'mins':
          case 'min':
          case 'm':
            return amount * minute;

          case 'seconds':
          case 'second':
          case 'secs':
          case 'sec':
          case 's':
            return amount * second;

          default:
            return amount;
        }
      };
    }, {}],
    6: [function (_dereq_, module, exports) {
      'use strict';
      /**
       * Wrap callbacks to prevent double execution.
       *
       * @param {Function} fn Function that should only be called once.
       * @returns {Function} A wrapped callback which prevents execution.
       * @api public
       */

      module.exports = function one(fn) {
        var called = 0,
            value;
        /**
         * The function that prevents double execution.
         *
         * @api private
         */

        function onetime() {
          if (called) return value;
          called = 1;
          value = fn.apply(this, arguments);
          fn = null;
          return value;
        } //
        // To make debugging more easy we want to use the name of the supplied
        // function. So when you look at the functions that are assigned to event
        // listeners you don't see a load of `onetime` functions but actually the
        // names of the functions that this module will call.
        //


        onetime.displayName = fn.displayName || fn.name || onetime.displayName || onetime.name;
        return onetime;
      };
    }, {}],
    7: [function (_dereq_, module, exports) {
      // shim for using process in browser
      var process = module.exports = {}; // cached from whatever global is present so that test runners that stub it
      // don't break things.  But we need to wrap it in a try catch in case it is
      // wrapped in strict mode code which doesn't define any globals.  It's inside a
      // function because try/catches deoptimize in certain engines.

      var cachedSetTimeout;
      var cachedClearTimeout;

      function defaultSetTimout() {
        throw new Error('setTimeout has not been defined');
      }

      function defaultClearTimeout() {
        throw new Error('clearTimeout has not been defined');
      }

      (function () {
        try {
          if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
          } else {
            cachedSetTimeout = defaultSetTimout;
          }
        } catch (e) {
          cachedSetTimeout = defaultSetTimout;
        }

        try {
          if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
          } else {
            cachedClearTimeout = defaultClearTimeout;
          }
        } catch (e) {
          cachedClearTimeout = defaultClearTimeout;
        }
      })();

      function runTimeout(fun) {
        if (cachedSetTimeout === setTimeout) {
          //normal enviroments in sane situations
          return setTimeout(fun, 0);
        } // if setTimeout wasn't available but was latter defined


        if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
          cachedSetTimeout = setTimeout;
          return setTimeout(fun, 0);
        }

        try {
          // when when somebody has screwed with setTimeout but no I.E. maddness
          return cachedSetTimeout(fun, 0);
        } catch (e) {
          try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
          } catch (e) {
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
          }
        }
      }

      function runClearTimeout(marker) {
        if (cachedClearTimeout === clearTimeout) {
          //normal enviroments in sane situations
          return clearTimeout(marker);
        } // if clearTimeout wasn't available but was latter defined


        if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
          cachedClearTimeout = clearTimeout;
          return clearTimeout(marker);
        }

        try {
          // when when somebody has screwed with setTimeout but no I.E. maddness
          return cachedClearTimeout(marker);
        } catch (e) {
          try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
          } catch (e) {
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
          }
        }
      }

      var queue = [];
      var draining = false;
      var currentQueue;
      var queueIndex = -1;

      function cleanUpNextTick() {
        if (!draining || !currentQueue) {
          return;
        }

        draining = false;

        if (currentQueue.length) {
          queue = currentQueue.concat(queue);
        } else {
          queueIndex = -1;
        }

        if (queue.length) {
          drainQueue();
        }
      }

      function drainQueue() {
        if (draining) {
          return;
        }

        var timeout = runTimeout(cleanUpNextTick);
        draining = true;
        var len = queue.length;

        while (len) {
          currentQueue = queue;
          queue = [];

          while (++queueIndex < len) {
            if (currentQueue) {
              currentQueue[queueIndex].run();
            }
          }

          queueIndex = -1;
          len = queue.length;
        }

        currentQueue = null;
        draining = false;
        runClearTimeout(timeout);
      }

      process.nextTick = function (fun) {
        var args = new Array(arguments.length - 1);

        if (arguments.length > 1) {
          for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
          }
        }

        queue.push(new Item(fun, args));

        if (queue.length === 1 && !draining) {
          runTimeout(drainQueue);
        }
      }; // v8 likes predictible objects


      function Item(fun, array) {
        this.fun = fun;
        this.array = array;
      }

      Item.prototype.run = function () {
        this.fun.apply(null, this.array);
      };

      process.title = 'browser';
      process.browser = true;
      process.env = {};
      process.argv = [];
      process.version = ''; // empty string to avoid regexp issues

      process.versions = {};

      function noop() {}

      process.on = noop;
      process.addListener = noop;
      process.once = noop;
      process.off = noop;
      process.removeListener = noop;
      process.removeAllListeners = noop;
      process.emit = noop;
      process.prependListener = noop;
      process.prependOnceListener = noop;

      process.listeners = function (name) {
        return [];
      };

      process.binding = function (name) {
        throw new Error('process.binding is not supported');
      };

      process.cwd = function () {
        return '/';
      };

      process.chdir = function (dir) {
        throw new Error('process.chdir is not supported');
      };

      process.umask = function () {
        return 0;
      };
    }, {}],
    8: [function (_dereq_, module, exports) {
      'use strict';

      var has = Object.prototype.hasOwnProperty,
          undef;
      /**
       * Decode a URI encoded string.
       *
       * @param {String} input The URI encoded string.
       * @returns {String|Null} The decoded string.
       * @api private
       */

      function decode(input) {
        try {
          return decodeURIComponent(input.replace(/\+/g, ' '));
        } catch (e) {
          return null;
        }
      }
      /**
       * Attempts to encode a given input.
       *
       * @param {String} input The string that needs to be encoded.
       * @returns {String|Null} The encoded string.
       * @api private
       */


      function encode(input) {
        try {
          return encodeURIComponent(input);
        } catch (e) {
          return null;
        }
      }
      /**
       * Simple query string parser.
       *
       * @param {String} query The query string that needs to be parsed.
       * @returns {Object}
       * @api public
       */


      function querystring(query) {
        var parser = /([^=?#&]+)=?([^&]*)/g,
            result = {},
            part;

        while (part = parser.exec(query)) {
          var key = decode(part[1]),
              value = decode(part[2]); //
          // Prevent overriding of existing properties. This ensures that build-in
          // methods like `toString` or __proto__ are not overriden by malicious
          // querystrings.
          //
          // In the case if failed decoding, we want to omit the key/value pairs
          // from the result.
          //

          if (key === null || value === null || key in result) continue;
          result[key] = value;
        }

        return result;
      }
      /**
       * Transform a query string to an object.
       *
       * @param {Object} obj Object that should be transformed.
       * @param {String} prefix Optional prefix.
       * @returns {String}
       * @api public
       */


      function querystringify(obj, prefix) {
        prefix = prefix || '';
        var pairs = [],
            value,
            key; //
        // Optionally prefix with a '?' if needed
        //

        if ('string' !== typeof prefix) prefix = '?';

        for (key in obj) {
          if (has.call(obj, key)) {
            value = obj[key]; //
            // Edge cases where we actually want to encode the value to an empty
            // string instead of the stringified value.
            //

            if (!value && (value === null || value === undef || isNaN(value))) {
              value = '';
            }

            key = encode(key);
            value = encode(value); //
            // If we failed to encode the strings, we should bail out as we don't
            // want to add invalid strings to the query.
            //

            if (key === null || value === null) continue;
            pairs.push(key + '=' + value);
          }
        }

        return pairs.length ? prefix + pairs.join('&') : '';
      } //
      // Expose the module.
      //


      exports.stringify = querystringify;
      exports.parse = querystring;
    }, {}],
    9: [function (_dereq_, module, exports) {
      'use strict';

      var EventEmitter = _dereq_('eventemitter3'),
          millisecond = _dereq_('millisecond'),
          destroy = _dereq_('demolish'),
          Tick = _dereq_('tick-tock'),
          one = _dereq_('one-time');
      /**
       * Returns sane defaults about a given value.
       *
       * @param {String} name Name of property we want.
       * @param {Recovery} selfie Recovery instance that got created.
       * @param {Object} opts User supplied options we want to check.
       * @returns {Number} Some default value.
       * @api private
       */


      function defaults(name, selfie, opts) {
        return millisecond(name in opts ? opts[name] : name in selfie ? selfie[name] : Recovery[name]);
      }
      /**
       * Attempt to recover your connection with reconnection attempt.
       *
       * @constructor
       * @param {Object} options Configuration
       * @api public
       */


      function Recovery(options) {
        var recovery = this;
        if (!(recovery instanceof Recovery)) return new Recovery(options);
        options = options || {};
        recovery.attempt = null; // Stores the current reconnect attempt.

        recovery._fn = null; // Stores the callback.

        recovery['reconnect timeout'] = defaults('reconnect timeout', recovery, options);
        recovery.retries = defaults('retries', recovery, options);
        recovery.factor = defaults('factor', recovery, options);
        recovery.max = defaults('max', recovery, options);
        recovery.min = defaults('min', recovery, options);
        recovery.timers = new Tick(recovery);
      }

      Recovery.prototype = new EventEmitter();
      Recovery.prototype.constructor = Recovery;
      Recovery['reconnect timeout'] = '30 seconds'; // Maximum time to wait for an answer.

      Recovery.max = Infinity; // Maximum delay.

      Recovery.min = '500 ms'; // Minimum delay.

      Recovery.retries = 10; // Maximum amount of retries.

      Recovery.factor = 2; // Exponential back off factor.

      /**
       * Start a new reconnect procedure.
       *
       * @returns {Recovery}
       * @api public
       */

      Recovery.prototype.reconnect = function reconnect() {
        var recovery = this;
        return recovery.backoff(function backedoff(err, opts) {
          opts.duration = +new Date() - opts.start;
          if (err) return recovery.emit('reconnect failed', err, opts);
          recovery.emit('reconnected', opts);
        }, recovery.attempt);
      };
      /**
       * Exponential back off algorithm for retry operations. It uses a randomized
       * retry so we don't DDOS our server when it goes down under pressure.
       *
       * @param {Function} fn Callback to be called after the timeout.
       * @param {Object} opts Options for configuring the timeout.
       * @returns {Recovery}
       * @api private
       */


      Recovery.prototype.backoff = function backoff(fn, opts) {
        var recovery = this;
        opts = opts || recovery.attempt || {}; //
        // Bailout when we already have a back off process running. We shouldn't call
        // the callback then.
        //

        if (opts.backoff) return recovery;
        opts['reconnect timeout'] = defaults('reconnect timeout', recovery, opts);
        opts.retries = defaults('retries', recovery, opts);
        opts.factor = defaults('factor', recovery, opts);
        opts.max = defaults('max', recovery, opts);
        opts.min = defaults('min', recovery, opts);
        opts.start = +opts.start || +new Date();
        opts.duration = +opts.duration || 0;
        opts.attempt = +opts.attempt || 0; //
        // Bailout if we are about to make too much attempts.
        //

        if (opts.attempt === opts.retries) {
          fn.call(recovery, new Error('Unable to recover'), opts);
          return recovery;
        } //
        // Prevent duplicate back off attempts using the same options object and
        // increment our attempt as we're about to have another go at this thing.
        //


        opts.backoff = true;
        opts.attempt++;
        recovery.attempt = opts; //
        // Calculate the timeout, but make it randomly so we don't retry connections
        // at the same interval and defeat the purpose. This exponential back off is
        // based on the work of:
        //
        // http://dthain.blogspot.nl/2009/02/exponential-backoff-in-distributed.html
        //

        opts.scheduled = opts.attempt !== 1 ? Math.min(Math.round((Math.random() + 1) * opts.min * Math.pow(opts.factor, opts.attempt - 1)), opts.max) : opts.min;
        recovery.timers.setTimeout('reconnect', function delay() {
          opts.duration = +new Date() - opts.start;
          opts.backoff = false;
          recovery.timers.clear('reconnect, timeout'); //
          // Create a `one` function which can only be called once. So we can use the
          // same function for different types of invocations to create a much better
          // and usable API.
          //

          var connect = recovery._fn = one(function connect(err) {
            recovery.reset();
            if (err) return recovery.backoff(fn, opts);
            fn.call(recovery, undefined, opts);
          });
          recovery.emit('reconnect', opts, connect);
          recovery.timers.setTimeout('timeout', function timeout() {
            var err = new Error('Failed to reconnect in a timely manner');
            opts.duration = +new Date() - opts.start;
            recovery.emit('reconnect timeout', err, opts);
            connect(err);
          }, opts['reconnect timeout']);
        }, opts.scheduled); //
        // Emit a `reconnecting` event with current reconnect options. This allows
        // them to update the UI and provide their users with feedback.
        //

        recovery.emit('reconnect scheduled', opts);
        return recovery;
      };
      /**
       * Check if the reconnection process is currently reconnecting.
       *
       * @returns {Boolean}
       * @api public
       */


      Recovery.prototype.reconnecting = function reconnecting() {
        return !!this.attempt;
      };
      /**
       * Tell our reconnection procedure that we're passed.
       *
       * @param {Error} err Reconnection failed.
       * @returns {Recovery}
       * @api public
       */


      Recovery.prototype.reconnected = function reconnected(err) {
        if (this._fn) this._fn(err);
        return this;
      };
      /**
       * Reset the reconnection attempt so it can be re-used again.
       *
       * @returns {Recovery}
       * @api public
       */


      Recovery.prototype.reset = function reset() {
        this._fn = this.attempt = null;
        this.timers.clear('reconnect, timeout');
        return this;
      };
      /**
       * Clean up the instance.
       *
       * @type {Function}
       * @returns {Boolean}
       * @api public
       */


      Recovery.prototype.destroy = destroy('timers attempt _fn'); //
      // Expose the module.
      //

      module.exports = Recovery;
    }, {
      "demolish": 1,
      "eventemitter3": 10,
      "millisecond": 5,
      "one-time": 6,
      "tick-tock": 12
    }],
    10: [function (_dereq_, module, exports) {
      'use strict'; //
      // We store our EE objects in a plain object whose properties are event names.
      // If `Object.create(null)` is not supported we prefix the event names with a
      // `~` to make sure that the built-in object properties are not overridden or
      // used as an attack vector.
      // We also assume that `Object.create(null)` is available when the event name
      // is an ES6 Symbol.
      //

      var prefix = typeof Object.create !== 'function' ? '~' : false;
      /**
       * Representation of a single EventEmitter function.
       *
       * @param {Function} fn Event handler to be called.
       * @param {Mixed} context Context for function execution.
       * @param {Boolean} once Only emit once
       * @api private
       */

      function EE(fn, context, once) {
        this.fn = fn;
        this.context = context;
        this.once = once || false;
      }
      /**
       * Minimal EventEmitter interface that is molded against the Node.js
       * EventEmitter interface.
       *
       * @constructor
       * @api public
       */


      function EventEmitter() {
        /* Nothing to set */
      }
      /**
       * Holds the assigned EventEmitters by name.
       *
       * @type {Object}
       * @private
       */


      EventEmitter.prototype._events = undefined;
      /**
       * Return a list of assigned event listeners.
       *
       * @param {String} event The events that should be listed.
       * @param {Boolean} exists We only need to know if there are listeners.
       * @returns {Array|Boolean}
       * @api public
       */

      EventEmitter.prototype.listeners = function listeners(event, exists) {
        var evt = prefix ? prefix + event : event,
            available = this._events && this._events[evt];
        if (exists) return !!available;
        if (!available) return [];
        if (available.fn) return [available.fn];

        for (var i = 0, l = available.length, ee = new Array(l); i < l; i++) {
          ee[i] = available[i].fn;
        }

        return ee;
      };
      /**
       * Emit an event to all registered event listeners.
       *
       * @param {String} event The name of the event.
       * @returns {Boolean} Indication if we've emitted an event.
       * @api public
       */


      EventEmitter.prototype.emit = function emit(event, a1, a2, a3, a4, a5) {
        var evt = prefix ? prefix + event : event;
        if (!this._events || !this._events[evt]) return false;
        var listeners = this._events[evt],
            len = arguments.length,
            args,
            i;

        if ('function' === typeof listeners.fn) {
          if (listeners.once) this.removeListener(event, listeners.fn, undefined, true);

          switch (len) {
            case 1:
              return listeners.fn.call(listeners.context), true;

            case 2:
              return listeners.fn.call(listeners.context, a1), true;

            case 3:
              return listeners.fn.call(listeners.context, a1, a2), true;

            case 4:
              return listeners.fn.call(listeners.context, a1, a2, a3), true;

            case 5:
              return listeners.fn.call(listeners.context, a1, a2, a3, a4), true;

            case 6:
              return listeners.fn.call(listeners.context, a1, a2, a3, a4, a5), true;
          }

          for (i = 1, args = new Array(len - 1); i < len; i++) {
            args[i - 1] = arguments[i];
          }

          listeners.fn.apply(listeners.context, args);
        } else {
          var length = listeners.length,
              j;

          for (i = 0; i < length; i++) {
            if (listeners[i].once) this.removeListener(event, listeners[i].fn, undefined, true);

            switch (len) {
              case 1:
                listeners[i].fn.call(listeners[i].context);
                break;

              case 2:
                listeners[i].fn.call(listeners[i].context, a1);
                break;

              case 3:
                listeners[i].fn.call(listeners[i].context, a1, a2);
                break;

              default:
                if (!args) for (j = 1, args = new Array(len - 1); j < len; j++) {
                  args[j - 1] = arguments[j];
                }
                listeners[i].fn.apply(listeners[i].context, args);
            }
          }
        }

        return true;
      };
      /**
       * Register a new EventListener for the given event.
       *
       * @param {String} event Name of the event.
       * @param {Functon} fn Callback function.
       * @param {Mixed} context The context of the function.
       * @api public
       */


      EventEmitter.prototype.on = function on(event, fn, context) {
        var listener = new EE(fn, context || this),
            evt = prefix ? prefix + event : event;
        if (!this._events) this._events = prefix ? {} : Object.create(null);
        if (!this._events[evt]) this._events[evt] = listener;else {
          if (!this._events[evt].fn) this._events[evt].push(listener);else this._events[evt] = [this._events[evt], listener];
        }
        return this;
      };
      /**
       * Add an EventListener that's only called once.
       *
       * @param {String} event Name of the event.
       * @param {Function} fn Callback function.
       * @param {Mixed} context The context of the function.
       * @api public
       */


      EventEmitter.prototype.once = function once(event, fn, context) {
        var listener = new EE(fn, context || this, true),
            evt = prefix ? prefix + event : event;
        if (!this._events) this._events = prefix ? {} : Object.create(null);
        if (!this._events[evt]) this._events[evt] = listener;else {
          if (!this._events[evt].fn) this._events[evt].push(listener);else this._events[evt] = [this._events[evt], listener];
        }
        return this;
      };
      /**
       * Remove event listeners.
       *
       * @param {String} event The event we want to remove.
       * @param {Function} fn The listener that we need to find.
       * @param {Mixed} context Only remove listeners matching this context.
       * @param {Boolean} once Only remove once listeners.
       * @api public
       */


      EventEmitter.prototype.removeListener = function removeListener(event, fn, context, once) {
        var evt = prefix ? prefix + event : event;
        if (!this._events || !this._events[evt]) return this;
        var listeners = this._events[evt],
            events = [];

        if (fn) {
          if (listeners.fn) {
            if (listeners.fn !== fn || once && !listeners.once || context && listeners.context !== context) {
              events.push(listeners);
            }
          } else {
            for (var i = 0, length = listeners.length; i < length; i++) {
              if (listeners[i].fn !== fn || once && !listeners[i].once || context && listeners[i].context !== context) {
                events.push(listeners[i]);
              }
            }
          }
        } //
        // Reset the array, or remove it completely if we have no more listeners.
        //


        if (events.length) {
          this._events[evt] = events.length === 1 ? events[0] : events;
        } else {
          delete this._events[evt];
        }

        return this;
      };
      /**
       * Remove all listeners or only the listeners for the specified event.
       *
       * @param {String} event The event want to remove all listeners for.
       * @api public
       */


      EventEmitter.prototype.removeAllListeners = function removeAllListeners(event) {
        if (!this._events) return this;
        if (event) delete this._events[prefix ? prefix + event : event];else this._events = prefix ? {} : Object.create(null);
        return this;
      }; //
      // Alias methods names because people roll like that.
      //


      EventEmitter.prototype.off = EventEmitter.prototype.removeListener;
      EventEmitter.prototype.addListener = EventEmitter.prototype.on; //
      // This function doesn't apply anymore.
      //

      EventEmitter.prototype.setMaxListeners = function setMaxListeners() {
        return this;
      }; //
      // Expose the prefix.
      //


      EventEmitter.prefixed = prefix; //
      // Expose the module.
      //

      if ('undefined' !== typeof module) {
        module.exports = EventEmitter;
      }
    }, {}],
    11: [function (_dereq_, module, exports) {
      'use strict';
      /**
       * Check if we're required to add a port number.
       *
       * @see https://url.spec.whatwg.org/#default-port
       * @param {Number|String} port Port number we need to check
       * @param {String} protocol Protocol we need to check against.
       * @returns {Boolean} Is it a default port for the given protocol
       * @api private
       */

      module.exports = function required(port, protocol) {
        protocol = protocol.split(':')[0];
        port = +port;
        if (!port) return false;

        switch (protocol) {
          case 'http':
          case 'ws':
            return port !== 80;

          case 'https':
          case 'wss':
            return port !== 443;

          case 'ftp':
            return port !== 21;

          case 'gopher':
            return port !== 70;

          case 'file':
            return false;
        }

        return port !== 0;
      };
    }, {}],
    12: [function (_dereq_, module, exports) {
      (function (setImmediate, clearImmediate) {
        (function () {
          'use strict';

          var has = Object.prototype.hasOwnProperty,
              ms = _dereq_('millisecond');
          /**
           * Timer instance.
           *
           * @constructor
           * @param {Object} timer New timer instance.
           * @param {Function} clear Clears the timer instance.
           * @param {Function} duration Duration of the timer.
           * @param {Function} fn The functions that need to be executed.
           * @api private
           */


          function Timer(timer, clear, duration, fn) {
            this.start = +new Date();
            this.duration = duration;
            this.clear = clear;
            this.timer = timer;
            this.fns = [fn];
          }
          /**
           * Calculate the time left for a given timer.
           *
           * @returns {Number} Time in milliseconds.
           * @api public
           */


          Timer.prototype.remaining = function remaining() {
            return this.duration - this.taken();
          };
          /**
           * Calculate the amount of time it has taken since we've set the timer.
           *
           * @returns {Number}
           * @api public
           */


          Timer.prototype.taken = function taken() {
            return +new Date() - this.start;
          };
          /**
           * Custom wrappers for the various of clear{whatever} functions. We cannot
           * invoke them directly as this will cause thrown errors in Google Chrome with
           * an Illegal Invocation Error
           *
           * @see #2
           * @type {Function}
           * @api private
           */


          function unsetTimeout(id) {
            clearTimeout(id);
          }

          function unsetInterval(id) {
            clearInterval(id);
          }

          function unsetImmediate(id) {
            clearImmediate(id);
          }
          /**
           * Simple timer management.
           *
           * @constructor
           * @param {Mixed} context Context of the callbacks that we execute.
           * @api public
           */


          function Tick(context) {
            if (!(this instanceof Tick)) return new Tick(context);
            this.timers = {};
            this.context = context || this;
          }
          /**
           * Return a function which will just iterate over all assigned callbacks and
           * optionally clear the timers from memory if needed.
           *
           * @param {String} name Name of the timer we need to execute.
           * @param {Boolean} clear Also clear from memory.
           * @returns {Function}
           * @api private
           */


          Tick.prototype.tock = function ticktock(name, clear) {
            var tock = this;
            return function tickedtock() {
              if (!(name in tock.timers)) return;
              var timer = tock.timers[name],
                  fns = timer.fns.slice(),
                  l = fns.length,
                  i = 0;
              if (clear) tock.clear(name);else tock.start = +new Date();

              for (; i < l; i++) {
                fns[i].call(tock.context);
              }
            };
          };
          /**
           * Add a new timeout.
           *
           * @param {String} name Name of the timer.
           * @param {Function} fn Completion callback.
           * @param {Mixed} time Duration of the timer.
           * @returns {Tick}
           * @api public
           */


          Tick.prototype.setTimeout = function timeout(name, fn, time) {
            var tick = this,
                tock;

            if (tick.timers[name]) {
              tick.timers[name].fns.push(fn);
              return tick;
            }

            tock = ms(time);
            tick.timers[name] = new Timer(setTimeout(tick.tock(name, true), ms(time)), unsetTimeout, tock, fn);
            return tick;
          };
          /**
           * Add a new interval.
           *
           * @param {String} name Name of the timer.
           * @param {Function} fn Completion callback.
           * @param {Mixed} time Interval of the timer.
           * @returns {Tick}
           * @api public
           */


          Tick.prototype.setInterval = function interval(name, fn, time) {
            var tick = this,
                tock;

            if (tick.timers[name]) {
              tick.timers[name].fns.push(fn);
              return tick;
            }

            tock = ms(time);
            tick.timers[name] = new Timer(setInterval(tick.tock(name), ms(time)), unsetInterval, tock, fn);
            return tick;
          };
          /**
           * Add a new setImmediate.
           *
           * @param {String} name Name of the timer.
           * @param {Function} fn Completion callback.
           * @returns {Tick}
           * @api public
           */


          Tick.prototype.setImmediate = function immediate(name, fn) {
            var tick = this;
            if ('function' !== typeof setImmediate) return tick.setTimeout(name, fn, 0);

            if (tick.timers[name]) {
              tick.timers[name].fns.push(fn);
              return tick;
            }

            tick.timers[name] = new Timer(setImmediate(tick.tock(name, true)), unsetImmediate, 0, fn);
            return tick;
          };
          /**
           * Check if we have a timer set.
           *
           * @param {String} name
           * @returns {Boolean}
           * @api public
           */


          Tick.prototype.active = function active(name) {
            return name in this.timers;
          };
          /**
           * Properly clean up all timeout references. If no arguments are supplied we
           * will attempt to clear every single timer that is present.
           *
           * @param {Arguments} ..args.. The names of the timeouts we need to clear
           * @returns {Tick}
           * @api public
           */


          Tick.prototype.clear = function clear() {
            var args = arguments.length ? arguments : [],
                tick = this,
                timer,
                i,
                l;

            if (args.length === 1 && 'string' === typeof args[0]) {
              args = args[0].split(/[, ]+/);
            }

            if (!args.length) {
              for (timer in tick.timers) {
                if (has.call(tick.timers, timer)) args.push(timer);
              }
            }

            for (i = 0, l = args.length; i < l; i++) {
              timer = tick.timers[args[i]];
              if (!timer) continue;
              timer.clear(timer.timer);
              timer.fns = timer.timer = timer.clear = null;
              delete tick.timers[args[i]];
            }

            return tick;
          };
          /**
           * Adjust a timeout or interval to a new duration.
           *
           * @returns {Tick}
           * @api public
           */


          Tick.prototype.adjust = function adjust(name, time) {
            var interval,
                tick = this,
                tock = ms(time),
                timer = tick.timers[name];
            if (!timer) return tick;
            interval = timer.clear === unsetInterval;
            timer.clear(timer.timer);
            timer.start = +new Date();
            timer.duration = tock;
            timer.timer = (interval ? setInterval : setTimeout)(tick.tock(name, !interval), tock);
            return tick;
          };
          /**
           * We will no longer use this module, prepare your self for global cleanups.
           *
           * @returns {Boolean}
           * @api public
           */


          Tick.prototype.end = Tick.prototype.destroy = function end() {
            if (!this.context) return false;
            this.clear();
            this.context = this.timers = null;
            return true;
          }; //
          // Expose the timer factory.
          //


          Tick.Timer = Timer;
          module.exports = Tick;
        }).call(this);
      }).call(this, _dereq_("timers").setImmediate, _dereq_("timers").clearImmediate);
    }, {
      "millisecond": 5,
      "timers": 13
    }],
    13: [function (_dereq_, module, exports) {
      (function (setImmediate, clearImmediate) {
        (function () {
          var nextTick = _dereq_('process/browser.js').nextTick;

          var apply = Function.prototype.apply;
          var slice = Array.prototype.slice;
          var immediateIds = {};
          var nextImmediateId = 0; // DOM APIs, for completeness

          exports.setTimeout = function () {
            return new Timeout(apply.call(setTimeout, window, arguments), clearTimeout);
          };

          exports.setInterval = function () {
            return new Timeout(apply.call(setInterval, window, arguments), clearInterval);
          };

          exports.clearTimeout = exports.clearInterval = function (timeout) {
            timeout.close();
          };

          function Timeout(id, clearFn) {
            this._id = id;
            this._clearFn = clearFn;
          }

          Timeout.prototype.unref = Timeout.prototype.ref = function () {};

          Timeout.prototype.close = function () {
            this._clearFn.call(window, this._id);
          }; // Does not start the time, just sets up the members needed.


          exports.enroll = function (item, msecs) {
            clearTimeout(item._idleTimeoutId);
            item._idleTimeout = msecs;
          };

          exports.unenroll = function (item) {
            clearTimeout(item._idleTimeoutId);
            item._idleTimeout = -1;
          };

          exports._unrefActive = exports.active = function (item) {
            clearTimeout(item._idleTimeoutId);
            var msecs = item._idleTimeout;

            if (msecs >= 0) {
              item._idleTimeoutId = setTimeout(function onTimeout() {
                if (item._onTimeout) item._onTimeout();
              }, msecs);
            }
          }; // That's not how node.js implements it but the exposed api is the same.


          exports.setImmediate = typeof setImmediate === "function" ? setImmediate : function (fn) {
            var id = nextImmediateId++;
            var args = arguments.length < 2 ? false : slice.call(arguments, 1);
            immediateIds[id] = true;
            nextTick(function onNextTick() {
              if (immediateIds[id]) {
                // fn.call() is faster so we optimize for the common use-case
                // @see http://jsperf.com/call-apply-segu
                if (args) {
                  fn.apply(null, args);
                } else {
                  fn.call(null);
                } // Prevent ids from leaking


                exports.clearImmediate(id);
              }
            });
            return id;
          };
          exports.clearImmediate = typeof clearImmediate === "function" ? clearImmediate : function (id) {
            delete immediateIds[id];
          };
        }).call(this);
      }).call(this, _dereq_("timers").setImmediate, _dereq_("timers").clearImmediate);
    }, {
      "process/browser.js": 7,
      "timers": 13
    }],
    14: [function (_dereq_, module, exports) {
      (function (global) {
        (function () {
          'use strict';

          var required = _dereq_('requires-port'),
              qs = _dereq_('querystringify'),
              slashes = /^[A-Za-z][A-Za-z0-9+-.]*:\/\//,
              protocolre = /^([a-z][a-z0-9.+-]*:)?(\/\/)?([\\/]+)?([\S\s]*)/i,
              windowsDriveLetter = /^[a-zA-Z]:/,
              whitespace = '[\\x09\\x0A\\x0B\\x0C\\x0D\\x20\\xA0\\u1680\\u180E\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200A\\u202F\\u205F\\u3000\\u2028\\u2029\\uFEFF]',
              left = new RegExp('^' + whitespace + '+');
          /**
           * Trim a given string.
           *
           * @param {String} str String to trim.
           * @public
           */


          function trimLeft(str) {
            return (str ? str : '').toString().replace(left, '');
          }
          /**
           * These are the parse rules for the URL parser, it informs the parser
           * about:
           *
           * 0. The char it Needs to parse, if it's a string it should be done using
           *    indexOf, RegExp using exec and NaN means set as current value.
           * 1. The property we should set when parsing this value.
           * 2. Indication if it's backwards or forward parsing, when set as number it's
           *    the value of extra chars that should be split off.
           * 3. Inherit from location if non existing in the parser.
           * 4. `toLowerCase` the resulting value.
           */


          var rules = [['#', 'hash'], // Extract from the back.
          ['?', 'query'], // Extract from the back.
          function sanitize(address, url) {
            // Sanitize what is left of the address
            return isSpecial(url.protocol) ? address.replace(/\\/g, '/') : address;
          }, ['/', 'pathname'], // Extract from the back.
          ['@', 'auth', 1], // Extract from the front.
          [NaN, 'host', undefined, 1, 1], // Set left over value.
          [/:(\d+)$/, 'port', undefined, 1], // RegExp the back.
          [NaN, 'hostname', undefined, 1, 1] // Set left over.
          ];
          /**
           * These properties should not be copied or inherited from. This is only needed
           * for all non blob URL's as a blob URL does not include a hash, only the
           * origin.
           *
           * @type {Object}
           * @private
           */

          var ignore = {
            hash: 1,
            query: 1
          };
          /**
           * The location object differs when your code is loaded through a normal page,
           * Worker or through a worker using a blob. And with the blobble begins the
           * trouble as the location object will contain the URL of the blob, not the
           * location of the page where our code is loaded in. The actual origin is
           * encoded in the `pathname` so we can thankfully generate a good "default"
           * location from it so we can generate proper relative URL's again.
           *
           * @param {Object|String} loc Optional default location object.
           * @returns {Object} lolcation object.
           * @public
           */

          function lolcation(loc) {
            var globalVar;
            if (typeof window !== 'undefined') globalVar = window;else if (typeof global !== 'undefined') globalVar = global;else if (typeof self !== 'undefined') globalVar = self;else globalVar = {};
            var location = globalVar.location || {};
            loc = loc || location;
            var finaldestination = {},
                type = typeof loc,
                key;

            if ('blob:' === loc.protocol) {
              finaldestination = new Url(unescape(loc.pathname), {});
            } else if ('string' === type) {
              finaldestination = new Url(loc, {});

              for (key in ignore) delete finaldestination[key];
            } else if ('object' === type) {
              for (key in loc) {
                if (key in ignore) continue;
                finaldestination[key] = loc[key];
              }

              if (finaldestination.slashes === undefined) {
                finaldestination.slashes = slashes.test(loc.href);
              }
            }

            return finaldestination;
          }
          /**
           * Check whether a protocol scheme is special.
           *
           * @param {String} The protocol scheme of the URL
           * @return {Boolean} `true` if the protocol scheme is special, else `false`
           * @private
           */


          function isSpecial(scheme) {
            return scheme === 'file:' || scheme === 'ftp:' || scheme === 'http:' || scheme === 'https:' || scheme === 'ws:' || scheme === 'wss:';
          }
          /**
           * @typedef ProtocolExtract
           * @type Object
           * @property {String} protocol Protocol matched in the URL, in lowercase.
           * @property {Boolean} slashes `true` if protocol is followed by "//", else `false`.
           * @property {String} rest Rest of the URL that is not part of the protocol.
           */

          /**
           * Extract protocol information from a URL with/without double slash ("//").
           *
           * @param {String} address URL we want to extract from.
           * @param {Object} location
           * @return {ProtocolExtract} Extracted information.
           * @private
           */


          function extractProtocol(address, location) {
            address = trimLeft(address);
            location = location || {};
            var match = protocolre.exec(address);
            var protocol = match[1] ? match[1].toLowerCase() : '';
            var forwardSlashes = !!match[2];
            var otherSlashes = !!match[3];
            var slashesCount = 0;
            var rest;

            if (forwardSlashes) {
              if (otherSlashes) {
                rest = match[2] + match[3] + match[4];
                slashesCount = match[2].length + match[3].length;
              } else {
                rest = match[2] + match[4];
                slashesCount = match[2].length;
              }
            } else {
              if (otherSlashes) {
                rest = match[3] + match[4];
                slashesCount = match[3].length;
              } else {
                rest = match[4];
              }
            }

            if (protocol === 'file:') {
              if (slashesCount >= 2) {
                rest = rest.slice(2);
              }
            } else if (isSpecial(protocol)) {
              rest = match[4];
            } else if (protocol) {
              if (forwardSlashes) {
                rest = rest.slice(2);
              }
            } else if (slashesCount >= 2 && isSpecial(location.protocol)) {
              rest = match[4];
            }

            return {
              protocol: protocol,
              slashes: forwardSlashes || isSpecial(protocol),
              slashesCount: slashesCount,
              rest: rest
            };
          }
          /**
           * Resolve a relative URL pathname against a base URL pathname.
           *
           * @param {String} relative Pathname of the relative URL.
           * @param {String} base Pathname of the base URL.
           * @return {String} Resolved pathname.
           * @private
           */


          function resolve(relative, base) {
            if (relative === '') return base;
            var path = (base || '/').split('/').slice(0, -1).concat(relative.split('/')),
                i = path.length,
                last = path[i - 1],
                unshift = false,
                up = 0;

            while (i--) {
              if (path[i] === '.') {
                path.splice(i, 1);
              } else if (path[i] === '..') {
                path.splice(i, 1);
                up++;
              } else if (up) {
                if (i === 0) unshift = true;
                path.splice(i, 1);
                up--;
              }
            }

            if (unshift) path.unshift('');
            if (last === '.' || last === '..') path.push('');
            return path.join('/');
          }
          /**
           * The actual URL instance. Instead of returning an object we've opted-in to
           * create an actual constructor as it's much more memory efficient and
           * faster and it pleases my OCD.
           *
           * It is worth noting that we should not use `URL` as class name to prevent
           * clashes with the global URL instance that got introduced in browsers.
           *
           * @constructor
           * @param {String} address URL we want to parse.
           * @param {Object|String} [location] Location defaults for relative paths.
           * @param {Boolean|Function} [parser] Parser for the query string.
           * @private
           */


          function Url(address, location, parser) {
            address = trimLeft(address);

            if (!(this instanceof Url)) {
              return new Url(address, location, parser);
            }

            var relative,
                extracted,
                parse,
                instruction,
                index,
                key,
                instructions = rules.slice(),
                type = typeof location,
                url = this,
                i = 0; //
            // The following if statements allows this module two have compatibility with
            // 2 different API:
            //
            // 1. Node.js's `url.parse` api which accepts a URL, boolean as arguments
            //    where the boolean indicates that the query string should also be parsed.
            //
            // 2. The `URL` interface of the browser which accepts a URL, object as
            //    arguments. The supplied object will be used as default values / fall-back
            //    for relative paths.
            //

            if ('object' !== type && 'string' !== type) {
              parser = location;
              location = null;
            }

            if (parser && 'function' !== typeof parser) parser = qs.parse;
            location = lolcation(location); //
            // Extract protocol information before running the instructions.
            //

            extracted = extractProtocol(address || '', location);
            relative = !extracted.protocol && !extracted.slashes;
            url.slashes = extracted.slashes || relative && location.slashes;
            url.protocol = extracted.protocol || location.protocol || '';
            address = extracted.rest; //
            // When the authority component is absent the URL starts with a path
            // component.
            //

            if (extracted.protocol === 'file:' && (extracted.slashesCount !== 2 || windowsDriveLetter.test(address)) || !extracted.slashes && (extracted.protocol || extracted.slashesCount < 2 || !isSpecial(url.protocol))) {
              instructions[3] = [/(.*)/, 'pathname'];
            }

            for (; i < instructions.length; i++) {
              instruction = instructions[i];

              if (typeof instruction === 'function') {
                address = instruction(address, url);
                continue;
              }

              parse = instruction[0];
              key = instruction[1];

              if (parse !== parse) {
                url[key] = address;
              } else if ('string' === typeof parse) {
                if (~(index = address.indexOf(parse))) {
                  if ('number' === typeof instruction[2]) {
                    url[key] = address.slice(0, index);
                    address = address.slice(index + instruction[2]);
                  } else {
                    url[key] = address.slice(index);
                    address = address.slice(0, index);
                  }
                }
              } else if (index = parse.exec(address)) {
                url[key] = index[1];
                address = address.slice(0, index.index);
              }

              url[key] = url[key] || (relative && instruction[3] ? location[key] || '' : ''); //
              // Hostname, host and protocol should be lowercased so they can be used to
              // create a proper `origin`.
              //

              if (instruction[4]) url[key] = url[key].toLowerCase();
            } //
            // Also parse the supplied query string in to an object. If we're supplied
            // with a custom parser as function use that instead of the default build-in
            // parser.
            //


            if (parser) url.query = parser(url.query); //
            // If the URL is relative, resolve the pathname against the base URL.
            //

            if (relative && location.slashes && url.pathname.charAt(0) !== '/' && (url.pathname !== '' || location.pathname !== '')) {
              url.pathname = resolve(url.pathname, location.pathname);
            } //
            // Default to a / for pathname if none exists. This normalizes the URL
            // to always have a /
            //


            if (url.pathname.charAt(0) !== '/' && isSpecial(url.protocol)) {
              url.pathname = '/' + url.pathname;
            } //
            // We should not add port numbers if they are already the default port number
            // for a given protocol. As the host also contains the port number we're going
            // override it with the hostname which contains no port number.
            //


            if (!required(url.port, url.protocol)) {
              url.host = url.hostname;
              url.port = '';
            } //
            // Parse down the `auth` for the username and password.
            //


            url.username = url.password = '';

            if (url.auth) {
              instruction = url.auth.split(':');
              url.username = instruction[0] || '';
              url.password = instruction[1] || '';
            }

            url.origin = url.protocol !== 'file:' && isSpecial(url.protocol) && url.host ? url.protocol + '//' + url.host : 'null'; //
            // The href is just the compiled result.
            //

            url.href = url.toString();
          }
          /**
           * This is convenience method for changing properties in the URL instance to
           * insure that they all propagate correctly.
           *
           * @param {String} part          Property we need to adjust.
           * @param {Mixed} value          The newly assigned value.
           * @param {Boolean|Function} fn  When setting the query, it will be the function
           *                               used to parse the query.
           *                               When setting the protocol, double slash will be
           *                               removed from the final url if it is true.
           * @returns {URL} URL instance for chaining.
           * @public
           */


          function set(part, value, fn) {
            var url = this;

            switch (part) {
              case 'query':
                if ('string' === typeof value && value.length) {
                  value = (fn || qs.parse)(value);
                }

                url[part] = value;
                break;

              case 'port':
                url[part] = value;

                if (!required(value, url.protocol)) {
                  url.host = url.hostname;
                  url[part] = '';
                } else if (value) {
                  url.host = url.hostname + ':' + value;
                }

                break;

              case 'hostname':
                url[part] = value;
                if (url.port) value += ':' + url.port;
                url.host = value;
                break;

              case 'host':
                url[part] = value;

                if (/:\d+$/.test(value)) {
                  value = value.split(':');
                  url.port = value.pop();
                  url.hostname = value.join(':');
                } else {
                  url.hostname = value;
                  url.port = '';
                }

                break;

              case 'protocol':
                url.protocol = value.toLowerCase();
                url.slashes = !fn;
                break;

              case 'pathname':
              case 'hash':
                if (value) {
                  var char = part === 'pathname' ? '/' : '#';
                  url[part] = value.charAt(0) !== char ? char + value : value;
                } else {
                  url[part] = value;
                }

                break;

              default:
                url[part] = value;
            }

            for (var i = 0; i < rules.length; i++) {
              var ins = rules[i];
              if (ins[4]) url[ins[1]] = url[ins[1]].toLowerCase();
            }

            url.origin = url.protocol !== 'file:' && isSpecial(url.protocol) && url.host ? url.protocol + '//' + url.host : 'null';
            url.href = url.toString();
            return url;
          }
          /**
           * Transform the properties back in to a valid and full URL string.
           *
           * @param {Function} stringify Optional query stringify function.
           * @returns {String} Compiled version of the URL.
           * @public
           */


          function toString(stringify) {
            if (!stringify || 'function' !== typeof stringify) stringify = qs.stringify;
            var query,
                url = this,
                protocol = url.protocol;
            if (protocol && protocol.charAt(protocol.length - 1) !== ':') protocol += ':';
            var result = protocol + (url.slashes || isSpecial(url.protocol) ? '//' : '');

            if (url.username) {
              result += url.username;
              if (url.password) result += ':' + url.password;
              result += '@';
            }

            result += url.host + url.pathname;
            query = 'object' === typeof url.query ? stringify(url.query) : url.query;
            if (query) result += '?' !== query.charAt(0) ? '?' + query : query;
            if (url.hash) result += url.hash;
            return result;
          }

          Url.prototype = {
            set: set,
            toString: toString
          }; //
          // Expose the URL parser and some additional properties that might be useful for
          // others or testing.
          //

          Url.extractProtocol = extractProtocol;
          Url.location = lolcation;
          Url.trimLeft = trimLeft;
          Url.qs = qs;
          module.exports = Url;
        }).call(this);
      }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
    }, {
      "querystringify": 8,
      "requires-port": 11
    }],
    15: [function (_dereq_, module, exports) {
      'use strict';

      var alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_'.split(''),
          length = 64,
          map = {},
          seed = 0,
          i = 0,
          prev;
      /**
       * Return a string representing the specified number.
       *
       * @param {Number} num The number to convert.
       * @returns {String} The string representation of the number.
       * @api public
       */

      function encode(num) {
        var encoded = '';

        do {
          encoded = alphabet[num % length] + encoded;
          num = Math.floor(num / length);
        } while (num > 0);

        return encoded;
      }
      /**
       * Return the integer value specified by the given string.
       *
       * @param {String} str The string to convert.
       * @returns {Number} The integer value represented by the string.
       * @api public
       */


      function decode(str) {
        var decoded = 0;

        for (i = 0; i < str.length; i++) {
          decoded = decoded * length + map[str.charAt(i)];
        }

        return decoded;
      }
      /**
       * Yeast: A tiny growing id generator.
       *
       * @returns {String} A unique id.
       * @api public
       */


      function yeast() {
        var now = encode(+new Date());
        if (now !== prev) return seed = 0, prev = now;
        return now + '.' + encode(seed++);
      } //
      // Map each character to its index.
      //


      for (; i < length; i++) map[alphabet[i]] = i; //
      // Expose the `yeast`, `encode` and `decode` functions.
      //


      yeast.encode = encode;
      yeast.decode = decode;
      module.exports = yeast;
    }, {}],
    16: [function (_dereq_, module, exports) {
      /*globals require, define */
      'use strict';

      var EventEmitter = _dereq_('eventemitter3'),
          TickTock = _dereq_('tick-tock'),
          Recovery = _dereq_('recovery'),
          qs = _dereq_('querystringify'),
          inherits = _dereq_('inherits'),
          destroy = _dereq_('demolish'),
          yeast = _dereq_('yeast'),
          u2028 = /\u2028/g,
          u2029 = /\u2029/g;
      /**
       * Context assertion, ensure that some of our public Primus methods are called
       * with the correct context to ensure that
       *
       * @param {Primus} self The context of the function.
       * @param {String} method The method name.
       * @api private
       */


      function context(self, method) {
        if (self instanceof Primus) return;
        var failure = new Error('Primus#' + method + '\'s context should called with a Primus instance');

        if ('function' !== typeof self.listeners || !self.listeners('error').length) {
          throw failure;
        }

        self.emit('error', failure);
      } //
      // Sets the default connection URL, it uses the default origin of the browser
      // when supported but degrades for older browsers. In Node.js, we cannot guess
      // where the user wants to connect to, so we just default to localhost.
      //


      var defaultUrl;

      try {
        if (location.origin) {
          defaultUrl = location.origin;
        } else {
          defaultUrl = location.protocol + '//' + location.host;
        }
      } catch (e) {
        defaultUrl = 'http://127.0.0.1';
      }
      /**
       * Primus is a real-time library agnostic framework for establishing real-time
       * connections with servers.
       *
       * Options:
       * - reconnect, configuration for the reconnect process.
       * - manual, don't automatically call `.open` to start the connection.
       * - websockets, force the use of WebSockets, even when you should avoid them.
       * - timeout, connect timeout, server didn't respond in a timely manner.
       * - pingTimeout, The maximum amount of time to wait for the server to send a ping.
       * - network, Use network events as leading method for network connection drops.
       * - strategy, Reconnection strategies.
       * - transport, Transport options.
       * - url, uri, The URL to use connect with the server.
       *
       * @constructor
       * @param {String} url The URL of your server.
       * @param {Object} options The configuration.
       * @api public
       */


      function Primus(url, options) {
        if (!(this instanceof Primus)) return new Primus(url, options);
        Primus.Stream.call(this);

        if ('function' !== typeof this.client) {
          return this.critical(new Error('The client library has not been compiled correctly, see ' + 'https://github.com/primus/primus#client-library for more details'));
        }

        if ('object' === typeof url) {
          options = url;
          url = options.url || options.uri || defaultUrl;
        } else {
          options = options || {};
        }

        if ('ping' in options || 'pong' in options) {
          return this.critical(new Error('The `ping` and `pong` options have been removed'));
        }

        var primus = this; // The maximum number of messages that can be placed in queue.

        options.queueSize = 'queueSize' in options ? options.queueSize : Infinity; // Connection timeout duration.

        options.timeout = 'timeout' in options ? options.timeout : 10e3; // Stores the back off configuration.

        options.reconnect = 'reconnect' in options ? options.reconnect : {}; // Heartbeat ping interval.

        options.pingTimeout = 'pingTimeout' in options ? options.pingTimeout : 45000; // Reconnect strategies.

        options.strategy = 'strategy' in options ? options.strategy : []; // Custom transport options.

        options.transport = 'transport' in options ? options.transport : {};
        primus.buffer = []; // Stores premature send data.

        primus.writable = true; // Silly stream compatibility.

        primus.readable = true; // Silly stream compatibility.

        primus.url = primus.parse(url || defaultUrl); // Parse the URL to a readable format.

        primus.readyState = Primus.CLOSED; // The readyState of the connection.

        primus.options = options; // Reference to the supplied options.

        primus.timers = new TickTock(this); // Contains all our timers.

        primus.socket = null; // Reference to the internal connection.

        primus.disconnect = false; // Did we receive a disconnect packet?

        primus.transport = options.transport; // Transport options.

        primus.transformers = {
          // Message transformers.
          outgoing: [],
          incoming: []
        }; //
        // Create our reconnection instance.
        //

        primus.recovery = new Recovery(options.reconnect); //
        // Parse the reconnection strategy. It can have the following strategies:
        //
        // - timeout: Reconnect when we have a network timeout.
        // - disconnect: Reconnect when we have an unexpected disconnect.
        // - online: Reconnect when we're back online.
        //

        if ('string' === typeof options.strategy) {
          options.strategy = options.strategy.split(/\s?,\s?/g);
        }

        if (false === options.strategy) {
          //
          // Strategies are disabled, but we still need an empty array to join it in
          // to nothing.
          //
          options.strategy = [];
        } else if (!options.strategy.length) {
          options.strategy.push('disconnect', 'online'); //
          // Timeout based reconnection should only be enabled conditionally. When
          // authorization is enabled it could trigger.
          //

          if (!this.authorization) options.strategy.push('timeout');
        }

        options.strategy = options.strategy.join(',').toLowerCase(); //
        // Force the use of WebSockets, even when we've detected some potential
        // broken WebSocket implementation.
        //

        if ('websockets' in options) {
          primus.AVOID_WEBSOCKETS = !options.websockets;
        } //
        // Force or disable the use of NETWORK events as leading client side
        // disconnection detection.
        //


        if ('network' in options) {
          primus.NETWORK_EVENTS = options.network;
        } //
        // Check if the user wants to manually initialise a connection. If they don't,
        // we want to do it after a really small timeout so we give the users enough
        // time to listen for `error` events etc.
        //


        if (!options.manual) primus.timers.setTimeout('open', function open() {
          primus.timers.clear('open');
          primus.open();
        }, 0);
        primus.initialise(options);
      }
      /**
       * Simple require wrapper to make browserify, node and require.js play nice.
       *
       * @param {String} name The module to require.
       * @returns {Object|Undefined} The module that we required.
       * @api private
       */


      Primus.requires = Primus.require = function requires(name) {
        if ('function' !== typeof _dereq_) return undefined;
        return !('function' === typeof define && define.amd) ? _dereq_(name) : undefined;
      }; //
      // It's possible that we're running in Node.js or in a Node.js compatible
      // environment. In this cases we try to inherit from the Stream base class.
      //


      try {
        Primus.Stream = Primus.requires('stream');
      } catch (e) {}

      if (!Primus.Stream) Primus.Stream = EventEmitter;
      inherits(Primus, Primus.Stream);
      /**
       * Primus readyStates, used internally to set the correct ready state.
       *
       * @type {Number}
       * @private
       */

      Primus.OPENING = 1; // We're opening the connection.

      Primus.CLOSED = 2; // No active connection.

      Primus.OPEN = 3; // The connection is open.

      /**
       * Are we working with a potentially broken WebSockets implementation? This
       * boolean can be used by transformers to remove `WebSockets` from their
       * supported transports.
       *
       * @type {Boolean}
       * @private
       */

      Primus.prototype.AVOID_WEBSOCKETS = false;
      /**
       * Some browsers support registering emitting `online` and `offline` events when
       * the connection has been dropped on the client. We're going to detect it in
       * a simple `try {} catch (e) {}` statement so we don't have to do complicated
       * feature detection.
       *
       * @type {Boolean}
       * @private
       */

      Primus.prototype.NETWORK_EVENTS = false;
      Primus.prototype.online = true;

      try {
        if (Primus.prototype.NETWORK_EVENTS = 'onLine' in navigator && (window.addEventListener || document.body.attachEvent)) {
          if (!navigator.onLine) {
            Primus.prototype.online = false;
          }
        }
      } catch (e) {}
      /**
       * The Ark contains all our plugins definitions. It's namespaced by
       * name => plugin.
       *
       * @type {Object}
       * @private
       */


      Primus.prototype.ark = {};
      /**
       * Simple emit wrapper that returns a function that emits an event once it's
       * called. This makes it easier for transports to emit specific events.
       *
       * @returns {Function} A function that will emit the event when called.
       * @api public
       */

      Primus.prototype.emits = _dereq_('emits');
      /**
       * Return the given plugin.
       *
       * @param {String} name The name of the plugin.
       * @returns {Object|undefined} The plugin or undefined.
       * @api public
       */

      Primus.prototype.plugin = function plugin(name) {
        context(this, 'plugin');
        if (name) return this.ark[name];
        var plugins = {};

        for (name in this.ark) {
          plugins[name] = this.ark[name];
        }

        return plugins;
      };
      /**
       * Checks if the given event is an emitted event by Primus.
       *
       * @param {String} evt The event name.
       * @returns {Boolean} Indication of the event is reserved for internal use.
       * @api public
       */


      Primus.prototype.reserved = function reserved(evt) {
        return /^(incoming|outgoing)::/.test(evt) || evt in this.reserved.events;
      };
      /**
       * The actual events that are used by the client.
       *
       * @type {Object}
       * @public
       */


      Primus.prototype.reserved.events = {
        'reconnect scheduled': 1,
        'reconnect timeout': 1,
        'readyStateChange': 1,
        'reconnect failed': 1,
        'reconnected': 1,
        'reconnect': 1,
        'offline': 1,
        'timeout': 1,
        'destroy': 1,
        'online': 1,
        'error': 1,
        'close': 1,
        'open': 1,
        'data': 1,
        'end': 1
      };
      /**
       * Initialise the Primus and setup all parsers and internal listeners.
       *
       * @param {Object} options The original options object.
       * @returns {Primus}
       * @api private
       */

      Primus.prototype.initialise = function initialise(options) {
        var primus = this;
        primus.recovery.on('reconnected', primus.emits('reconnected')).on('reconnect failed', primus.emits('reconnect failed', function failed(next) {
          primus.emit('end');
          next();
        })).on('reconnect timeout', primus.emits('reconnect timeout')).on('reconnect scheduled', primus.emits('reconnect scheduled')).on('reconnect', primus.emits('reconnect', function reconnect(next) {
          primus.emit('outgoing::reconnect');
          next();
        }));
        primus.on('outgoing::open', function opening() {
          var readyState = primus.readyState;
          primus.readyState = Primus.OPENING;

          if (readyState !== primus.readyState) {
            primus.emit('readyStateChange', 'opening');
          }
        });
        primus.on('incoming::open', function opened() {
          var readyState = primus.readyState;

          if (primus.recovery.reconnecting()) {
            primus.recovery.reconnected();
          } //
          // The connection has been opened so we should set our state to
          // (writ|read)able so our stream compatibility works as intended.
          //


          primus.writable = true;
          primus.readable = true; //
          // Make sure we are flagged as `online` as we've successfully opened the
          // connection.
          //

          if (!primus.online) {
            primus.online = true;
            primus.emit('online');
          }

          primus.readyState = Primus.OPEN;

          if (readyState !== primus.readyState) {
            primus.emit('readyStateChange', 'open');
          }

          primus.heartbeat();

          if (primus.buffer.length) {
            var data = primus.buffer.slice(),
                length = data.length,
                i = 0;
            primus.buffer.length = 0;

            for (; i < length; i++) {
              primus._write(data[i]);
            }
          }

          primus.emit('open');
        });
        primus.on('incoming::ping', function ping(time) {
          primus.online = true;
          primus.heartbeat();
          primus.emit('outgoing::pong', time);

          primus._write('primus::pong::' + time);
        });
        primus.on('incoming::error', function error(e) {
          var connect = primus.timers.active('connect'),
              err = e; //
          // When the error is not an Error instance we try to normalize it.
          //

          if ('string' === typeof e) {
            err = new Error(e);
          } else if (!(e instanceof Error) && 'object' === typeof e) {
            //
            // BrowserChannel and SockJS returns an object which contains some
            // details of the error. In order to have a proper error we "copy" the
            // details in an Error instance.
            //
            err = new Error(e.message || e.reason);

            for (var key in e) {
              if (Object.prototype.hasOwnProperty.call(e, key)) err[key] = e[key];
            }
          } //
          // We're still doing a reconnect attempt, it could be that we failed to
          // connect because the server was down. Failing connect attempts should
          // always emit an `error` event instead of a `open` event.
          //
          //


          if (primus.recovery.reconnecting()) return primus.recovery.reconnected(err);
          if (primus.listeners('error').length) primus.emit('error', err); //
          // We received an error while connecting, this most likely the result of an
          // unauthorized access to the server.
          //

          if (connect) {
            if (~primus.options.strategy.indexOf('timeout')) {
              primus.recovery.reconnect();
            } else {
              primus.end();
            }
          }
        });
        primus.on('incoming::data', function message(raw) {
          primus.decoder(raw, function decoding(err, data) {
            //
            // Do a "safe" emit('error') when we fail to parse a message. We don't
            // want to throw here as listening to errors should be optional.
            //
            if (err) return primus.listeners('error').length && primus.emit('error', err); //
            // Handle all "primus::" prefixed protocol messages.
            //

            if (primus.protocol(data)) return;
            primus.transforms(primus, primus, 'incoming', data, raw);
          });
        });
        primus.on('incoming::end', function end() {
          var readyState = primus.readyState; //
          // This `end` started with the receiving of a primus::server::close packet
          // which indicated that the user/developer on the server closed the
          // connection and it was not a result of a network disruption. So we should
          // kill the connection without doing a reconnect.
          //

          if (primus.disconnect) {
            primus.disconnect = false;
            return primus.end();
          } //
          // Always set the readyState to closed, and if we're still connecting, close
          // the connection so we're sure that everything after this if statement block
          // is only executed because our readyState is set to `open`.
          //


          primus.readyState = Primus.CLOSED;

          if (readyState !== primus.readyState) {
            primus.emit('readyStateChange', 'end');
          }

          if (primus.timers.active('connect')) primus.end();

          if (readyState !== Primus.OPEN) {
            return primus.recovery.reconnecting() ? primus.recovery.reconnect() : false;
          }

          this.writable = false;
          this.readable = false; //
          // Clear all timers in case we're not going to reconnect.
          //

          this.timers.clear(); //
          // Fire the `close` event as an indication of connection disruption.
          // This is also fired by `primus#end` so it is emitted in all cases.
          //

          primus.emit('close'); //
          // The disconnect was unintentional, probably because the server has
          // shutdown, so if the reconnection is enabled start a reconnect procedure.
          //

          if (~primus.options.strategy.indexOf('disconnect')) {
            return primus.recovery.reconnect();
          }

          primus.emit('outgoing::end');
          primus.emit('end');
        }); //
        // Setup the real-time client.
        //

        primus.client(); //
        // Process the potential plugins.
        //

        for (var plugin in primus.ark) {
          primus.ark[plugin].call(primus, primus, options);
        } //
        // NOTE: The following code is only required if we're supporting network
        // events as it requires access to browser globals.
        //


        if (!primus.NETWORK_EVENTS) return primus;
        /**
         * Handler for offline notifications.
         *
         * @api private
         */

        primus.offlineHandler = function offline() {
          if (!primus.online) return; // Already or still offline, bailout.

          primus.online = false;
          primus.emit('offline');
          primus.end(); //
          // It is certainly possible that we're in a reconnection loop and that the
          // user goes offline. In this case we want to kill the existing attempt so
          // when the user goes online, it will attempt to reconnect freshly again.
          //

          primus.recovery.reset();
        };
        /**
         * Handler for online notifications.
         *
         * @api private
         */


        primus.onlineHandler = function online() {
          if (primus.online) return; // Already or still online, bailout.

          primus.online = true;
          primus.emit('online');

          if (~primus.options.strategy.indexOf('online')) {
            primus.recovery.reconnect();
          }
        };

        if (window.addEventListener) {
          window.addEventListener('offline', primus.offlineHandler, false);
          window.addEventListener('online', primus.onlineHandler, false);
        } else if (document.body.attachEvent) {
          document.body.attachEvent('onoffline', primus.offlineHandler);
          document.body.attachEvent('ononline', primus.onlineHandler);
        }

        return primus;
      };
      /**
       * Really dead simple protocol parser. We simply assume that every message that
       * is prefixed with `primus::` could be used as some sort of protocol definition
       * for Primus.
       *
       * @param {String} msg The data.
       * @returns {Boolean} Is a protocol message.
       * @api private
       */


      Primus.prototype.protocol = function protocol(msg) {
        if ('string' !== typeof msg || msg.indexOf('primus::') !== 0) return false;
        var last = msg.indexOf(':', 8),
            value = msg.slice(last + 2);

        switch (msg.slice(8, last)) {
          case 'ping':
            this.emit('incoming::ping', +value);
            break;

          case 'server':
            //
            // The server is closing the connection, forcefully disconnect so we don't
            // reconnect again.
            //
            if ('close' === value) {
              this.disconnect = true;
            }

            break;

          case 'id':
            this.emit('incoming::id', value);
            break;
          //
          // Unknown protocol, somebody is probably sending `primus::` prefixed
          // messages.
          //

          default:
            return false;
        }

        return true;
      };
      /**
       * Execute the set of message transformers from Primus on the incoming or
       * outgoing message.
       * This function and it's content should be in sync with Spark#transforms in
       * spark.js.
       *
       * @param {Primus} primus Reference to the Primus instance with message transformers.
       * @param {Spark|Primus} connection Connection that receives or sends data.
       * @param {String} type The type of message, 'incoming' or 'outgoing'.
       * @param {Mixed} data The data to send or that has been received.
       * @param {String} raw The raw encoded data.
       * @returns {Primus}
       * @api public
       */


      Primus.prototype.transforms = function transforms(primus, connection, type, data, raw) {
        var packet = {
          data: data
        },
            fns = primus.transformers[type]; //
        // Iterate in series over the message transformers so we can allow optional
        // asynchronous execution of message transformers which could for example
        // retrieve additional data from the server, do extra decoding or even
        // message validation.
        //

        (function transform(index, done) {
          var transformer = fns[index++];
          if (!transformer) return done();

          if (1 === transformer.length) {
            if (false === transformer.call(connection, packet)) {
              //
              // When false is returned by an incoming transformer it means that's
              // being handled by the transformer and we should not emit the `data`
              // event.
              //
              return;
            }

            return transform(index, done);
          }

          transformer.call(connection, packet, function finished(err, arg) {
            if (err) return connection.emit('error', err);
            if (false === arg) return;
            transform(index, done);
          });
        })(0, function done() {
          //
          // We always emit 2 arguments for the data event, the first argument is the
          // parsed data and the second argument is the raw string that we received.
          // This allows you, for example, to do some validation on the parsed data
          // and then save the raw string in your database without the stringify
          // overhead.
          //
          if ('incoming' === type) return connection.emit('data', packet.data, raw);

          connection._write(packet.data);
        });

        return this;
      };
      /**
       * Retrieve the current id from the server.
       *
       * @param {Function} fn Callback function.
       * @returns {Primus}
       * @api public
       */


      Primus.prototype.id = function id(fn) {
        if (this.socket && this.socket.id) return fn(this.socket.id);

        this._write('primus::id::');

        return this.once('incoming::id', fn);
      };
      /**
       * Establish a connection with the server. When this function is called we
       * assume that we don't have any open connections. If you do call it when you
       * have a connection open, it could cause duplicate connections.
       *
       * @returns {Primus}
       * @api public
       */


      Primus.prototype.open = function open() {
        context(this, 'open'); //
        // Only start a `connection timeout` procedure if we're not reconnecting as
        // that shouldn't count as an initial connection. This should be started
        // before the connection is opened to capture failing connections and kill the
        // timeout.
        //

        if (!this.recovery.reconnecting() && this.options.timeout) this.timeout();
        this.emit('outgoing::open');
        return this;
      };
      /**
       * Send a new message.
       *
       * @param {Mixed} data The data that needs to be written.
       * @returns {Boolean} Always returns true as we don't support back pressure.
       * @api public
       */


      Primus.prototype.write = function write(data) {
        context(this, 'write');
        this.transforms(this, this, 'outgoing', data);
        return true;
      };
      /**
       * The actual message writer.
       *
       * @param {Mixed} data The message that needs to be written.
       * @returns {Boolean} Successful write to the underlaying transport.
       * @api private
       */


      Primus.prototype._write = function write(data) {
        var primus = this; //
        // The connection is closed, normally this would already be done in the
        // `spark.write` method, but as `_write` is used internally, we should also
        // add the same check here to prevent potential crashes by writing to a dead
        // socket.
        //

        if (Primus.OPEN !== primus.readyState) {
          //
          // If the buffer is at capacity, remove the first item.
          //
          if (this.buffer.length === this.options.queueSize) {
            this.buffer.splice(0, 1);
          }

          this.buffer.push(data);
          return false;
        }

        primus.encoder(data, function encoded(err, packet) {
          //
          // Do a "safe" emit('error') when we fail to parse a message. We don't
          // want to throw here as listening to errors should be optional.
          //
          if (err) return primus.listeners('error').length && primus.emit('error', err); //
          // Hack 1: \u2028 and \u2029 are allowed inside a JSON string, but JavaScript
          // defines them as newline separators. Unescaped control characters are not
          // allowed inside JSON strings, so this causes an error at parse time. We
          // work around this issue by escaping these characters. This can cause
          // errors with JSONP requests or if the string is just evaluated.
          //

          if ('string' === typeof packet) {
            if (~packet.indexOf('\u2028')) packet = packet.replace(u2028, '\\u2028');
            if (~packet.indexOf('\u2029')) packet = packet.replace(u2029, '\\u2029');
          }

          primus.emit('outgoing::data', packet);
        });
        return true;
      };
      /**
       * Set a timer that, upon expiration, closes the client.
       *
       * @returns {Primus}
       * @api private
       */


      Primus.prototype.heartbeat = function heartbeat() {
        if (!this.options.pingTimeout) return this;
        this.timers.clear('heartbeat');
        this.timers.setTimeout('heartbeat', function expired() {
          //
          // The network events already captured the offline event.
          //
          if (!this.online) return;
          this.online = false;
          this.emit('offline');
          this.emit('incoming::end');
        }, this.options.pingTimeout);
        return this;
      };
      /**
       * Start a connection timeout.
       *
       * @returns {Primus}
       * @api private
       */


      Primus.prototype.timeout = function timeout() {
        var primus = this;
        /**
         * Remove all references to the timeout listener as we've received an event
         * that can be used to determine state.
         *
         * @api private
         */

        function remove() {
          primus.removeListener('error', remove).removeListener('open', remove).removeListener('end', remove).timers.clear('connect');
        }

        primus.timers.setTimeout('connect', function expired() {
          remove(); // Clean up old references.

          if (primus.readyState === Primus.OPEN || primus.recovery.reconnecting()) {
            return;
          }

          primus.emit('timeout'); //
          // We failed to connect to the server.
          //

          if (~primus.options.strategy.indexOf('timeout')) {
            primus.recovery.reconnect();
          } else {
            primus.end();
          }
        }, primus.options.timeout);
        return primus.on('error', remove).on('open', remove).on('end', remove);
      };
      /**
       * Close the connection completely.
       *
       * @param {Mixed} data last packet of data.
       * @returns {Primus}
       * @api public
       */


      Primus.prototype.end = function end(data) {
        context(this, 'end');

        if (this.readyState === Primus.CLOSED && !this.timers.active('connect') && !this.timers.active('open')) {
          //
          // If we are reconnecting stop the reconnection procedure.
          //
          if (this.recovery.reconnecting()) {
            this.recovery.reset();
            this.emit('end');
          }

          return this;
        }

        if (data !== undefined) this.write(data);
        this.writable = false;
        this.readable = false;
        var readyState = this.readyState;
        this.readyState = Primus.CLOSED;

        if (readyState !== this.readyState) {
          this.emit('readyStateChange', 'end');
        }

        this.timers.clear();
        this.emit('outgoing::end');
        this.emit('close');
        this.emit('end');
        return this;
      };
      /**
       * Completely demolish the Primus instance and forcefully nuke all references.
       *
       * @returns {Boolean}
       * @api public
       */


      Primus.prototype.destroy = destroy('url timers options recovery socket transport transformers', {
        before: 'end',
        after: ['removeAllListeners', function detach() {
          if (!this.NETWORK_EVENTS) return;

          if (window.addEventListener) {
            window.removeEventListener('offline', this.offlineHandler);
            window.removeEventListener('online', this.onlineHandler);
          } else if (document.body.attachEvent) {
            document.body.detachEvent('onoffline', this.offlineHandler);
            document.body.detachEvent('ononline', this.onlineHandler);
          }
        }]
      });
      /**
       * Create a shallow clone of a given object.
       *
       * @param {Object} obj The object that needs to be cloned.
       * @returns {Object} Copy.
       * @api private
       */

      Primus.prototype.clone = function clone(obj) {
        return this.merge({}, obj);
      };
      /**
       * Merge different objects in to one target object.
       *
       * @param {Object} target The object where everything should be merged in.
       * @returns {Object} Original target with all merged objects.
       * @api private
       */


      Primus.prototype.merge = function merge(target) {
        for (var i = 1, key, obj; i < arguments.length; i++) {
          obj = arguments[i];

          for (key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) target[key] = obj[key];
          }
        }

        return target;
      };
      /**
       * Parse the connection string.
       *
       * @type {Function}
       * @param {String} url Connection URL.
       * @returns {Object} Parsed connection.
       * @api private
       */


      Primus.prototype.parse = _dereq_('url-parse');
      /**
       * Parse a query string.
       *
       * @param {String} query The query string that needs to be parsed.
       * @returns {Object} Parsed query string.
       * @api private
       */

      Primus.prototype.querystring = qs.parse;
      /**
       * Transform a query string object back into string equiv.
       *
       * @param {Object} obj The query string object.
       * @returns {String}
       * @api private
       */

      Primus.prototype.querystringify = qs.stringify;
      /**
       * Generates a connection URI.
       *
       * @param {String} protocol The protocol that should used to crate the URI.
       * @returns {String|options} The URL.
       * @api private
       */

      Primus.prototype.uri = function uri(options) {
        var url = this.url,
            server = [],
            qsa = false; //
        // Query strings are only allowed when we've received clearance for it.
        //

        if (options.query) qsa = true;
        options = options || {};
        options.protocol = 'protocol' in options ? options.protocol : 'http:';
        options.query = url.query && qsa ? url.query.slice(1) : false;
        options.secure = 'secure' in options ? options.secure : url.protocol === 'https:' || url.protocol === 'wss:';
        options.auth = 'auth' in options ? options.auth : url.auth;
        options.pathname = 'pathname' in options ? options.pathname : this.pathname;
        options.port = 'port' in options ? +options.port : +url.port || (options.secure ? 443 : 80); //
        // We need to make sure that we create a unique connection URL every time to
        // prevent back forward cache from becoming an issue. We're doing this by
        // forcing an cache busting query string in to the URL.
        //

        var querystring = this.querystring(options.query || '');
        querystring._primuscb = yeast();
        options.query = this.querystringify(querystring); //
        // Allow transformation of the options before we construct a full URL from it.
        //

        this.emit('outgoing::url', options); //
        // Automatically suffix the protocol so we can supply `ws:` and `http:` and
        // it gets transformed correctly.
        //

        server.push(options.secure ? options.protocol.replace(':', 's:') : options.protocol, '');
        server.push(options.auth ? options.auth + '@' + url.host : url.host); //
        // Pathnames are optional as some Transformers would just use the pathname
        // directly.
        //

        if (options.pathname) server.push(options.pathname.slice(1)); //
        // Optionally add a search query.
        //

        if (qsa) server[server.length - 1] += '?' + options.query;else delete options.query;
        if (options.object) return options;
        return server.join('/');
      };
      /**
       * Register a new message transformer. This allows you to easily manipulate incoming
       * and outgoing data which is particularity handy for plugins that want to send
       * meta data together with the messages.
       *
       * @param {String} type Incoming or outgoing
       * @param {Function} fn A new message transformer.
       * @returns {Primus}
       * @api public
       */


      Primus.prototype.transform = function transform(type, fn) {
        context(this, 'transform');

        if (!(type in this.transformers)) {
          return this.critical(new Error('Invalid transformer type'));
        }

        this.transformers[type].push(fn);
        return this;
      };
      /**
       * A critical error has occurred, if we have an `error` listener, emit it there.
       * If not, throw it, so we get a stack trace + proper error message.
       *
       * @param {Error} err The critical error.
       * @returns {Primus}
       * @api private
       */


      Primus.prototype.critical = function critical(err) {
        if (this.emit('error', err)) return this;
        throw err;
      };
      /**
       * Syntax sugar, adopt a Socket.IO like API.
       *
       * @param {String} url The URL we want to connect to.
       * @param {Object} options Connection options.
       * @returns {Primus}
       * @api public
       */


      Primus.connect = function connect(url, options) {
        return new Primus(url, options);
      }; //
      // Expose the EventEmitter so it can be re-used by wrapping libraries we're also
      // exposing the Stream interface.
      //


      Primus.EventEmitter = EventEmitter; //
      // These libraries are automatically inserted at the server-side using the
      // Primus#library method.
      //

      Primus.prototype.client = function client() {
        var primus = this,
            socket; //
        // Select an available SockJS factory.
        //

        var Factory = function Factory() {
          if ('undefined' !== typeof SockJS) return SockJS;

          try {
            return Primus.requires('sockjs-client');
          } catch (e) {}

          return undefined;
        }();

        if (!Factory) return primus.critical(new Error('Missing required `sockjs-client` module. ' + 'Please run `npm install --save sockjs-client`')); //
        // Connect to the given URL.
        //

        primus.on('outgoing::open', function opening() {
          primus.emit('outgoing::end');
          primus.socket = socket = new Factory(primus.uri({
            protocol: 'http:',
            query: true
          }), null, primus.merge(primus.transport, {
            info: {
              websocket: !primus.AVOID_WEBSOCKETS,
              // Prevent WebSocket crashes
              cookie_needed: true // Disables xdomainrequest bugs

            }
          })); //
          // Setup the Event handlers.
          //

          socket.onopen = primus.emits('incoming::open');
          socket.onerror = primus.emits('incoming::error');

          socket.onclose = function (e) {
            if (e && e.code > 1000) primus.emit('incoming::error', e);
            primus.emit('incoming::end');
          };

          socket.onmessage = primus.emits('incoming::data', function parse(next, evt) {
            next(undefined, evt.data);
          });
        }); //
        // We need to write a new message to the socket.
        //

        primus.on('outgoing::data', function write(message) {
          if (socket) socket.send(message);
        }); //
        // Attempt to reconnect the socket.
        //

        primus.on('outgoing::reconnect', function reconnect() {
          primus.emit('outgoing::open');
        }); //
        // We need to close the socket.
        //

        primus.on('outgoing::end', function close() {
          if (!socket) return;

          socket.onerror = socket.onopen = socket.onclose = socket.onmessage = function () {};

          socket.close();
          socket = null;
        });
      };

      Primus.prototype.authorization = true;
      Primus.prototype.pathname = "/primus";

      Primus.prototype.encoder = function encoder(data, fn) {
        var err;

        try {
          data = JSON.stringify(data);
        } catch (e) {
          err = e;
        }

        fn(err, data);
      };

      Primus.prototype.decoder = function decoder(data, fn) {
        var err;
        if ('string' !== typeof data) return fn(err, data);

        try {
          data = JSON.parse(data);
        } catch (e) {
          err = e;
        }

        fn(err, data);
      };

      Primus.prototype.version = "8.0.5";

      if ('undefined' !== typeof document && 'undefined' !== typeof navigator) {
        //
        // Hack 2: If you press ESC in FireFox it will close all active connections.
        // Normally this makes sense, when your page is still loading. But versions
        // before FireFox 22 will close all connections including WebSocket connections
        // after page load. One way to prevent this is to do a `preventDefault()` and
        // cancel the operation before it bubbles up to the browsers default handler.
        // It needs to be added as `keydown` event, if it's added keyup it will not be
        // able to prevent the connection from being closed.
        //
        if (document.addEventListener) {
          document.addEventListener('keydown', function keydown(e) {
            if (e.keyCode !== 27 || !e.preventDefault) return;
            e.preventDefault();
          }, false);
        } //
        // Hack 3: This is a Mac/Apple bug only, when you're behind a reverse proxy or
        // have you network settings set to `automatic proxy discovery` the safari
        // browser will crash when the WebSocket constructor is initialised. There is
        // no way to detect the usage of these proxies available in JavaScript so we
        // need to do some nasty browser sniffing. This only affects Safari versions
        // lower then 5.1.4
        //


        var ua = (navigator.userAgent || '').toLowerCase(),
            parsed = ua.match(/.+(?:rv|it|ra|ie)[/: ](\d+)\.(\d+)(?:\.(\d+))?/) || [],
            version = +[parsed[1], parsed[2]].join('.');

        if (!~ua.indexOf('chrome') && ~ua.indexOf('safari') && version < 534.54) {
          Primus.prototype.AVOID_WEBSOCKETS = true;
        }
      } //
      // Expose the library.
      //


      module.exports = Primus;
    }, {
      "demolish": 1,
      "emits": 2,
      "eventemitter3": 3,
      "inherits": 4,
      "querystringify": 8,
      "recovery": 9,
      "tick-tock": 12,
      "url-parse": 14,
      "yeast": 15
    }]
  }, {}, [16])(16);

  Primus.prototype.ark["emitter"] = function () {};

  return Primus;
}, [function (Primus) {
  (function (f) {
    var g;

    if (typeof window !== 'undefined') {
      g = window;
    } else if (typeof self !== 'undefined') {
      g = self;
    }

    g.SockJS = f();
  })(function () {
    var define, module, exports;
    return function () {
      function r(e, n, t) {
        function o(i, f) {
          if (!n[i]) {
            if (!e[i]) {
              var c = "function" == typeof require && require;
              if (!f && c) return c(i, !0);
              if (u) return u(i, !0);
              var a = new Error("Cannot find module '" + i + "'");
              throw a.code = "MODULE_NOT_FOUND", a;
            }

            var p = n[i] = {
              exports: {}
            };
            e[i][0].call(p.exports, function (r) {
              var n = e[i][1][r];
              return o(n || r);
            }, p, p.exports, r, e, n, t);
          }

          return n[i].exports;
        }

        for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);

        return o;
      }

      return r;
    }()({
      1: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            Event = _dereq_('./event');

        function CloseEvent() {
          Event.call(this);
          this.initEvent('close', false, false);
          this.wasClean = false;
          this.code = 0;
          this.reason = '';
        }

        inherits(CloseEvent, Event);
        module.exports = CloseEvent;
      }, {
        "./event": 3,
        "inherits": 53
      }],
      2: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventTarget = _dereq_('./eventtarget');

        function EventEmitter() {
          EventTarget.call(this);
        }

        inherits(EventEmitter, EventTarget);

        EventEmitter.prototype.removeAllListeners = function (type) {
          if (type) {
            delete this._listeners[type];
          } else {
            this._listeners = {};
          }
        };

        EventEmitter.prototype.once = function (type, listener) {
          var self = this,
              fired = false;

          function g() {
            self.removeListener(type, g);

            if (!fired) {
              fired = true;
              listener.apply(this, arguments);
            }
          }

          this.on(type, g);
        };

        EventEmitter.prototype.emit = function () {
          var type = arguments[0];
          var listeners = this._listeners[type];

          if (!listeners) {
            return;
          } // equivalent of Array.prototype.slice.call(arguments, 1);


          var l = arguments.length;
          var args = new Array(l - 1);

          for (var ai = 1; ai < l; ai++) {
            args[ai - 1] = arguments[ai];
          }

          for (var i = 0; i < listeners.length; i++) {
            listeners[i].apply(this, args);
          }
        };

        EventEmitter.prototype.on = EventEmitter.prototype.addListener = EventTarget.prototype.addEventListener;
        EventEmitter.prototype.removeListener = EventTarget.prototype.removeEventListener;
        module.exports.EventEmitter = EventEmitter;
      }, {
        "./eventtarget": 4,
        "inherits": 53
      }],
      3: [function (_dereq_, module, exports) {
        'use strict';

        function Event(eventType) {
          this.type = eventType;
        }

        Event.prototype.initEvent = function (eventType, canBubble, cancelable) {
          this.type = eventType;
          this.bubbles = canBubble;
          this.cancelable = cancelable;
          this.timeStamp = +new Date();
          return this;
        };

        Event.prototype.stopPropagation = function () {};

        Event.prototype.preventDefault = function () {};

        Event.CAPTURING_PHASE = 1;
        Event.AT_TARGET = 2;
        Event.BUBBLING_PHASE = 3;
        module.exports = Event;
      }, {}],
      4: [function (_dereq_, module, exports) {
        'use strict';
        /* Simplified implementation of DOM2 EventTarget.
         *   http://www.w3.org/TR/DOM-Level-2-Events/events.html#Events-EventTarget
         */

        function EventTarget() {
          this._listeners = {};
        }

        EventTarget.prototype.addEventListener = function (eventType, listener) {
          if (!(eventType in this._listeners)) {
            this._listeners[eventType] = [];
          }

          var arr = this._listeners[eventType]; // #4

          if (arr.indexOf(listener) === -1) {
            // Make a copy so as not to interfere with a current dispatchEvent.
            arr = arr.concat([listener]);
          }

          this._listeners[eventType] = arr;
        };

        EventTarget.prototype.removeEventListener = function (eventType, listener) {
          var arr = this._listeners[eventType];

          if (!arr) {
            return;
          }

          var idx = arr.indexOf(listener);

          if (idx !== -1) {
            if (arr.length > 1) {
              // Make a copy so as not to interfere with a current dispatchEvent.
              this._listeners[eventType] = arr.slice(0, idx).concat(arr.slice(idx + 1));
            } else {
              delete this._listeners[eventType];
            }

            return;
          }
        };

        EventTarget.prototype.dispatchEvent = function () {
          var event = arguments[0];
          var t = event.type; // equivalent of Array.prototype.slice.call(arguments, 0);

          var args = arguments.length === 1 ? [event] : Array.apply(null, arguments); // TODO: This doesn't match the real behavior; per spec, onfoo get
          // their place in line from the /first/ time they're set from
          // non-null. Although WebKit bumps it to the end every time it's
          // set.

          if (this['on' + t]) {
            this['on' + t].apply(this, args);
          }

          if (t in this._listeners) {
            // Grab a reference to the listeners list. removeEventListener may alter the list.
            var listeners = this._listeners[t];

            for (var i = 0; i < listeners.length; i++) {
              listeners[i].apply(this, args);
            }
          }
        };

        module.exports = EventTarget;
      }, {}],
      5: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            Event = _dereq_('./event');

        function TransportMessageEvent(data) {
          Event.call(this);
          this.initEvent('message', false, false);
          this.data = data;
        }

        inherits(TransportMessageEvent, Event);
        module.exports = TransportMessageEvent;
      }, {
        "./event": 3,
        "inherits": 53
      }],
      6: [function (_dereq_, module, exports) {
        'use strict';

        var JSON3 = _dereq_('json3'),
            iframeUtils = _dereq_('./utils/iframe');

        function FacadeJS(transport) {
          this._transport = transport;
          transport.on('message', this._transportMessage.bind(this));
          transport.on('close', this._transportClose.bind(this));
        }

        FacadeJS.prototype._transportClose = function (code, reason) {
          iframeUtils.postMessage('c', JSON3.stringify([code, reason]));
        };

        FacadeJS.prototype._transportMessage = function (frame) {
          iframeUtils.postMessage('t', frame);
        };

        FacadeJS.prototype._send = function (data) {
          this._transport.send(data);
        };

        FacadeJS.prototype._close = function () {
          this._transport.close();

          this._transport.removeAllListeners();
        };

        module.exports = FacadeJS;
      }, {
        "./utils/iframe": 46,
        "json3": 54
      }],
      7: [function (_dereq_, module, exports) {
        'use strict';

        var urlUtils = _dereq_('./utils/url'),
            eventUtils = _dereq_('./utils/event'),
            JSON3 = _dereq_('json3'),
            FacadeJS = _dereq_('./facade'),
            InfoIframeReceiver = _dereq_('./info-iframe-receiver'),
            iframeUtils = _dereq_('./utils/iframe'),
            loc = _dereq_('./location');

        module.exports = function (SockJS, availableTransports) {
          var transportMap = {};
          availableTransports.forEach(function (at) {
            if (at.facadeTransport) {
              transportMap[at.facadeTransport.transportName] = at.facadeTransport;
            }
          }); // hard-coded for the info iframe
          // TODO see if we can make this more dynamic

          transportMap[InfoIframeReceiver.transportName] = InfoIframeReceiver;
          var parentOrigin;
          /* eslint-disable camelcase */

          SockJS.bootstrap_iframe = function () {
            /* eslint-enable camelcase */
            var facade;
            iframeUtils.currentWindowId = loc.hash.slice(1);

            var onMessage = function (e) {
              if (e.source !== parent) {
                return;
              }

              if (typeof parentOrigin === 'undefined') {
                parentOrigin = e.origin;
              }

              if (e.origin !== parentOrigin) {
                return;
              }

              var iframeMessage;

              try {
                iframeMessage = JSON3.parse(e.data);
              } catch (ignored) {
                return;
              }

              if (iframeMessage.windowId !== iframeUtils.currentWindowId) {
                return;
              }

              switch (iframeMessage.type) {
                case 's':
                  var p;

                  try {
                    p = JSON3.parse(iframeMessage.data);
                  } catch (ignored) {
                    break;
                  }

                  var version = p[0];
                  var transport = p[1];
                  var transUrl = p[2];
                  var baseUrl = p[3]; // change this to semver logic

                  if (version !== SockJS.version) {
                    throw new Error('Incompatible SockJS! Main site uses:' + ' "' + version + '", the iframe:' + ' "' + SockJS.version + '".');
                  }

                  if (!urlUtils.isOriginEqual(transUrl, loc.href) || !urlUtils.isOriginEqual(baseUrl, loc.href)) {
                    throw new Error('Can\'t connect to different domain from within an ' + 'iframe. (' + loc.href + ', ' + transUrl + ', ' + baseUrl + ')');
                  }

                  facade = new FacadeJS(new transportMap[transport](transUrl, baseUrl));
                  break;

                case 'm':
                  facade._send(iframeMessage.data);

                  break;

                case 'c':
                  if (facade) {
                    facade._close();
                  }

                  facade = null;
                  break;
              }
            };

            eventUtils.attachEvent('message', onMessage); // Start

            iframeUtils.postMessage('s');
          };
        };
      }, {
        "./facade": 6,
        "./info-iframe-receiver": 9,
        "./location": 12,
        "./utils/event": 45,
        "./utils/iframe": 46,
        "./utils/url": 51,
        "json3": 54
      }],
      8: [function (_dereq_, module, exports) {
        'use strict';

        var EventEmitter = _dereq_('events').EventEmitter,
            inherits = _dereq_('inherits'),
            JSON3 = _dereq_('json3'),
            objectUtils = _dereq_('./utils/object');

        function InfoAjax(url, AjaxObject) {
          EventEmitter.call(this);
          var self = this;
          var t0 = +new Date();
          this.xo = new AjaxObject('GET', url);
          this.xo.once('finish', function (status, text) {
            var info, rtt;

            if (status === 200) {
              rtt = +new Date() - t0;

              if (text) {
                try {
                  info = JSON3.parse(text);
                } catch (e) {}
              }

              if (!objectUtils.isObject(info)) {
                info = {};
              }
            }

            self.emit('finish', info, rtt);
            self.removeAllListeners();
          });
        }

        inherits(InfoAjax, EventEmitter);

        InfoAjax.prototype.close = function () {
          this.removeAllListeners();
          this.xo.close();
        };

        module.exports = InfoAjax;
      }, {
        "./utils/object": 48,
        "events": 2,
        "inherits": 53,
        "json3": 54
      }],
      9: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter,
            JSON3 = _dereq_('json3'),
            XHRLocalObject = _dereq_('./transport/sender/xhr-local'),
            InfoAjax = _dereq_('./info-ajax');

        function InfoReceiverIframe(transUrl) {
          var self = this;
          EventEmitter.call(this);
          this.ir = new InfoAjax(transUrl, XHRLocalObject);
          this.ir.once('finish', function (info, rtt) {
            self.ir = null;
            self.emit('message', JSON3.stringify([info, rtt]));
          });
        }

        inherits(InfoReceiverIframe, EventEmitter);
        InfoReceiverIframe.transportName = 'iframe-info-receiver';

        InfoReceiverIframe.prototype.close = function () {
          if (this.ir) {
            this.ir.close();
            this.ir = null;
          }

          this.removeAllListeners();
        };

        module.exports = InfoReceiverIframe;
      }, {
        "./info-ajax": 8,
        "./transport/sender/xhr-local": 36,
        "events": 2,
        "inherits": 53,
        "json3": 54
      }],
      10: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var EventEmitter = _dereq_('events').EventEmitter,
              inherits = _dereq_('inherits'),
              JSON3 = _dereq_('json3'),
              utils = _dereq_('./utils/event'),
              IframeTransport = _dereq_('./transport/iframe'),
              InfoReceiverIframe = _dereq_('./info-iframe-receiver');

          function InfoIframe(baseUrl, url) {
            var self = this;
            EventEmitter.call(this);

            var go = function () {
              var ifr = self.ifr = new IframeTransport(InfoReceiverIframe.transportName, url, baseUrl);
              ifr.once('message', function (msg) {
                if (msg) {
                  var d;

                  try {
                    d = JSON3.parse(msg);
                  } catch (e) {
                    self.emit('finish');
                    self.close();
                    return;
                  }

                  var info = d[0],
                      rtt = d[1];
                  self.emit('finish', info, rtt);
                }

                self.close();
              });
              ifr.once('close', function () {
                self.emit('finish');
                self.close();
              });
            }; // TODO this seems the same as the 'needBody' from transports


            if (!global.document.body) {
              utils.attachEvent('load', go);
            } else {
              go();
            }
          }

          inherits(InfoIframe, EventEmitter);

          InfoIframe.enabled = function () {
            return IframeTransport.enabled();
          };

          InfoIframe.prototype.close = function () {
            if (this.ifr) {
              this.ifr.close();
            }

            this.removeAllListeners();
            this.ifr = null;
          };

          module.exports = InfoIframe;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./info-iframe-receiver": 9,
        "./transport/iframe": 21,
        "./utils/event": 45,
        "events": 2,
        "inherits": 53,
        "json3": 54
      }],
      11: [function (_dereq_, module, exports) {
        'use strict';

        var EventEmitter = _dereq_('events').EventEmitter,
            inherits = _dereq_('inherits'),
            urlUtils = _dereq_('./utils/url'),
            XDR = _dereq_('./transport/sender/xdr'),
            XHRCors = _dereq_('./transport/sender/xhr-cors'),
            XHRLocal = _dereq_('./transport/sender/xhr-local'),
            XHRFake = _dereq_('./transport/sender/xhr-fake'),
            InfoIframe = _dereq_('./info-iframe'),
            InfoAjax = _dereq_('./info-ajax');

        function InfoReceiver(baseUrl, urlInfo) {
          var self = this;
          EventEmitter.call(this);
          setTimeout(function () {
            self.doXhr(baseUrl, urlInfo);
          }, 0);
        }

        inherits(InfoReceiver, EventEmitter); // TODO this is currently ignoring the list of available transports and the whitelist

        InfoReceiver._getReceiver = function (baseUrl, url, urlInfo) {
          // determine method of CORS support (if needed)
          if (urlInfo.sameOrigin) {
            return new InfoAjax(url, XHRLocal);
          }

          if (XHRCors.enabled) {
            return new InfoAjax(url, XHRCors);
          }

          if (XDR.enabled && urlInfo.sameScheme) {
            return new InfoAjax(url, XDR);
          }

          if (InfoIframe.enabled()) {
            return new InfoIframe(baseUrl, url);
          }

          return new InfoAjax(url, XHRFake);
        };

        InfoReceiver.prototype.doXhr = function (baseUrl, urlInfo) {
          var self = this,
              url = urlUtils.addPath(baseUrl, '/info');
          this.xo = InfoReceiver._getReceiver(baseUrl, url, urlInfo);
          this.timeoutRef = setTimeout(function () {
            self._cleanup(false);

            self.emit('finish');
          }, InfoReceiver.timeout);
          this.xo.once('finish', function (info, rtt) {
            self._cleanup(true);

            self.emit('finish', info, rtt);
          });
        };

        InfoReceiver.prototype._cleanup = function (wasClean) {
          clearTimeout(this.timeoutRef);
          this.timeoutRef = null;

          if (!wasClean && this.xo) {
            this.xo.close();
          }

          this.xo = null;
        };

        InfoReceiver.prototype.close = function () {
          this.removeAllListeners();

          this._cleanup(false);
        };

        InfoReceiver.timeout = 8000;
        module.exports = InfoReceiver;
      }, {
        "./info-ajax": 8,
        "./info-iframe": 10,
        "./transport/sender/xdr": 33,
        "./transport/sender/xhr-cors": 34,
        "./transport/sender/xhr-fake": 35,
        "./transport/sender/xhr-local": 36,
        "./utils/url": 51,
        "events": 2,
        "inherits": 53
      }],
      12: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          module.exports = global.location || {
            origin: 'http://localhost:80',
            protocol: 'http:',
            host: 'localhost',
            port: 80,
            href: 'http://localhost/',
            hash: ''
          };
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      13: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          _dereq_('./shims');

          var URL = _dereq_('url-parse'),
              inherits = _dereq_('inherits'),
              JSON3 = _dereq_('json3'),
              random = _dereq_('./utils/random'),
              escape = _dereq_('./utils/escape'),
              urlUtils = _dereq_('./utils/url'),
              eventUtils = _dereq_('./utils/event'),
              transport = _dereq_('./utils/transport'),
              objectUtils = _dereq_('./utils/object'),
              browser = _dereq_('./utils/browser'),
              log = _dereq_('./utils/log'),
              Event = _dereq_('./event/event'),
              EventTarget = _dereq_('./event/eventtarget'),
              loc = _dereq_('./location'),
              CloseEvent = _dereq_('./event/close'),
              TransportMessageEvent = _dereq_('./event/trans-message'),
              InfoReceiver = _dereq_('./info-receiver');

          var transports; // follow constructor steps defined at http://dev.w3.org/html5/websockets/#the-websocket-interface

          function SockJS(url, protocols, options) {
            if (!(this instanceof SockJS)) {
              return new SockJS(url, protocols, options);
            }

            if (arguments.length < 1) {
              throw new TypeError("Failed to construct 'SockJS: 1 argument required, but only 0 present");
            }

            EventTarget.call(this);
            this.readyState = SockJS.CONNECTING;
            this.extensions = '';
            this.protocol = ''; // non-standard extension

            options = options || {};

            if (options.protocols_whitelist) {
              log.warn("'protocols_whitelist' is DEPRECATED. Use 'transports' instead.");
            }

            this._transportsWhitelist = options.transports;
            this._transportOptions = options.transportOptions || {};
            this._timeout = options.timeout || 0;
            var sessionId = options.sessionId || 8;

            if (typeof sessionId === 'function') {
              this._generateSessionId = sessionId;
            } else if (typeof sessionId === 'number') {
              this._generateSessionId = function () {
                return random.string(sessionId);
              };
            } else {
              throw new TypeError('If sessionId is used in the options, it needs to be a number or a function.');
            }

            this._server = options.server || random.numberString(1000); // Step 1 of WS spec - parse and validate the url. Issue #8

            var parsedUrl = new URL(url);

            if (!parsedUrl.host || !parsedUrl.protocol) {
              throw new SyntaxError("The URL '" + url + "' is invalid");
            } else if (parsedUrl.hash) {
              throw new SyntaxError('The URL must not contain a fragment');
            } else if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
              throw new SyntaxError("The URL's scheme must be either 'http:' or 'https:'. '" + parsedUrl.protocol + "' is not allowed.");
            }

            var secure = parsedUrl.protocol === 'https:'; // Step 2 - don't allow secure origin with an insecure protocol

            if (loc.protocol === 'https:' && !secure) {
              // exception is 127.0.0.0/8 and ::1 urls
              if (!urlUtils.isLoopbackAddr(parsedUrl.hostname)) {
                throw new Error('SecurityError: An insecure SockJS connection may not be initiated from a page loaded over HTTPS');
              }
            } // Step 3 - check port access - no need here
            // Step 4 - parse protocols argument


            if (!protocols) {
              protocols = [];
            } else if (!Array.isArray(protocols)) {
              protocols = [protocols];
            } // Step 5 - check protocols argument


            var sortedProtocols = protocols.sort();
            sortedProtocols.forEach(function (proto, i) {
              if (!proto) {
                throw new SyntaxError("The protocols entry '" + proto + "' is invalid.");
              }

              if (i < sortedProtocols.length - 1 && proto === sortedProtocols[i + 1]) {
                throw new SyntaxError("The protocols entry '" + proto + "' is duplicated.");
              }
            }); // Step 6 - convert origin

            var o = urlUtils.getOrigin(loc.href);
            this._origin = o ? o.toLowerCase() : null; // remove the trailing slash

            parsedUrl.set('pathname', parsedUrl.pathname.replace(/\/+$/, '')); // store the sanitized url

            this.url = parsedUrl.href; // Step 7 - start connection in background
            // obtain server info
            // http://sockjs.github.io/sockjs-protocol/sockjs-protocol-0.3.3.html#section-26

            this._urlInfo = {
              nullOrigin: !browser.hasDomain(),
              sameOrigin: urlUtils.isOriginEqual(this.url, loc.href),
              sameScheme: urlUtils.isSchemeEqual(this.url, loc.href)
            };
            this._ir = new InfoReceiver(this.url, this._urlInfo);

            this._ir.once('finish', this._receiveInfo.bind(this));
          }

          inherits(SockJS, EventTarget);

          function userSetCode(code) {
            return code === 1000 || code >= 3000 && code <= 4999;
          }

          SockJS.prototype.close = function (code, reason) {
            // Step 1
            if (code && !userSetCode(code)) {
              throw new Error('InvalidAccessError: Invalid code');
            } // Step 2.4 states the max is 123 bytes, but we are just checking length


            if (reason && reason.length > 123) {
              throw new SyntaxError('reason argument has an invalid length');
            } // Step 3.1


            if (this.readyState === SockJS.CLOSING || this.readyState === SockJS.CLOSED) {
              return;
            } // TODO look at docs to determine how to set this


            var wasClean = true;

            this._close(code || 1000, reason || 'Normal closure', wasClean);
          };

          SockJS.prototype.send = function (data) {
            // #13 - convert anything non-string to string
            // TODO this currently turns objects into [object Object]
            if (typeof data !== 'string') {
              data = '' + data;
            }

            if (this.readyState === SockJS.CONNECTING) {
              throw new Error('InvalidStateError: The connection has not been established yet');
            }

            if (this.readyState !== SockJS.OPEN) {
              return;
            }

            this._transport.send(escape.quote(data));
          };

          SockJS.version = _dereq_('./version');
          SockJS.CONNECTING = 0;
          SockJS.OPEN = 1;
          SockJS.CLOSING = 2;
          SockJS.CLOSED = 3;

          SockJS.prototype._receiveInfo = function (info, rtt) {
            this._ir = null;

            if (!info) {
              this._close(1002, 'Cannot connect to server');

              return;
            } // establish a round-trip timeout (RTO) based on the
            // round-trip time (RTT)


            this._rto = this.countRTO(rtt); // allow server to override url used for the actual transport

            this._transUrl = info.base_url ? info.base_url : this.url;
            info = objectUtils.extend(info, this._urlInfo); // determine list of desired and supported transports

            var enabledTransports = transports.filterToEnabled(this._transportsWhitelist, info);
            this._transports = enabledTransports.main;

            this._connect();
          };

          SockJS.prototype._connect = function () {
            for (var Transport = this._transports.shift(); Transport; Transport = this._transports.shift()) {
              if (Transport.needBody) {
                if (!global.document.body || typeof global.document.readyState !== 'undefined' && global.document.readyState !== 'complete' && global.document.readyState !== 'interactive') {
                  this._transports.unshift(Transport);

                  eventUtils.attachEvent('load', this._connect.bind(this));
                  return;
                }
              } // calculate timeout based on RTO and round trips. Default to 5s


              var timeoutMs = Math.max(this._timeout, this._rto * Transport.roundTrips || 5000);
              this._transportTimeoutId = setTimeout(this._transportTimeout.bind(this), timeoutMs);
              var transportUrl = urlUtils.addPath(this._transUrl, '/' + this._server + '/' + this._generateSessionId());
              var options = this._transportOptions[Transport.transportName];
              var transportObj = new Transport(transportUrl, this._transUrl, options);
              transportObj.on('message', this._transportMessage.bind(this));
              transportObj.once('close', this._transportClose.bind(this));
              transportObj.transportName = Transport.transportName;
              this._transport = transportObj;
              return;
            }

            this._close(2000, 'All transports failed', false);
          };

          SockJS.prototype._transportTimeout = function () {
            if (this.readyState === SockJS.CONNECTING) {
              if (this._transport) {
                this._transport.close();
              }

              this._transportClose(2007, 'Transport timed out');
            }
          };

          SockJS.prototype._transportMessage = function (msg) {
            var self = this,
                type = msg.slice(0, 1),
                content = msg.slice(1),
                payload; // first check for messages that don't need a payload

            switch (type) {
              case 'o':
                this._open();

                return;

              case 'h':
                this.dispatchEvent(new Event('heartbeat'));
                return;
            }

            if (content) {
              try {
                payload = JSON3.parse(content);
              } catch (e) {}
            }

            if (typeof payload === 'undefined') {
              return;
            }

            switch (type) {
              case 'a':
                if (Array.isArray(payload)) {
                  payload.forEach(function (p) {
                    self.dispatchEvent(new TransportMessageEvent(p));
                  });
                }

                break;

              case 'm':
                this.dispatchEvent(new TransportMessageEvent(payload));
                break;

              case 'c':
                if (Array.isArray(payload) && payload.length === 2) {
                  this._close(payload[0], payload[1], true);
                }

                break;
            }
          };

          SockJS.prototype._transportClose = function (code, reason) {
            if (this._transport) {
              this._transport.removeAllListeners();

              this._transport = null;
              this.transport = null;
            }

            if (!userSetCode(code) && code !== 2000 && this.readyState === SockJS.CONNECTING) {
              this._connect();

              return;
            }

            this._close(code, reason);
          };

          SockJS.prototype._open = function () {
            if (this.readyState === SockJS.CONNECTING) {
              if (this._transportTimeoutId) {
                clearTimeout(this._transportTimeoutId);
                this._transportTimeoutId = null;
              }

              this.readyState = SockJS.OPEN;
              this.transport = this._transport.transportName;
              this.dispatchEvent(new Event('open'));
            } else {
              // The server might have been restarted, and lost track of our
              // connection.
              this._close(1006, 'Server lost session');
            }
          };

          SockJS.prototype._close = function (code, reason, wasClean) {
            var forceFail = false;

            if (this._ir) {
              forceFail = true;

              this._ir.close();

              this._ir = null;
            }

            if (this._transport) {
              this._transport.close();

              this._transport = null;
              this.transport = null;
            }

            if (this.readyState === SockJS.CLOSED) {
              throw new Error('InvalidStateError: SockJS has already been closed');
            }

            this.readyState = SockJS.CLOSING;
            setTimeout(function () {
              this.readyState = SockJS.CLOSED;

              if (forceFail) {
                this.dispatchEvent(new Event('error'));
              }

              var e = new CloseEvent('close');
              e.wasClean = wasClean || false;
              e.code = code || 1000;
              e.reason = reason;
              this.dispatchEvent(e);
              this.onmessage = this.onclose = this.onerror = null;
            }.bind(this), 0);
          }; // See: http://www.erg.abdn.ac.uk/~gerrit/dccp/notes/ccid2/rto_estimator/
          // and RFC 2988.


          SockJS.prototype.countRTO = function (rtt) {
            // In a local environment, when using IE8/9 and the `jsonp-polling`
            // transport the time needed to establish a connection (the time that pass
            // from the opening of the transport to the call of `_dispatchOpen`) is
            // around 200msec (the lower bound used in the article above) and this
            // causes spurious timeouts. For this reason we calculate a value slightly
            // larger than that used in the article.
            if (rtt > 100) {
              return 4 * rtt; // rto > 400msec
            }

            return 300 + rtt; // 300msec < rto <= 400msec
          };

          module.exports = function (availableTransports) {
            transports = transport(availableTransports);

            _dereq_('./iframe-bootstrap')(SockJS, availableTransports);

            return SockJS;
          };
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./event/close": 1,
        "./event/event": 3,
        "./event/eventtarget": 4,
        "./event/trans-message": 5,
        "./iframe-bootstrap": 7,
        "./info-receiver": 11,
        "./location": 12,
        "./shims": 14,
        "./utils/browser": 43,
        "./utils/escape": 44,
        "./utils/event": 45,
        "./utils/log": 47,
        "./utils/object": 48,
        "./utils/random": 49,
        "./utils/transport": 50,
        "./utils/url": 51,
        "./version": 52,
        "inherits": 53,
        "json3": 54,
        "url-parse": 57
      }],
      14: [function (_dereq_, module, exports) {
        /* eslint-disable */

        /* jscs: disable */
        'use strict'; // pulled specific shims from https://github.com/es-shims/es5-shim

        var ArrayPrototype = Array.prototype;
        var ObjectPrototype = Object.prototype;
        var FunctionPrototype = Function.prototype;
        var StringPrototype = String.prototype;
        var array_slice = ArrayPrototype.slice;
        var _toString = ObjectPrototype.toString;

        var isFunction = function (val) {
          return ObjectPrototype.toString.call(val) === '[object Function]';
        };

        var isArray = function isArray(obj) {
          return _toString.call(obj) === '[object Array]';
        };

        var isString = function isString(obj) {
          return _toString.call(obj) === '[object String]';
        };

        var supportsDescriptors = Object.defineProperty && function () {
          try {
            Object.defineProperty({}, 'x', {});
            return true;
          } catch (e) {
            /* this is ES3 */
            return false;
          }
        }(); // Define configurable, writable and non-enumerable props
        // if they don't exist.


        var defineProperty;

        if (supportsDescriptors) {
          defineProperty = function (object, name, method, forceAssign) {
            if (!forceAssign && name in object) {
              return;
            }

            Object.defineProperty(object, name, {
              configurable: true,
              enumerable: false,
              writable: true,
              value: method
            });
          };
        } else {
          defineProperty = function (object, name, method, forceAssign) {
            if (!forceAssign && name in object) {
              return;
            }

            object[name] = method;
          };
        }

        var defineProperties = function (object, map, forceAssign) {
          for (var name in map) {
            if (ObjectPrototype.hasOwnProperty.call(map, name)) {
              defineProperty(object, name, map[name], forceAssign);
            }
          }
        };

        var toObject = function (o) {
          if (o == null) {
            // this matches both null and undefined
            throw new TypeError("can't convert " + o + ' to object');
          }

          return Object(o);
        }; //
        // Util
        // ======
        //
        // ES5 9.4
        // http://es5.github.com/#x9.4
        // http://jsperf.com/to-integer


        function toInteger(num) {
          var n = +num;

          if (n !== n) {
            // isNaN
            n = 0;
          } else if (n !== 0 && n !== 1 / 0 && n !== -(1 / 0)) {
            n = (n > 0 || -1) * Math.floor(Math.abs(n));
          }

          return n;
        }

        function ToUint32(x) {
          return x >>> 0;
        } //
        // Function
        // ========
        //
        // ES-5 15.3.4.5
        // http://es5.github.com/#x15.3.4.5


        function Empty() {}

        defineProperties(FunctionPrototype, {
          bind: function bind(that) {
            // .length is 1
            // 1. Let Target be the this value.
            var target = this; // 2. If IsCallable(Target) is false, throw a TypeError exception.

            if (!isFunction(target)) {
              throw new TypeError('Function.prototype.bind called on incompatible ' + target);
            } // 3. Let A be a new (possibly empty) internal list of all of the
            //   argument values provided after thisArg (arg1, arg2 etc), in order.
            // XXX slicedArgs will stand in for "A" if used


            var args = array_slice.call(arguments, 1); // for normal call
            // 4. Let F be a new native ECMAScript object.
            // 11. Set the [[Prototype]] internal property of F to the standard
            //   built-in Function prototype object as specified in 15.3.3.1.
            // 12. Set the [[Call]] internal property of F as described in
            //   15.3.4.5.1.
            // 13. Set the [[Construct]] internal property of F as described in
            //   15.3.4.5.2.
            // 14. Set the [[HasInstance]] internal property of F as described in
            //   15.3.4.5.3.

            var binder = function () {
              if (this instanceof bound) {
                // 15.3.4.5.2 [[Construct]]
                // When the [[Construct]] internal method of a function object,
                // F that was created using the bind function is called with a
                // list of arguments ExtraArgs, the following steps are taken:
                // 1. Let target be the value of F's [[TargetFunction]]
                //   internal property.
                // 2. If target has no [[Construct]] internal method, a
                //   TypeError exception is thrown.
                // 3. Let boundArgs be the value of F's [[BoundArgs]] internal
                //   property.
                // 4. Let args be a new list containing the same values as the
                //   list boundArgs in the same order followed by the same
                //   values as the list ExtraArgs in the same order.
                // 5. Return the result of calling the [[Construct]] internal
                //   method of target providing args as the arguments.
                var result = target.apply(this, args.concat(array_slice.call(arguments)));

                if (Object(result) === result) {
                  return result;
                }

                return this;
              } else {
                // 15.3.4.5.1 [[Call]]
                // When the [[Call]] internal method of a function object, F,
                // which was created using the bind function is called with a
                // this value and a list of arguments ExtraArgs, the following
                // steps are taken:
                // 1. Let boundArgs be the value of F's [[BoundArgs]] internal
                //   property.
                // 2. Let boundThis be the value of F's [[BoundThis]] internal
                //   property.
                // 3. Let target be the value of F's [[TargetFunction]] internal
                //   property.
                // 4. Let args be a new list containing the same values as the
                //   list boundArgs in the same order followed by the same
                //   values as the list ExtraArgs in the same order.
                // 5. Return the result of calling the [[Call]] internal method
                //   of target providing boundThis as the this value and
                //   providing args as the arguments.
                // equiv: target.call(this, ...boundArgs, ...args)
                return target.apply(that, args.concat(array_slice.call(arguments)));
              }
            }; // 15. If the [[Class]] internal property of Target is "Function", then
            //     a. Let L be the length property of Target minus the length of A.
            //     b. Set the length own property of F to either 0 or L, whichever is
            //       larger.
            // 16. Else set the length own property of F to 0.


            var boundLength = Math.max(0, target.length - args.length); // 17. Set the attributes of the length own property of F to the values
            //   specified in 15.3.5.1.

            var boundArgs = [];

            for (var i = 0; i < boundLength; i++) {
              boundArgs.push('$' + i);
            } // XXX Build a dynamic function with desired amount of arguments is the only
            // way to set the length property of a function.
            // In environments where Content Security Policies enabled (Chrome extensions,
            // for ex.) all use of eval or Function costructor throws an exception.
            // However in all of these environments Function.prototype.bind exists
            // and so this code will never be executed.


            var bound = Function('binder', 'return function (' + boundArgs.join(',') + '){ return binder.apply(this, arguments); }')(binder);

            if (target.prototype) {
              Empty.prototype = target.prototype;
              bound.prototype = new Empty(); // Clean up dangling references.

              Empty.prototype = null;
            } // TODO
            // 18. Set the [[Extensible]] internal property of F to true.
            // TODO
            // 19. Let thrower be the [[ThrowTypeError]] function Object (13.2.3).
            // 20. Call the [[DefineOwnProperty]] internal method of F with
            //   arguments "caller", PropertyDescriptor {[[Get]]: thrower, [[Set]]:
            //   thrower, [[Enumerable]]: false, [[Configurable]]: false}, and
            //   false.
            // 21. Call the [[DefineOwnProperty]] internal method of F with
            //   arguments "arguments", PropertyDescriptor {[[Get]]: thrower,
            //   [[Set]]: thrower, [[Enumerable]]: false, [[Configurable]]: false},
            //   and false.
            // TODO
            // NOTE Function objects created using Function.prototype.bind do not
            // have a prototype property or the [[Code]], [[FormalParameters]], and
            // [[Scope]] internal properties.
            // XXX can't delete prototype in pure-js.
            // 22. Return F.


            return bound;
          }
        }); //
        // Array
        // =====
        //
        // ES5 15.4.3.2
        // http://es5.github.com/#x15.4.3.2
        // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/isArray

        defineProperties(Array, {
          isArray: isArray
        });
        var boxedString = Object('a');
        var splitString = boxedString[0] !== 'a' || !(0 in boxedString);

        var properlyBoxesContext = function properlyBoxed(method) {
          // Check node 0.6.21 bug where third parameter is not boxed
          var properlyBoxesNonStrict = true;
          var properlyBoxesStrict = true;

          if (method) {
            method.call('foo', function (_, __, context) {
              if (typeof context !== 'object') {
                properlyBoxesNonStrict = false;
              }
            });
            method.call([1], function () {
              'use strict';

              properlyBoxesStrict = typeof this === 'string';
            }, 'x');
          }

          return !!method && properlyBoxesNonStrict && properlyBoxesStrict;
        };

        defineProperties(ArrayPrototype, {
          forEach: function forEach(fun
          /*, thisp*/
          ) {
            var object = toObject(this),
                self = splitString && isString(this) ? this.split('') : object,
                thisp = arguments[1],
                i = -1,
                length = self.length >>> 0; // If no callback function or if callback is not a callable function

            if (!isFunction(fun)) {
              throw new TypeError(); // TODO message
            }

            while (++i < length) {
              if (i in self) {
                // Invoke the callback function with call, passing arguments:
                // context, property value, property key, thisArg object
                // context
                fun.call(thisp, self[i], i, object);
              }
            }
          }
        }, !properlyBoxesContext(ArrayPrototype.forEach)); // ES5 15.4.4.14
        // http://es5.github.com/#x15.4.4.14
        // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/indexOf

        var hasFirefox2IndexOfBug = Array.prototype.indexOf && [0, 1].indexOf(1, 2) !== -1;
        defineProperties(ArrayPrototype, {
          indexOf: function indexOf(sought
          /*, fromIndex */
          ) {
            var self = splitString && isString(this) ? this.split('') : toObject(this),
                length = self.length >>> 0;

            if (!length) {
              return -1;
            }

            var i = 0;

            if (arguments.length > 1) {
              i = toInteger(arguments[1]);
            } // handle negative indices


            i = i >= 0 ? i : Math.max(0, length + i);

            for (; i < length; i++) {
              if (i in self && self[i] === sought) {
                return i;
              }
            }

            return -1;
          }
        }, hasFirefox2IndexOfBug); //
        // String
        // ======
        //
        // ES5 15.5.4.14
        // http://es5.github.com/#x15.5.4.14
        // [bugfix, IE lt 9, firefox 4, Konqueror, Opera, obscure browsers]
        // Many browsers do not split properly with regular expressions or they
        // do not perform the split correctly under obscure conditions.
        // See http://blog.stevenlevithan.com/archives/cross-browser-split
        // I've tested in many browsers and this seems to cover the deviant ones:
        //    'ab'.split(/(?:ab)*/) should be ["", ""], not [""]
        //    '.'.split(/(.?)(.?)/) should be ["", ".", "", ""], not ["", ""]
        //    'tesst'.split(/(s)*/) should be ["t", undefined, "e", "s", "t"], not
        //       [undefined, "t", undefined, "e", ...]
        //    ''.split(/.?/) should be [], not [""]
        //    '.'.split(/()()/) should be ["."], not ["", "", "."]

        var string_split = StringPrototype.split;

        if ('ab'.split(/(?:ab)*/).length !== 2 || '.'.split(/(.?)(.?)/).length !== 4 || 'tesst'.split(/(s)*/)[1] === 't' || 'test'.split(/(?:)/, -1).length !== 4 || ''.split(/.?/).length || '.'.split(/()()/).length > 1) {
          (function () {
            var compliantExecNpcg = /()??/.exec('')[1] === void 0; // NPCG: nonparticipating capturing group

            StringPrototype.split = function (separator, limit) {
              var string = this;

              if (separator === void 0 && limit === 0) {
                return [];
              } // If `separator` is not a regex, use native split


              if (_toString.call(separator) !== '[object RegExp]') {
                return string_split.call(this, separator, limit);
              }

              var output = [],
                  flags = (separator.ignoreCase ? 'i' : '') + (separator.multiline ? 'm' : '') + (separator.extended ? 'x' : '') + ( // Proposed for ES6
              separator.sticky ? 'y' : ''),
                  // Firefox 3+
              lastLastIndex = 0,
                  // Make `global` and avoid `lastIndex` issues by working with a copy
              separator2,
                  match,
                  lastIndex,
                  lastLength;
              separator = new RegExp(separator.source, flags + 'g');
              string += ''; // Type-convert

              if (!compliantExecNpcg) {
                // Doesn't need flags gy, but they don't hurt
                separator2 = new RegExp('^' + separator.source + '$(?!\\s)', flags);
              }
              /* Values for `limit`, per the spec:
               * If undefined: 4294967295 // Math.pow(2, 32) - 1
               * If 0, Infinity, or NaN: 0
               * If positive number: limit = Math.floor(limit); if (limit > 4294967295) limit -= 4294967296;
               * If negative number: 4294967296 - Math.floor(Math.abs(limit))
               * If other: Type-convert, then use the above rules
               */


              limit = limit === void 0 ? -1 >>> 0 : // Math.pow(2, 32) - 1
              ToUint32(limit);

              while (match = separator.exec(string)) {
                // `separator.lastIndex` is not reliable cross-browser
                lastIndex = match.index + match[0].length;

                if (lastIndex > lastLastIndex) {
                  output.push(string.slice(lastLastIndex, match.index)); // Fix browsers whose `exec` methods don't consistently return `undefined` for
                  // nonparticipating capturing groups

                  if (!compliantExecNpcg && match.length > 1) {
                    match[0].replace(separator2, function () {
                      for (var i = 1; i < arguments.length - 2; i++) {
                        if (arguments[i] === void 0) {
                          match[i] = void 0;
                        }
                      }
                    });
                  }

                  if (match.length > 1 && match.index < string.length) {
                    ArrayPrototype.push.apply(output, match.slice(1));
                  }

                  lastLength = match[0].length;
                  lastLastIndex = lastIndex;

                  if (output.length >= limit) {
                    break;
                  }
                }

                if (separator.lastIndex === match.index) {
                  separator.lastIndex++; // Avoid an infinite loop
                }
              }

              if (lastLastIndex === string.length) {
                if (lastLength || !separator.test('')) {
                  output.push('');
                }
              } else {
                output.push(string.slice(lastLastIndex));
              }

              return output.length > limit ? output.slice(0, limit) : output;
            };
          })(); // [bugfix, chrome]
          // If separator is undefined, then the result array contains just one String,
          // which is the this value (converted to a String). If limit is not undefined,
          // then the output array is truncated so that it contains no more than limit
          // elements.
          // "0".split(undefined, 0) -> []

        } else if ('0'.split(void 0, 0).length) {
          StringPrototype.split = function split(separator, limit) {
            if (separator === void 0 && limit === 0) {
              return [];
            }

            return string_split.call(this, separator, limit);
          };
        } // ECMA-262, 3rd B.2.3
        // Not an ECMAScript standard, although ECMAScript 3rd Edition has a
        // non-normative section suggesting uniform semantics and it should be
        // normalized across all browsers
        // [bugfix, IE lt 9] IE < 9 substr() with negative value not working in IE


        var string_substr = StringPrototype.substr;
        var hasNegativeSubstrBug = ''.substr && '0b'.substr(-1) !== 'b';
        defineProperties(StringPrototype, {
          substr: function substr(start, length) {
            return string_substr.call(this, start < 0 ? (start = this.length + start) < 0 ? 0 : start : start, length);
          }
        }, hasNegativeSubstrBug);
      }, {}],
      15: [function (_dereq_, module, exports) {
        'use strict';

        module.exports = [// streaming transports
        _dereq_('./transport/websocket'), _dereq_('./transport/xhr-streaming'), _dereq_('./transport/xdr-streaming'), _dereq_('./transport/eventsource'), _dereq_('./transport/lib/iframe-wrap')(_dereq_('./transport/eventsource')) // polling transports
        , _dereq_('./transport/htmlfile'), _dereq_('./transport/lib/iframe-wrap')(_dereq_('./transport/htmlfile')), _dereq_('./transport/xhr-polling'), _dereq_('./transport/xdr-polling'), _dereq_('./transport/lib/iframe-wrap')(_dereq_('./transport/xhr-polling')), _dereq_('./transport/jsonp-polling')];
      }, {
        "./transport/eventsource": 19,
        "./transport/htmlfile": 20,
        "./transport/jsonp-polling": 22,
        "./transport/lib/iframe-wrap": 25,
        "./transport/websocket": 37,
        "./transport/xdr-polling": 38,
        "./transport/xdr-streaming": 39,
        "./transport/xhr-polling": 40,
        "./transport/xhr-streaming": 41
      }],
      16: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var EventEmitter = _dereq_('events').EventEmitter,
              inherits = _dereq_('inherits'),
              utils = _dereq_('../../utils/event'),
              urlUtils = _dereq_('../../utils/url'),
              XHR = global.XMLHttpRequest;

          function AbstractXHRObject(method, url, payload, opts) {
            var self = this;
            EventEmitter.call(this);
            setTimeout(function () {
              self._start(method, url, payload, opts);
            }, 0);
          }

          inherits(AbstractXHRObject, EventEmitter);

          AbstractXHRObject.prototype._start = function (method, url, payload, opts) {
            var self = this;

            try {
              this.xhr = new XHR();
            } catch (x) {// intentionally empty
            }

            if (!this.xhr) {
              this.emit('finish', 0, 'no xhr support');

              this._cleanup();

              return;
            } // several browsers cache POSTs


            url = urlUtils.addQuery(url, 't=' + +new Date()); // Explorer tends to keep connection open, even after the
            // tab gets closed: http://bugs.jquery.com/ticket/5280

            this.unloadRef = utils.unloadAdd(function () {
              self._cleanup(true);
            });

            try {
              this.xhr.open(method, url, true);

              if (this.timeout && 'timeout' in this.xhr) {
                this.xhr.timeout = this.timeout;

                this.xhr.ontimeout = function () {
                  self.emit('finish', 0, '');

                  self._cleanup(false);
                };
              }
            } catch (e) {
              // IE raises an exception on wrong port.
              this.emit('finish', 0, '');

              this._cleanup(false);

              return;
            }

            if ((!opts || !opts.noCredentials) && AbstractXHRObject.supportsCORS) {
              // Mozilla docs says https://developer.mozilla.org/en/XMLHttpRequest :
              // "This never affects same-site requests."
              this.xhr.withCredentials = true;
            }

            if (opts && opts.headers) {
              for (var key in opts.headers) {
                this.xhr.setRequestHeader(key, opts.headers[key]);
              }
            }

            this.xhr.onreadystatechange = function () {
              if (self.xhr) {
                var x = self.xhr;
                var text, status;

                switch (x.readyState) {
                  case 3:
                    // IE doesn't like peeking into responseText or status
                    // on Microsoft.XMLHTTP and readystate=3
                    try {
                      status = x.status;
                      text = x.responseText;
                    } catch (e) {// intentionally empty
                    } // IE returns 1223 for 204: http://bugs.jquery.com/ticket/1450


                    if (status === 1223) {
                      status = 204;
                    } // IE does return readystate == 3 for 404 answers.


                    if (status === 200 && text && text.length > 0) {
                      self.emit('chunk', status, text);
                    }

                    break;

                  case 4:
                    status = x.status; // IE returns 1223 for 204: http://bugs.jquery.com/ticket/1450

                    if (status === 1223) {
                      status = 204;
                    } // IE returns this for a bad port
                    // http://msdn.microsoft.com/en-us/library/windows/desktop/aa383770(v=vs.85).aspx


                    if (status === 12005 || status === 12029) {
                      status = 0;
                    }

                    self.emit('finish', status, x.responseText);

                    self._cleanup(false);

                    break;
                }
              }
            };

            try {
              self.xhr.send(payload);
            } catch (e) {
              self.emit('finish', 0, '');

              self._cleanup(false);
            }
          };

          AbstractXHRObject.prototype._cleanup = function (abort) {
            if (!this.xhr) {
              return;
            }

            this.removeAllListeners();
            utils.unloadDel(this.unloadRef); // IE needs this field to be a function

            this.xhr.onreadystatechange = function () {};

            if (this.xhr.ontimeout) {
              this.xhr.ontimeout = null;
            }

            if (abort) {
              try {
                this.xhr.abort();
              } catch (x) {// intentionally empty
              }
            }

            this.unloadRef = this.xhr = null;
          };

          AbstractXHRObject.prototype.close = function () {
            this._cleanup(true);
          };

          AbstractXHRObject.enabled = !!XHR; // override XMLHttpRequest for IE6/7
          // obfuscate to avoid firewalls

          var axo = ['Active'].concat('Object').join('X');

          if (!AbstractXHRObject.enabled && axo in global) {
            XHR = function () {
              try {
                return new global[axo]('Microsoft.XMLHTTP');
              } catch (e) {
                return null;
              }
            };

            AbstractXHRObject.enabled = !!new XHR();
          }

          var cors = false;

          try {
            cors = 'withCredentials' in new XHR();
          } catch (ignored) {// intentionally empty
          }

          AbstractXHRObject.supportsCORS = cors;
          module.exports = AbstractXHRObject;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/event": 45,
        "../../utils/url": 51,
        "events": 2,
        "inherits": 53
      }],
      17: [function (_dereq_, module, exports) {
        (function (global) {
          module.exports = global.EventSource;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      18: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var Driver = global.WebSocket || global.MozWebSocket;

          if (Driver) {
            module.exports = function WebSocketBrowserDriver(url) {
              return new Driver(url);
            };
          } else {
            module.exports = undefined;
          }
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      19: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            AjaxBasedTransport = _dereq_('./lib/ajax-based'),
            EventSourceReceiver = _dereq_('./receiver/eventsource'),
            XHRCorsObject = _dereq_('./sender/xhr-cors'),
            EventSourceDriver = _dereq_('eventsource');

        function EventSourceTransport(transUrl) {
          if (!EventSourceTransport.enabled()) {
            throw new Error('Transport created when disabled');
          }

          AjaxBasedTransport.call(this, transUrl, '/eventsource', EventSourceReceiver, XHRCorsObject);
        }

        inherits(EventSourceTransport, AjaxBasedTransport);

        EventSourceTransport.enabled = function () {
          return !!EventSourceDriver;
        };

        EventSourceTransport.transportName = 'eventsource';
        EventSourceTransport.roundTrips = 2;
        module.exports = EventSourceTransport;
      }, {
        "./lib/ajax-based": 23,
        "./receiver/eventsource": 28,
        "./sender/xhr-cors": 34,
        "eventsource": 17,
        "inherits": 53
      }],
      20: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            HtmlfileReceiver = _dereq_('./receiver/htmlfile'),
            XHRLocalObject = _dereq_('./sender/xhr-local'),
            AjaxBasedTransport = _dereq_('./lib/ajax-based');

        function HtmlFileTransport(transUrl) {
          if (!HtmlfileReceiver.enabled) {
            throw new Error('Transport created when disabled');
          }

          AjaxBasedTransport.call(this, transUrl, '/htmlfile', HtmlfileReceiver, XHRLocalObject);
        }

        inherits(HtmlFileTransport, AjaxBasedTransport);

        HtmlFileTransport.enabled = function (info) {
          return HtmlfileReceiver.enabled && info.sameOrigin;
        };

        HtmlFileTransport.transportName = 'htmlfile';
        HtmlFileTransport.roundTrips = 2;
        module.exports = HtmlFileTransport;
      }, {
        "./lib/ajax-based": 23,
        "./receiver/htmlfile": 29,
        "./sender/xhr-local": 36,
        "inherits": 53
      }],
      21: [function (_dereq_, module, exports) {
        'use strict'; // Few cool transports do work only for same-origin. In order to make
        // them work cross-domain we shall use iframe, served from the
        // remote domain. New browsers have capabilities to communicate with
        // cross domain iframe using postMessage(). In IE it was implemented
        // from IE 8+, but of course, IE got some details wrong:
        //    http://msdn.microsoft.com/en-us/library/cc197015(v=VS.85).aspx
        //    http://stevesouders.com/misc/test-postmessage.php

        var inherits = _dereq_('inherits'),
            JSON3 = _dereq_('json3'),
            EventEmitter = _dereq_('events').EventEmitter,
            version = _dereq_('../version'),
            urlUtils = _dereq_('../utils/url'),
            iframeUtils = _dereq_('../utils/iframe'),
            eventUtils = _dereq_('../utils/event'),
            random = _dereq_('../utils/random');

        function IframeTransport(transport, transUrl, baseUrl) {
          if (!IframeTransport.enabled()) {
            throw new Error('Transport created when disabled');
          }

          EventEmitter.call(this);
          var self = this;
          this.origin = urlUtils.getOrigin(baseUrl);
          this.baseUrl = baseUrl;
          this.transUrl = transUrl;
          this.transport = transport;
          this.windowId = random.string(8);
          var iframeUrl = urlUtils.addPath(baseUrl, '/iframe.html') + '#' + this.windowId;
          this.iframeObj = iframeUtils.createIframe(iframeUrl, function (r) {
            self.emit('close', 1006, 'Unable to load an iframe (' + r + ')');
            self.close();
          });
          this.onmessageCallback = this._message.bind(this);
          eventUtils.attachEvent('message', this.onmessageCallback);
        }

        inherits(IframeTransport, EventEmitter);

        IframeTransport.prototype.close = function () {
          this.removeAllListeners();

          if (this.iframeObj) {
            eventUtils.detachEvent('message', this.onmessageCallback);

            try {
              // When the iframe is not loaded, IE raises an exception
              // on 'contentWindow'.
              this.postMessage('c');
            } catch (x) {// intentionally empty
            }

            this.iframeObj.cleanup();
            this.iframeObj = null;
            this.onmessageCallback = this.iframeObj = null;
          }
        };

        IframeTransport.prototype._message = function (e) {
          if (!urlUtils.isOriginEqual(e.origin, this.origin)) {
            return;
          }

          var iframeMessage;

          try {
            iframeMessage = JSON3.parse(e.data);
          } catch (ignored) {
            return;
          }

          if (iframeMessage.windowId !== this.windowId) {
            return;
          }

          switch (iframeMessage.type) {
            case 's':
              this.iframeObj.loaded(); // window global dependency

              this.postMessage('s', JSON3.stringify([version, this.transport, this.transUrl, this.baseUrl]));
              break;

            case 't':
              this.emit('message', iframeMessage.data);
              break;

            case 'c':
              var cdata;

              try {
                cdata = JSON3.parse(iframeMessage.data);
              } catch (ignored) {
                return;
              }

              this.emit('close', cdata[0], cdata[1]);
              this.close();
              break;
          }
        };

        IframeTransport.prototype.postMessage = function (type, data) {
          this.iframeObj.post(JSON3.stringify({
            windowId: this.windowId,
            type: type,
            data: data || ''
          }), this.origin);
        };

        IframeTransport.prototype.send = function (message) {
          this.postMessage('m', message);
        };

        IframeTransport.enabled = function () {
          return iframeUtils.iframeEnabled;
        };

        IframeTransport.transportName = 'iframe';
        IframeTransport.roundTrips = 2;
        module.exports = IframeTransport;
      }, {
        "../utils/event": 45,
        "../utils/iframe": 46,
        "../utils/random": 49,
        "../utils/url": 51,
        "../version": 52,
        "events": 2,
        "inherits": 53,
        "json3": 54
      }],
      22: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict'; // The simplest and most robust transport, using the well-know cross
          // domain hack - JSONP. This transport is quite inefficient - one
          // message could use up to one http request. But at least it works almost
          // everywhere.
          // Known limitations:
          //   o you will get a spinning cursor
          //   o for Konqueror a dumb timer is needed to detect errors

          var inherits = _dereq_('inherits'),
              SenderReceiver = _dereq_('./lib/sender-receiver'),
              JsonpReceiver = _dereq_('./receiver/jsonp'),
              jsonpSender = _dereq_('./sender/jsonp');

          function JsonPTransport(transUrl) {
            if (!JsonPTransport.enabled()) {
              throw new Error('Transport created when disabled');
            }

            SenderReceiver.call(this, transUrl, '/jsonp', jsonpSender, JsonpReceiver);
          }

          inherits(JsonPTransport, SenderReceiver);

          JsonPTransport.enabled = function () {
            return !!global.document;
          };

          JsonPTransport.transportName = 'jsonp-polling';
          JsonPTransport.roundTrips = 1;
          JsonPTransport.needBody = true;
          module.exports = JsonPTransport;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./lib/sender-receiver": 27,
        "./receiver/jsonp": 30,
        "./sender/jsonp": 32,
        "inherits": 53
      }],
      23: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            urlUtils = _dereq_('../../utils/url'),
            SenderReceiver = _dereq_('./sender-receiver');

        function createAjaxSender(AjaxObject) {
          return function (url, payload, callback) {
            var opt = {};

            if (typeof payload === 'string') {
              opt.headers = {
                'Content-type': 'text/plain'
              };
            }

            var ajaxUrl = urlUtils.addPath(url, '/xhr_send');
            var xo = new AjaxObject('POST', ajaxUrl, payload, opt);
            xo.once('finish', function (status) {
              xo = null;

              if (status !== 200 && status !== 204) {
                return callback(new Error('http status ' + status));
              }

              callback();
            });
            return function () {
              xo.close();
              xo = null;
              var err = new Error('Aborted');
              err.code = 1000;
              callback(err);
            };
          };
        }

        function AjaxBasedTransport(transUrl, urlSuffix, Receiver, AjaxObject) {
          SenderReceiver.call(this, transUrl, urlSuffix, createAjaxSender(AjaxObject), Receiver, AjaxObject);
        }

        inherits(AjaxBasedTransport, SenderReceiver);
        module.exports = AjaxBasedTransport;
      }, {
        "../../utils/url": 51,
        "./sender-receiver": 27,
        "inherits": 53
      }],
      24: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter;

        function BufferedSender(url, sender) {
          EventEmitter.call(this);
          this.sendBuffer = [];
          this.sender = sender;
          this.url = url;
        }

        inherits(BufferedSender, EventEmitter);

        BufferedSender.prototype.send = function (message) {
          this.sendBuffer.push(message);

          if (!this.sendStop) {
            this.sendSchedule();
          }
        }; // For polling transports in a situation when in the message callback,
        // new message is being send. If the sending connection was started
        // before receiving one, it is possible to saturate the network and
        // timeout due to the lack of receiving socket. To avoid that we delay
        // sending messages by some small time, in order to let receiving
        // connection be started beforehand. This is only a halfmeasure and
        // does not fix the big problem, but it does make the tests go more
        // stable on slow networks.


        BufferedSender.prototype.sendScheduleWait = function () {
          var self = this;
          var tref;

          this.sendStop = function () {
            self.sendStop = null;
            clearTimeout(tref);
          };

          tref = setTimeout(function () {
            self.sendStop = null;
            self.sendSchedule();
          }, 25);
        };

        BufferedSender.prototype.sendSchedule = function () {
          var self = this;

          if (this.sendBuffer.length > 0) {
            var payload = '[' + this.sendBuffer.join(',') + ']';
            this.sendStop = this.sender(this.url, payload, function (err) {
              self.sendStop = null;

              if (err) {
                self.emit('close', err.code || 1006, 'Sending error: ' + err);
                self.close();
              } else {
                self.sendScheduleWait();
              }
            });
            this.sendBuffer = [];
          }
        };

        BufferedSender.prototype._cleanup = function () {
          this.removeAllListeners();
        };

        BufferedSender.prototype.close = function () {
          this._cleanup();

          if (this.sendStop) {
            this.sendStop();
            this.sendStop = null;
          }
        };

        module.exports = BufferedSender;
      }, {
        "events": 2,
        "inherits": 53
      }],
      25: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var inherits = _dereq_('inherits'),
              IframeTransport = _dereq_('../iframe'),
              objectUtils = _dereq_('../../utils/object');

          module.exports = function (transport) {
            function IframeWrapTransport(transUrl, baseUrl) {
              IframeTransport.call(this, transport.transportName, transUrl, baseUrl);
            }

            inherits(IframeWrapTransport, IframeTransport);

            IframeWrapTransport.enabled = function (url, info) {
              if (!global.document) {
                return false;
              }

              var iframeInfo = objectUtils.extend({}, info);
              iframeInfo.sameOrigin = true;
              return transport.enabled(iframeInfo) && IframeTransport.enabled();
            };

            IframeWrapTransport.transportName = 'iframe-' + transport.transportName;
            IframeWrapTransport.needBody = true;
            IframeWrapTransport.roundTrips = IframeTransport.roundTrips + transport.roundTrips - 1; // html, javascript (2) + transport - no CORS (1)

            IframeWrapTransport.facadeTransport = transport;
            return IframeWrapTransport;
          };
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/object": 48,
        "../iframe": 21,
        "inherits": 53
      }],
      26: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter;

        function Polling(Receiver, receiveUrl, AjaxObject) {
          EventEmitter.call(this);
          this.Receiver = Receiver;
          this.receiveUrl = receiveUrl;
          this.AjaxObject = AjaxObject;

          this._scheduleReceiver();
        }

        inherits(Polling, EventEmitter);

        Polling.prototype._scheduleReceiver = function () {
          var self = this;
          var poll = this.poll = new this.Receiver(this.receiveUrl, this.AjaxObject);
          poll.on('message', function (msg) {
            self.emit('message', msg);
          });
          poll.once('close', function (code, reason) {
            self.poll = poll = null;

            if (!self.pollIsClosing) {
              if (reason === 'network') {
                self._scheduleReceiver();
              } else {
                self.emit('close', code || 1006, reason);
                self.removeAllListeners();
              }
            }
          });
        };

        Polling.prototype.abort = function () {
          this.removeAllListeners();
          this.pollIsClosing = true;

          if (this.poll) {
            this.poll.abort();
          }
        };

        module.exports = Polling;
      }, {
        "events": 2,
        "inherits": 53
      }],
      27: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            urlUtils = _dereq_('../../utils/url'),
            BufferedSender = _dereq_('./buffered-sender'),
            Polling = _dereq_('./polling');

        function SenderReceiver(transUrl, urlSuffix, senderFunc, Receiver, AjaxObject) {
          var pollUrl = urlUtils.addPath(transUrl, urlSuffix);
          var self = this;
          BufferedSender.call(this, transUrl, senderFunc);
          this.poll = new Polling(Receiver, pollUrl, AjaxObject);
          this.poll.on('message', function (msg) {
            self.emit('message', msg);
          });
          this.poll.once('close', function (code, reason) {
            self.poll = null;
            self.emit('close', code, reason);
            self.close();
          });
        }

        inherits(SenderReceiver, BufferedSender);

        SenderReceiver.prototype.close = function () {
          BufferedSender.prototype.close.call(this);
          this.removeAllListeners();

          if (this.poll) {
            this.poll.abort();
            this.poll = null;
          }
        };

        module.exports = SenderReceiver;
      }, {
        "../../utils/url": 51,
        "./buffered-sender": 24,
        "./polling": 26,
        "inherits": 53
      }],
      28: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter,
            EventSourceDriver = _dereq_('eventsource');

        function EventSourceReceiver(url) {
          EventEmitter.call(this);
          var self = this;
          var es = this.es = new EventSourceDriver(url);

          es.onmessage = function (e) {
            self.emit('message', decodeURI(e.data));
          };

          es.onerror = function (e) {
            // ES on reconnection has readyState = 0 or 1.
            // on network error it's CLOSED = 2
            var reason = es.readyState !== 2 ? 'network' : 'permanent';

            self._cleanup();

            self._close(reason);
          };
        }

        inherits(EventSourceReceiver, EventEmitter);

        EventSourceReceiver.prototype.abort = function () {
          this._cleanup();

          this._close('user');
        };

        EventSourceReceiver.prototype._cleanup = function () {
          var es = this.es;

          if (es) {
            es.onmessage = es.onerror = null;
            es.close();
            this.es = null;
          }
        };

        EventSourceReceiver.prototype._close = function (reason) {
          var self = this; // Safari and chrome < 15 crash if we close window before
          // waiting for ES cleanup. See:
          // https://code.google.com/p/chromium/issues/detail?id=89155

          setTimeout(function () {
            self.emit('close', null, reason);
            self.removeAllListeners();
          }, 200);
        };

        module.exports = EventSourceReceiver;
      }, {
        "events": 2,
        "eventsource": 17,
        "inherits": 53
      }],
      29: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var inherits = _dereq_('inherits'),
              iframeUtils = _dereq_('../../utils/iframe'),
              urlUtils = _dereq_('../../utils/url'),
              EventEmitter = _dereq_('events').EventEmitter,
              random = _dereq_('../../utils/random');

          function HtmlfileReceiver(url) {
            EventEmitter.call(this);
            var self = this;
            iframeUtils.polluteGlobalNamespace();
            this.id = 'a' + random.string(6);
            url = urlUtils.addQuery(url, 'c=' + decodeURIComponent(iframeUtils.WPrefix + '.' + this.id));
            var constructFunc = HtmlfileReceiver.htmlfileEnabled ? iframeUtils.createHtmlfile : iframeUtils.createIframe;
            global[iframeUtils.WPrefix][this.id] = {
              start: function () {
                self.iframeObj.loaded();
              },
              message: function (data) {
                self.emit('message', data);
              },
              stop: function () {
                self._cleanup();

                self._close('network');
              }
            };
            this.iframeObj = constructFunc(url, function () {
              self._cleanup();

              self._close('permanent');
            });
          }

          inherits(HtmlfileReceiver, EventEmitter);

          HtmlfileReceiver.prototype.abort = function () {
            this._cleanup();

            this._close('user');
          };

          HtmlfileReceiver.prototype._cleanup = function () {
            if (this.iframeObj) {
              this.iframeObj.cleanup();
              this.iframeObj = null;
            }

            delete global[iframeUtils.WPrefix][this.id];
          };

          HtmlfileReceiver.prototype._close = function (reason) {
            this.emit('close', null, reason);
            this.removeAllListeners();
          };

          HtmlfileReceiver.htmlfileEnabled = false; // obfuscate to avoid firewalls

          var axo = ['Active'].concat('Object').join('X');

          if (axo in global) {
            try {
              HtmlfileReceiver.htmlfileEnabled = !!new global[axo]('htmlfile');
            } catch (x) {// intentionally empty
            }
          }

          HtmlfileReceiver.enabled = HtmlfileReceiver.htmlfileEnabled || iframeUtils.iframeEnabled;
          module.exports = HtmlfileReceiver;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/iframe": 46,
        "../../utils/random": 49,
        "../../utils/url": 51,
        "events": 2,
        "inherits": 53
      }],
      30: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var utils = _dereq_('../../utils/iframe'),
              random = _dereq_('../../utils/random'),
              browser = _dereq_('../../utils/browser'),
              urlUtils = _dereq_('../../utils/url'),
              inherits = _dereq_('inherits'),
              EventEmitter = _dereq_('events').EventEmitter;

          function JsonpReceiver(url) {
            var self = this;
            EventEmitter.call(this);
            utils.polluteGlobalNamespace();
            this.id = 'a' + random.string(6);
            var urlWithId = urlUtils.addQuery(url, 'c=' + encodeURIComponent(utils.WPrefix + '.' + this.id));
            global[utils.WPrefix][this.id] = this._callback.bind(this);

            this._createScript(urlWithId); // Fallback mostly for Konqueror - stupid timer, 35 seconds shall be plenty.


            this.timeoutId = setTimeout(function () {
              self._abort(new Error('JSONP script loaded abnormally (timeout)'));
            }, JsonpReceiver.timeout);
          }

          inherits(JsonpReceiver, EventEmitter);

          JsonpReceiver.prototype.abort = function () {
            if (global[utils.WPrefix][this.id]) {
              var err = new Error('JSONP user aborted read');
              err.code = 1000;

              this._abort(err);
            }
          };

          JsonpReceiver.timeout = 35000;
          JsonpReceiver.scriptErrorTimeout = 1000;

          JsonpReceiver.prototype._callback = function (data) {
            this._cleanup();

            if (this.aborting) {
              return;
            }

            if (data) {
              this.emit('message', data);
            }

            this.emit('close', null, 'network');
            this.removeAllListeners();
          };

          JsonpReceiver.prototype._abort = function (err) {
            this._cleanup();

            this.aborting = true;
            this.emit('close', err.code, err.message);
            this.removeAllListeners();
          };

          JsonpReceiver.prototype._cleanup = function () {
            clearTimeout(this.timeoutId);

            if (this.script2) {
              this.script2.parentNode.removeChild(this.script2);
              this.script2 = null;
            }

            if (this.script) {
              var script = this.script; // Unfortunately, you can't really abort script loading of
              // the script.

              script.parentNode.removeChild(script);
              script.onreadystatechange = script.onerror = script.onload = script.onclick = null;
              this.script = null;
            }

            delete global[utils.WPrefix][this.id];
          };

          JsonpReceiver.prototype._scriptError = function () {
            var self = this;

            if (this.errorTimer) {
              return;
            }

            this.errorTimer = setTimeout(function () {
              if (!self.loadedOkay) {
                self._abort(new Error('JSONP script loaded abnormally (onerror)'));
              }
            }, JsonpReceiver.scriptErrorTimeout);
          };

          JsonpReceiver.prototype._createScript = function (url) {
            var self = this;
            var script = this.script = global.document.createElement('script');
            var script2; // Opera synchronous load trick.

            script.id = 'a' + random.string(8);
            script.src = url;
            script.type = 'text/javascript';
            script.charset = 'UTF-8';
            script.onerror = this._scriptError.bind(this);

            script.onload = function () {
              self._abort(new Error('JSONP script loaded abnormally (onload)'));
            }; // IE9 fires 'error' event after onreadystatechange or before, in random order.
            // Use loadedOkay to determine if actually errored


            script.onreadystatechange = function () {
              if (/loaded|closed/.test(script.readyState)) {
                if (script && script.htmlFor && script.onclick) {
                  self.loadedOkay = true;

                  try {
                    // In IE, actually execute the script.
                    script.onclick();
                  } catch (x) {// intentionally empty
                  }
                }

                if (script) {
                  self._abort(new Error('JSONP script loaded abnormally (onreadystatechange)'));
                }
              }
            }; // IE: event/htmlFor/onclick trick.
            // One can't rely on proper order for onreadystatechange. In order to
            // make sure, set a 'htmlFor' and 'event' properties, so that
            // script code will be installed as 'onclick' handler for the
            // script object. Later, onreadystatechange, manually execute this
            // code. FF and Chrome doesn't work with 'event' and 'htmlFor'
            // set. For reference see:
            //   http://jaubourg.net/2010/07/loading-script-as-onclick-handler-of.html
            // Also, read on that about script ordering:
            //   http://wiki.whatwg.org/wiki/Dynamic_Script_Execution_Order


            if (typeof script.async === 'undefined' && global.document.attachEvent) {
              // According to mozilla docs, in recent browsers script.async defaults
              // to 'true', so we may use it to detect a good browser:
              // https://developer.mozilla.org/en/HTML/Element/script
              if (!browser.isOpera()) {
                // Naively assume we're in IE
                try {
                  script.htmlFor = script.id;
                  script.event = 'onclick';
                } catch (x) {// intentionally empty
                }

                script.async = true;
              } else {
                // Opera, second sync script hack
                script2 = this.script2 = global.document.createElement('script');
                script2.text = "try{var a = document.getElementById('" + script.id + "'); if(a)a.onerror();}catch(x){};";
                script.async = script2.async = false;
              }
            }

            if (typeof script.async !== 'undefined') {
              script.async = true;
            }

            var head = global.document.getElementsByTagName('head')[0];
            head.insertBefore(script, head.firstChild);

            if (script2) {
              head.insertBefore(script2, head.firstChild);
            }
          };

          module.exports = JsonpReceiver;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/browser": 43,
        "../../utils/iframe": 46,
        "../../utils/random": 49,
        "../../utils/url": 51,
        "events": 2,
        "inherits": 53
      }],
      31: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter;

        function XhrReceiver(url, AjaxObject) {
          EventEmitter.call(this);
          var self = this;
          this.bufferPosition = 0;
          this.xo = new AjaxObject('POST', url, null);
          this.xo.on('chunk', this._chunkHandler.bind(this));
          this.xo.once('finish', function (status, text) {
            self._chunkHandler(status, text);

            self.xo = null;
            var reason = status === 200 ? 'network' : 'permanent';
            self.emit('close', null, reason);

            self._cleanup();
          });
        }

        inherits(XhrReceiver, EventEmitter);

        XhrReceiver.prototype._chunkHandler = function (status, text) {
          if (status !== 200 || !text) {
            return;
          }

          for (var idx = -1;; this.bufferPosition += idx + 1) {
            var buf = text.slice(this.bufferPosition);
            idx = buf.indexOf('\n');

            if (idx === -1) {
              break;
            }

            var msg = buf.slice(0, idx);

            if (msg) {
              this.emit('message', msg);
            }
          }
        };

        XhrReceiver.prototype._cleanup = function () {
          this.removeAllListeners();
        };

        XhrReceiver.prototype.abort = function () {
          if (this.xo) {
            this.xo.close();
            this.emit('close', null, 'user');
            this.xo = null;
          }

          this._cleanup();
        };

        module.exports = XhrReceiver;
      }, {
        "events": 2,
        "inherits": 53
      }],
      32: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var random = _dereq_('../../utils/random'),
              urlUtils = _dereq_('../../utils/url');

          var form, area;

          function createIframe(id) {
            try {
              // ie6 dynamic iframes with target="" support (thanks Chris Lambacher)
              return global.document.createElement('<iframe name="' + id + '">');
            } catch (x) {
              var iframe = global.document.createElement('iframe');
              iframe.name = id;
              return iframe;
            }
          }

          function createForm() {
            form = global.document.createElement('form');
            form.style.display = 'none';
            form.style.position = 'absolute';
            form.method = 'POST';
            form.enctype = 'application/x-www-form-urlencoded';
            form.acceptCharset = 'UTF-8';
            area = global.document.createElement('textarea');
            area.name = 'd';
            form.appendChild(area);
            global.document.body.appendChild(form);
          }

          module.exports = function (url, payload, callback) {
            if (!form) {
              createForm();
            }

            var id = 'a' + random.string(8);
            form.target = id;
            form.action = urlUtils.addQuery(urlUtils.addPath(url, '/jsonp_send'), 'i=' + id);
            var iframe = createIframe(id);
            iframe.id = id;
            iframe.style.display = 'none';
            form.appendChild(iframe);

            try {
              area.value = payload;
            } catch (e) {// seriously broken browsers get here
            }

            form.submit();

            var completed = function (err) {
              if (!iframe.onerror) {
                return;
              }

              iframe.onreadystatechange = iframe.onerror = iframe.onload = null; // Opera mini doesn't like if we GC iframe
              // immediately, thus this timeout.

              setTimeout(function () {
                iframe.parentNode.removeChild(iframe);
                iframe = null;
              }, 500);
              area.value = ''; // It is not possible to detect if the iframe succeeded or
              // failed to submit our form.

              callback(err);
            };

            iframe.onerror = function () {
              completed();
            };

            iframe.onload = function () {
              completed();
            };

            iframe.onreadystatechange = function (e) {
              if (iframe.readyState === 'complete') {
                completed();
              }
            };

            return function () {
              completed(new Error('Aborted'));
            };
          };
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/random": 49,
        "../../utils/url": 51
      }],
      33: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var EventEmitter = _dereq_('events').EventEmitter,
              inherits = _dereq_('inherits'),
              eventUtils = _dereq_('../../utils/event'),
              browser = _dereq_('../../utils/browser'),
              urlUtils = _dereq_('../../utils/url'); // References:
          //   http://ajaxian.com/archives/100-line-ajax-wrapper
          //   http://msdn.microsoft.com/en-us/library/cc288060(v=VS.85).aspx


          function XDRObject(method, url, payload) {
            var self = this;
            EventEmitter.call(this);
            setTimeout(function () {
              self._start(method, url, payload);
            }, 0);
          }

          inherits(XDRObject, EventEmitter);

          XDRObject.prototype._start = function (method, url, payload) {
            var self = this;
            var xdr = new global.XDomainRequest(); // IE caches even POSTs

            url = urlUtils.addQuery(url, 't=' + +new Date());

            xdr.onerror = function () {
              self._error();
            };

            xdr.ontimeout = function () {
              self._error();
            };

            xdr.onprogress = function () {
              self.emit('chunk', 200, xdr.responseText);
            };

            xdr.onload = function () {
              self.emit('finish', 200, xdr.responseText);

              self._cleanup(false);
            };

            this.xdr = xdr;
            this.unloadRef = eventUtils.unloadAdd(function () {
              self._cleanup(true);
            });

            try {
              // Fails with AccessDenied if port number is bogus
              this.xdr.open(method, url);

              if (this.timeout) {
                this.xdr.timeout = this.timeout;
              }

              this.xdr.send(payload);
            } catch (x) {
              this._error();
            }
          };

          XDRObject.prototype._error = function () {
            this.emit('finish', 0, '');

            this._cleanup(false);
          };

          XDRObject.prototype._cleanup = function (abort) {
            if (!this.xdr) {
              return;
            }

            this.removeAllListeners();
            eventUtils.unloadDel(this.unloadRef);
            this.xdr.ontimeout = this.xdr.onerror = this.xdr.onprogress = this.xdr.onload = null;

            if (abort) {
              try {
                this.xdr.abort();
              } catch (x) {// intentionally empty
              }
            }

            this.unloadRef = this.xdr = null;
          };

          XDRObject.prototype.close = function () {
            this._cleanup(true);
          }; // IE 8/9 if the request target uses the same scheme - #79


          XDRObject.enabled = !!(global.XDomainRequest && browser.hasDomain());
          module.exports = XDRObject;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../../utils/browser": 43,
        "../../utils/event": 45,
        "../../utils/url": 51,
        "events": 2,
        "inherits": 53
      }],
      34: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            XhrDriver = _dereq_('../driver/xhr');

        function XHRCorsObject(method, url, payload, opts) {
          XhrDriver.call(this, method, url, payload, opts);
        }

        inherits(XHRCorsObject, XhrDriver);
        XHRCorsObject.enabled = XhrDriver.enabled && XhrDriver.supportsCORS;
        module.exports = XHRCorsObject;
      }, {
        "../driver/xhr": 16,
        "inherits": 53
      }],
      35: [function (_dereq_, module, exports) {
        'use strict';

        var EventEmitter = _dereq_('events').EventEmitter,
            inherits = _dereq_('inherits');

        function
          /* method, url, payload, opts */
        XHRFake() {
          var self = this;
          EventEmitter.call(this);
          this.to = setTimeout(function () {
            self.emit('finish', 200, '{}');
          }, XHRFake.timeout);
        }

        inherits(XHRFake, EventEmitter);

        XHRFake.prototype.close = function () {
          clearTimeout(this.to);
        };

        XHRFake.timeout = 2000;
        module.exports = XHRFake;
      }, {
        "events": 2,
        "inherits": 53
      }],
      36: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            XhrDriver = _dereq_('../driver/xhr');

        function XHRLocalObject(method, url, payload
        /*, opts */
        ) {
          XhrDriver.call(this, method, url, payload, {
            noCredentials: true
          });
        }

        inherits(XHRLocalObject, XhrDriver);
        XHRLocalObject.enabled = XhrDriver.enabled;
        module.exports = XHRLocalObject;
      }, {
        "../driver/xhr": 16,
        "inherits": 53
      }],
      37: [function (_dereq_, module, exports) {
        'use strict';

        var utils = _dereq_('../utils/event'),
            urlUtils = _dereq_('../utils/url'),
            inherits = _dereq_('inherits'),
            EventEmitter = _dereq_('events').EventEmitter,
            WebsocketDriver = _dereq_('./driver/websocket');

        function WebSocketTransport(transUrl, ignore, options) {
          if (!WebSocketTransport.enabled()) {
            throw new Error('Transport created when disabled');
          }

          EventEmitter.call(this);
          var self = this;
          var url = urlUtils.addPath(transUrl, '/websocket');

          if (url.slice(0, 5) === 'https') {
            url = 'wss' + url.slice(5);
          } else {
            url = 'ws' + url.slice(4);
          }

          this.url = url;
          this.ws = new WebsocketDriver(this.url, [], options);

          this.ws.onmessage = function (e) {
            self.emit('message', e.data);
          }; // Firefox has an interesting bug. If a websocket connection is
          // created after onunload, it stays alive even when user
          // navigates away from the page. In such situation let's lie -
          // let's not open the ws connection at all. See:
          // https://github.com/sockjs/sockjs-client/issues/28
          // https://bugzilla.mozilla.org/show_bug.cgi?id=696085


          this.unloadRef = utils.unloadAdd(function () {
            self.ws.close();
          });

          this.ws.onclose = function (e) {
            self.emit('close', e.code, e.reason);

            self._cleanup();
          };

          this.ws.onerror = function (e) {
            self.emit('close', 1006, 'WebSocket connection broken');

            self._cleanup();
          };
        }

        inherits(WebSocketTransport, EventEmitter);

        WebSocketTransport.prototype.send = function (data) {
          var msg = '[' + data + ']';
          this.ws.send(msg);
        };

        WebSocketTransport.prototype.close = function () {
          var ws = this.ws;

          this._cleanup();

          if (ws) {
            ws.close();
          }
        };

        WebSocketTransport.prototype._cleanup = function () {
          var ws = this.ws;

          if (ws) {
            ws.onmessage = ws.onclose = ws.onerror = null;
          }

          utils.unloadDel(this.unloadRef);
          this.unloadRef = this.ws = null;
          this.removeAllListeners();
        };

        WebSocketTransport.enabled = function () {
          return !!WebsocketDriver;
        };

        WebSocketTransport.transportName = 'websocket'; // In theory, ws should require 1 round trip. But in chrome, this is
        // not very stable over SSL. Most likely a ws connection requires a
        // separate SSL connection, in which case 2 round trips are an
        // absolute minumum.

        WebSocketTransport.roundTrips = 2;
        module.exports = WebSocketTransport;
      }, {
        "../utils/event": 45,
        "../utils/url": 51,
        "./driver/websocket": 18,
        "events": 2,
        "inherits": 53
      }],
      38: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            AjaxBasedTransport = _dereq_('./lib/ajax-based'),
            XdrStreamingTransport = _dereq_('./xdr-streaming'),
            XhrReceiver = _dereq_('./receiver/xhr'),
            XDRObject = _dereq_('./sender/xdr');

        function XdrPollingTransport(transUrl) {
          if (!XDRObject.enabled) {
            throw new Error('Transport created when disabled');
          }

          AjaxBasedTransport.call(this, transUrl, '/xhr', XhrReceiver, XDRObject);
        }

        inherits(XdrPollingTransport, AjaxBasedTransport);
        XdrPollingTransport.enabled = XdrStreamingTransport.enabled;
        XdrPollingTransport.transportName = 'xdr-polling';
        XdrPollingTransport.roundTrips = 2; // preflight, ajax

        module.exports = XdrPollingTransport;
      }, {
        "./lib/ajax-based": 23,
        "./receiver/xhr": 31,
        "./sender/xdr": 33,
        "./xdr-streaming": 39,
        "inherits": 53
      }],
      39: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            AjaxBasedTransport = _dereq_('./lib/ajax-based'),
            XhrReceiver = _dereq_('./receiver/xhr'),
            XDRObject = _dereq_('./sender/xdr'); // According to:
        //   http://stackoverflow.com/questions/1641507/detect-browser-support-for-cross-domain-xmlhttprequests
        //   http://hacks.mozilla.org/2009/07/cross-site-xmlhttprequest-with-cors/


        function XdrStreamingTransport(transUrl) {
          if (!XDRObject.enabled) {
            throw new Error('Transport created when disabled');
          }

          AjaxBasedTransport.call(this, transUrl, '/xhr_streaming', XhrReceiver, XDRObject);
        }

        inherits(XdrStreamingTransport, AjaxBasedTransport);

        XdrStreamingTransport.enabled = function (info) {
          if (info.cookie_needed || info.nullOrigin) {
            return false;
          }

          return XDRObject.enabled && info.sameScheme;
        };

        XdrStreamingTransport.transportName = 'xdr-streaming';
        XdrStreamingTransport.roundTrips = 2; // preflight, ajax

        module.exports = XdrStreamingTransport;
      }, {
        "./lib/ajax-based": 23,
        "./receiver/xhr": 31,
        "./sender/xdr": 33,
        "inherits": 53
      }],
      40: [function (_dereq_, module, exports) {
        'use strict';

        var inherits = _dereq_('inherits'),
            AjaxBasedTransport = _dereq_('./lib/ajax-based'),
            XhrReceiver = _dereq_('./receiver/xhr'),
            XHRCorsObject = _dereq_('./sender/xhr-cors'),
            XHRLocalObject = _dereq_('./sender/xhr-local');

        function XhrPollingTransport(transUrl) {
          if (!XHRLocalObject.enabled && !XHRCorsObject.enabled) {
            throw new Error('Transport created when disabled');
          }

          AjaxBasedTransport.call(this, transUrl, '/xhr', XhrReceiver, XHRCorsObject);
        }

        inherits(XhrPollingTransport, AjaxBasedTransport);

        XhrPollingTransport.enabled = function (info) {
          if (info.nullOrigin) {
            return false;
          }

          if (XHRLocalObject.enabled && info.sameOrigin) {
            return true;
          }

          return XHRCorsObject.enabled;
        };

        XhrPollingTransport.transportName = 'xhr-polling';
        XhrPollingTransport.roundTrips = 2; // preflight, ajax

        module.exports = XhrPollingTransport;
      }, {
        "./lib/ajax-based": 23,
        "./receiver/xhr": 31,
        "./sender/xhr-cors": 34,
        "./sender/xhr-local": 36,
        "inherits": 53
      }],
      41: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var inherits = _dereq_('inherits'),
              AjaxBasedTransport = _dereq_('./lib/ajax-based'),
              XhrReceiver = _dereq_('./receiver/xhr'),
              XHRCorsObject = _dereq_('./sender/xhr-cors'),
              XHRLocalObject = _dereq_('./sender/xhr-local'),
              browser = _dereq_('../utils/browser');

          function XhrStreamingTransport(transUrl) {
            if (!XHRLocalObject.enabled && !XHRCorsObject.enabled) {
              throw new Error('Transport created when disabled');
            }

            AjaxBasedTransport.call(this, transUrl, '/xhr_streaming', XhrReceiver, XHRCorsObject);
          }

          inherits(XhrStreamingTransport, AjaxBasedTransport);

          XhrStreamingTransport.enabled = function (info) {
            if (info.nullOrigin) {
              return false;
            } // Opera doesn't support xhr-streaming #60
            // But it might be able to #92


            if (browser.isOpera()) {
              return false;
            }

            return XHRCorsObject.enabled;
          };

          XhrStreamingTransport.transportName = 'xhr-streaming';
          XhrStreamingTransport.roundTrips = 2; // preflight, ajax
          // Safari gets confused when a streaming ajax request is started
          // before onload. This causes the load indicator to spin indefinetely.
          // Only require body when used in a browser

          XhrStreamingTransport.needBody = !!global.document;
          module.exports = XhrStreamingTransport;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "../utils/browser": 43,
        "./lib/ajax-based": 23,
        "./receiver/xhr": 31,
        "./sender/xhr-cors": 34,
        "./sender/xhr-local": 36,
        "inherits": 53
      }],
      42: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          if (global.crypto && global.crypto.getRandomValues) {
            module.exports.randomBytes = function (length) {
              var bytes = new Uint8Array(length);
              global.crypto.getRandomValues(bytes);
              return bytes;
            };
          } else {
            module.exports.randomBytes = function (length) {
              var bytes = new Array(length);

              for (var i = 0; i < length; i++) {
                bytes[i] = Math.floor(Math.random() * 256);
              }

              return bytes;
            };
          }
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      43: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          module.exports = {
            isOpera: function () {
              return global.navigator && /opera/i.test(global.navigator.userAgent);
            },
            isKonqueror: function () {
              return global.navigator && /konqueror/i.test(global.navigator.userAgent);
            } // #187 wrap document.domain in try/catch because of WP8 from file:///
            ,
            hasDomain: function () {
              // non-browser client always has a domain
              if (!global.document) {
                return true;
              }

              try {
                return !!global.document.domain;
              } catch (e) {
                return false;
              }
            }
          };
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      44: [function (_dereq_, module, exports) {
        'use strict';

        var JSON3 = _dereq_('json3'); // Some extra characters that Chrome gets wrong, and substitutes with
        // something else on the wire.
        // eslint-disable-next-line no-control-regex, no-misleading-character-class


        var extraEscapable = /[\x00-\x1f\ud800-\udfff\ufffe\uffff\u0300-\u0333\u033d-\u0346\u034a-\u034c\u0350-\u0352\u0357-\u0358\u035c-\u0362\u0374\u037e\u0387\u0591-\u05af\u05c4\u0610-\u0617\u0653-\u0654\u0657-\u065b\u065d-\u065e\u06df-\u06e2\u06eb-\u06ec\u0730\u0732-\u0733\u0735-\u0736\u073a\u073d\u073f-\u0741\u0743\u0745\u0747\u07eb-\u07f1\u0951\u0958-\u095f\u09dc-\u09dd\u09df\u0a33\u0a36\u0a59-\u0a5b\u0a5e\u0b5c-\u0b5d\u0e38-\u0e39\u0f43\u0f4d\u0f52\u0f57\u0f5c\u0f69\u0f72-\u0f76\u0f78\u0f80-\u0f83\u0f93\u0f9d\u0fa2\u0fa7\u0fac\u0fb9\u1939-\u193a\u1a17\u1b6b\u1cda-\u1cdb\u1dc0-\u1dcf\u1dfc\u1dfe\u1f71\u1f73\u1f75\u1f77\u1f79\u1f7b\u1f7d\u1fbb\u1fbe\u1fc9\u1fcb\u1fd3\u1fdb\u1fe3\u1feb\u1fee-\u1fef\u1ff9\u1ffb\u1ffd\u2000-\u2001\u20d0-\u20d1\u20d4-\u20d7\u20e7-\u20e9\u2126\u212a-\u212b\u2329-\u232a\u2adc\u302b-\u302c\uaab2-\uaab3\uf900-\ufa0d\ufa10\ufa12\ufa15-\ufa1e\ufa20\ufa22\ufa25-\ufa26\ufa2a-\ufa2d\ufa30-\ufa6d\ufa70-\ufad9\ufb1d\ufb1f\ufb2a-\ufb36\ufb38-\ufb3c\ufb3e\ufb40-\ufb41\ufb43-\ufb44\ufb46-\ufb4e\ufff0-\uffff]/g,
            extraLookup; // This may be quite slow, so let's delay until user actually uses bad
        // characters.

        var unrollLookup = function (escapable) {
          var i;
          var unrolled = {};
          var c = [];

          for (i = 0; i < 65536; i++) {
            c.push(String.fromCharCode(i));
          }

          escapable.lastIndex = 0;
          c.join('').replace(escapable, function (a) {
            unrolled[a] = '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
            return '';
          });
          escapable.lastIndex = 0;
          return unrolled;
        }; // Quote string, also taking care of unicode characters that browsers
        // often break. Especially, take care of unicode surrogates:
        // http://en.wikipedia.org/wiki/Mapping_of_Unicode_characters#Surrogates


        module.exports = {
          quote: function (string) {
            var quoted = JSON3.stringify(string); // In most cases this should be very fast and good enough.

            extraEscapable.lastIndex = 0;

            if (!extraEscapable.test(quoted)) {
              return quoted;
            }

            if (!extraLookup) {
              extraLookup = unrollLookup(extraEscapable);
            }

            return quoted.replace(extraEscapable, function (a) {
              return extraLookup[a];
            });
          }
        };
      }, {
        "json3": 54
      }],
      45: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var random = _dereq_('./random');

          var onUnload = {},
              afterUnload = false // detect google chrome packaged apps because they don't allow the 'unload' event
          ,
              isChromePackagedApp = global.chrome && global.chrome.app && global.chrome.app.runtime;
          module.exports = {
            attachEvent: function (event, listener) {
              if (typeof global.addEventListener !== 'undefined') {
                global.addEventListener(event, listener, false);
              } else if (global.document && global.attachEvent) {
                // IE quirks.
                // According to: http://stevesouders.com/misc/test-postmessage.php
                // the message gets delivered only to 'document', not 'window'.
                global.document.attachEvent('on' + event, listener); // I get 'window' for ie8.

                global.attachEvent('on' + event, listener);
              }
            },
            detachEvent: function (event, listener) {
              if (typeof global.addEventListener !== 'undefined') {
                global.removeEventListener(event, listener, false);
              } else if (global.document && global.detachEvent) {
                global.document.detachEvent('on' + event, listener);
                global.detachEvent('on' + event, listener);
              }
            },
            unloadAdd: function (listener) {
              if (isChromePackagedApp) {
                return null;
              }

              var ref = random.string(8);
              onUnload[ref] = listener;

              if (afterUnload) {
                setTimeout(this.triggerUnloadCallbacks, 0);
              }

              return ref;
            },
            unloadDel: function (ref) {
              if (ref in onUnload) {
                delete onUnload[ref];
              }
            },
            triggerUnloadCallbacks: function () {
              for (var ref in onUnload) {
                onUnload[ref]();
                delete onUnload[ref];
              }
            }
          };

          var unloadTriggered = function () {
            if (afterUnload) {
              return;
            }

            afterUnload = true;
            module.exports.triggerUnloadCallbacks();
          }; // 'unload' alone is not reliable in opera within an iframe, but we
          // can't use `beforeunload` as IE fires it on javascript: links.


          if (!isChromePackagedApp) {
            module.exports.attachEvent('unload', unloadTriggered);
          }
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./random": 49
      }],
      46: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var eventUtils = _dereq_('./event'),
              JSON3 = _dereq_('json3'),
              browser = _dereq_('./browser');

          module.exports = {
            WPrefix: '_jp',
            currentWindowId: null,
            polluteGlobalNamespace: function () {
              if (!(module.exports.WPrefix in global)) {
                global[module.exports.WPrefix] = {};
              }
            },
            postMessage: function (type, data) {
              if (global.parent !== global) {
                global.parent.postMessage(JSON3.stringify({
                  windowId: module.exports.currentWindowId,
                  type: type,
                  data: data || ''
                }), '*');
              } else {}
            },
            createIframe: function (iframeUrl, errorCallback) {
              var iframe = global.document.createElement('iframe');
              var tref, unloadRef;

              var unattach = function () {
                clearTimeout(tref); // Explorer had problems with that.

                try {
                  iframe.onload = null;
                } catch (x) {// intentionally empty
                }

                iframe.onerror = null;
              };

              var cleanup = function () {
                if (iframe) {
                  unattach(); // This timeout makes chrome fire onbeforeunload event
                  // within iframe. Without the timeout it goes straight to
                  // onunload.

                  setTimeout(function () {
                    if (iframe) {
                      iframe.parentNode.removeChild(iframe);
                    }

                    iframe = null;
                  }, 0);
                  eventUtils.unloadDel(unloadRef);
                }
              };

              var onerror = function (err) {
                if (iframe) {
                  cleanup();
                  errorCallback(err);
                }
              };

              var post = function (msg, origin) {
                setTimeout(function () {
                  try {
                    // When the iframe is not loaded, IE raises an exception
                    // on 'contentWindow'.
                    if (iframe && iframe.contentWindow) {
                      iframe.contentWindow.postMessage(msg, origin);
                    }
                  } catch (x) {// intentionally empty
                  }
                }, 0);
              };

              iframe.src = iframeUrl;
              iframe.style.display = 'none';
              iframe.style.position = 'absolute';

              iframe.onerror = function () {
                onerror('onerror');
              };

              iframe.onload = function () {
                // `onload` is triggered before scripts on the iframe are
                // executed. Give it few seconds to actually load stuff.
                clearTimeout(tref);
                tref = setTimeout(function () {
                  onerror('onload timeout');
                }, 2000);
              };

              global.document.body.appendChild(iframe);
              tref = setTimeout(function () {
                onerror('timeout');
              }, 15000);
              unloadRef = eventUtils.unloadAdd(cleanup);
              return {
                post: post,
                cleanup: cleanup,
                loaded: unattach
              };
            }
            /* eslint no-undef: "off", new-cap: "off" */
            ,
            createHtmlfile: function (iframeUrl, errorCallback) {
              var axo = ['Active'].concat('Object').join('X');
              var doc = new global[axo]('htmlfile');
              var tref, unloadRef;
              var iframe;

              var unattach = function () {
                clearTimeout(tref);
                iframe.onerror = null;
              };

              var cleanup = function () {
                if (doc) {
                  unattach();
                  eventUtils.unloadDel(unloadRef);
                  iframe.parentNode.removeChild(iframe);
                  iframe = doc = null;
                  CollectGarbage();
                }
              };

              var onerror = function (r) {
                if (doc) {
                  cleanup();
                  errorCallback(r);
                }
              };

              var post = function (msg, origin) {
                try {
                  // When the iframe is not loaded, IE raises an exception
                  // on 'contentWindow'.
                  setTimeout(function () {
                    if (iframe && iframe.contentWindow) {
                      iframe.contentWindow.postMessage(msg, origin);
                    }
                  }, 0);
                } catch (x) {// intentionally empty
                }
              };

              doc.open();
              doc.write('<html><s' + 'cript>' + 'document.domain="' + global.document.domain + '";' + '</s' + 'cript></html>');
              doc.close();
              doc.parentWindow[module.exports.WPrefix] = global[module.exports.WPrefix];
              var c = doc.createElement('div');
              doc.body.appendChild(c);
              iframe = doc.createElement('iframe');
              c.appendChild(iframe);
              iframe.src = iframeUrl;

              iframe.onerror = function () {
                onerror('onerror');
              };

              tref = setTimeout(function () {
                onerror('timeout');
              }, 15000);
              unloadRef = eventUtils.unloadAdd(cleanup);
              return {
                post: post,
                cleanup: cleanup,
                loaded: unattach
              };
            }
          };
          module.exports.iframeEnabled = false;

          if (global.document) {
            // postMessage misbehaves in konqueror 4.6.5 - the messages are delivered with
            // huge delay, or not at all.
            module.exports.iframeEnabled = (typeof global.postMessage === 'function' || typeof global.postMessage === 'object') && !browser.isKonqueror();
          }
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./browser": 43,
        "./event": 45,
        "json3": 54
      }],
      47: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var logObject = {};
          ['log', 'debug', 'warn'].forEach(function (level) {
            var levelExists;

            try {
              levelExists = global.console && global.console[level] && global.console[level].apply;
            } catch (e) {// do nothing
            }

            logObject[level] = levelExists ? function () {
              return global.console[level].apply(global.console, arguments);
            } : level === 'log' ? function () {} : logObject.log;
          });
          module.exports = logObject;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      48: [function (_dereq_, module, exports) {
        'use strict';

        module.exports = {
          isObject: function (obj) {
            var type = typeof obj;
            return type === 'function' || type === 'object' && !!obj;
          },
          extend: function (obj) {
            if (!this.isObject(obj)) {
              return obj;
            }

            var source, prop;

            for (var i = 1, length = arguments.length; i < length; i++) {
              source = arguments[i];

              for (prop in source) {
                if (Object.prototype.hasOwnProperty.call(source, prop)) {
                  obj[prop] = source[prop];
                }
              }
            }

            return obj;
          }
        };
      }, {}],
      49: [function (_dereq_, module, exports) {
        'use strict';

        var crypto = _dereq_('crypto'); // This string has length 32, a power of 2, so the modulus doesn't introduce a
        // bias.


        var _randomStringChars = 'abcdefghijklmnopqrstuvwxyz012345';
        module.exports = {
          string: function (length) {
            var max = _randomStringChars.length;
            var bytes = crypto.randomBytes(length);
            var ret = [];

            for (var i = 0; i < length; i++) {
              ret.push(_randomStringChars.substr(bytes[i] % max, 1));
            }

            return ret.join('');
          },
          number: function (max) {
            return Math.floor(Math.random() * max);
          },
          numberString: function (max) {
            var t = ('' + (max - 1)).length;
            var p = new Array(t + 1).join('0');
            return (p + this.number(max)).slice(-t);
          }
        };
      }, {
        "crypto": 42
      }],
      50: [function (_dereq_, module, exports) {
        'use strict';

        module.exports = function (availableTransports) {
          return {
            filterToEnabled: function (transportsWhitelist, info) {
              var transports = {
                main: [],
                facade: []
              };

              if (!transportsWhitelist) {
                transportsWhitelist = [];
              } else if (typeof transportsWhitelist === 'string') {
                transportsWhitelist = [transportsWhitelist];
              }

              availableTransports.forEach(function (trans) {
                if (!trans) {
                  return;
                }

                if (trans.transportName === 'websocket' && info.websocket === false) {
                  return;
                }

                if (transportsWhitelist.length && transportsWhitelist.indexOf(trans.transportName) === -1) {
                  return;
                }

                if (trans.enabled(info)) {
                  transports.main.push(trans);

                  if (trans.facadeTransport) {
                    transports.facade.push(trans.facadeTransport);
                  }
                } else {}
              });
              return transports;
            }
          };
        };
      }, {}],
      51: [function (_dereq_, module, exports) {
        'use strict';

        var URL = _dereq_('url-parse');

        module.exports = {
          getOrigin: function (url) {
            if (!url) {
              return null;
            }

            var p = new URL(url);

            if (p.protocol === 'file:') {
              return null;
            }

            var port = p.port;

            if (!port) {
              port = p.protocol === 'https:' ? '443' : '80';
            }

            return p.protocol + '//' + p.hostname + ':' + port;
          },
          isOriginEqual: function (a, b) {
            var res = this.getOrigin(a) === this.getOrigin(b);
            return res;
          },
          isSchemeEqual: function (a, b) {
            return a.split(':')[0] === b.split(':')[0];
          },
          addPath: function (url, path) {
            var qs = url.split('?');
            return qs[0] + path + (qs[1] ? '?' + qs[1] : '');
          },
          addQuery: function (url, q) {
            return url + (url.indexOf('?') === -1 ? '?' + q : '&' + q);
          },
          isLoopbackAddr: function (addr) {
            return /^127\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(addr) || /^\[::1\]$/.test(addr);
          }
        };
      }, {
        "url-parse": 57
      }],
      52: [function (_dereq_, module, exports) {
        module.exports = '1.5.0';
      }, {}],
      53: [function (_dereq_, module, exports) {
        if (typeof Object.create === 'function') {
          // implementation from standard node.js 'util' module
          module.exports = function inherits(ctor, superCtor) {
            if (superCtor) {
              ctor.super_ = superCtor;
              ctor.prototype = Object.create(superCtor.prototype, {
                constructor: {
                  value: ctor,
                  enumerable: false,
                  writable: true,
                  configurable: true
                }
              });
            }
          };
        } else {
          // old school shim for old browsers
          module.exports = function inherits(ctor, superCtor) {
            if (superCtor) {
              ctor.super_ = superCtor;

              var TempCtor = function () {};

              TempCtor.prototype = superCtor.prototype;
              ctor.prototype = new TempCtor();
              ctor.prototype.constructor = ctor;
            }
          };
        }
      }, {}],
      54: [function (_dereq_, module, exports) {
        (function (global) {
          /*! JSON v3.3.2 | https://bestiejs.github.io/json3 | Copyright 2012-2015, Kit Cambridge, Benjamin Tan | http://kit.mit-license.org */
          ;
          (function () {
            // Detect the `define` function exposed by asynchronous module loaders. The
            // strict `define` check is necessary for compatibility with `r.js`.
            var isLoader = typeof define === "function" && define.amd; // A set of types used to distinguish objects from primitives.

            var objectTypes = {
              "function": true,
              "object": true
            }; // Detect the `exports` object exposed by CommonJS implementations.

            var freeExports = objectTypes[typeof exports] && exports && !exports.nodeType && exports; // Use the `global` object exposed by Node (including Browserify via
            // `insert-module-globals`), Narwhal, and Ringo as the default context,
            // and the `window` object in browsers. Rhino exports a `global` function
            // instead.

            var root = objectTypes[typeof window] && window || this,
                freeGlobal = freeExports && objectTypes[typeof module] && module && !module.nodeType && typeof global == "object" && global;

            if (freeGlobal && (freeGlobal.global === freeGlobal || freeGlobal.window === freeGlobal || freeGlobal.self === freeGlobal)) {
              root = freeGlobal;
            } // Public: Initializes JSON 3 using the given `context` object, attaching the
            // `stringify` and `parse` functions to the specified `exports` object.


            function runInContext(context, exports) {
              context || (context = root.Object());
              exports || (exports = root.Object()); // Native constructor aliases.

              var Number = context.Number || root.Number,
                  String = context.String || root.String,
                  Object = context.Object || root.Object,
                  Date = context.Date || root.Date,
                  SyntaxError = context.SyntaxError || root.SyntaxError,
                  TypeError = context.TypeError || root.TypeError,
                  Math = context.Math || root.Math,
                  nativeJSON = context.JSON || root.JSON; // Delegate to the native `stringify` and `parse` implementations.

              if (typeof nativeJSON == "object" && nativeJSON) {
                exports.stringify = nativeJSON.stringify;
                exports.parse = nativeJSON.parse;
              } // Convenience aliases.


              var objectProto = Object.prototype,
                  getClass = objectProto.toString,
                  isProperty = objectProto.hasOwnProperty,
                  undefined; // Internal: Contains `try...catch` logic used by other functions.
              // This prevents other functions from being deoptimized.

              function attempt(func, errorFunc) {
                try {
                  func();
                } catch (exception) {
                  if (errorFunc) {
                    errorFunc();
                  }
                }
              } // Test the `Date#getUTC*` methods. Based on work by @Yaffle.


              var isExtended = new Date(-3509827334573292);
              attempt(function () {
                // The `getUTCFullYear`, `Month`, and `Date` methods return nonsensical
                // results for certain dates in Opera >= 10.53.
                isExtended = isExtended.getUTCFullYear() == -109252 && isExtended.getUTCMonth() === 0 && isExtended.getUTCDate() === 1 && isExtended.getUTCHours() == 10 && isExtended.getUTCMinutes() == 37 && isExtended.getUTCSeconds() == 6 && isExtended.getUTCMilliseconds() == 708;
              }); // Internal: Determines whether the native `JSON.stringify` and `parse`
              // implementations are spec-compliant. Based on work by Ken Snyder.

              function has(name) {
                if (has[name] != null) {
                  // Return cached feature test result.
                  return has[name];
                }

                var isSupported;

                if (name == "bug-string-char-index") {
                  // IE <= 7 doesn't support accessing string characters using square
                  // bracket notation. IE 8 only supports this for primitives.
                  isSupported = "a"[0] != "a";
                } else if (name == "json") {
                  // Indicates whether both `JSON.stringify` and `JSON.parse` are
                  // supported.
                  isSupported = has("json-stringify") && has("date-serialization") && has("json-parse");
                } else if (name == "date-serialization") {
                  // Indicates whether `Date`s can be serialized accurately by `JSON.stringify`.
                  isSupported = has("json-stringify") && isExtended;

                  if (isSupported) {
                    var stringify = exports.stringify;
                    attempt(function () {
                      isSupported = // JSON 2, Prototype <= 1.7, and older WebKit builds incorrectly
                      // serialize extended years.
                      stringify(new Date(-8.64e15)) == '"-271821-04-20T00:00:00.000Z"' && // The milliseconds are optional in ES 5, but required in 5.1.
                      stringify(new Date(8.64e15)) == '"+275760-09-13T00:00:00.000Z"' && // Firefox <= 11.0 incorrectly serializes years prior to 0 as negative
                      // four-digit years instead of six-digit years. Credits: @Yaffle.
                      stringify(new Date(-621987552e5)) == '"-000001-01-01T00:00:00.000Z"' && // Safari <= 5.1.5 and Opera >= 10.53 incorrectly serialize millisecond
                      // values less than 1000. Credits: @Yaffle.
                      stringify(new Date(-1)) == '"1969-12-31T23:59:59.999Z"';
                    });
                  }
                } else {
                  var value,
                      serialized = '{"a":[1,true,false,null,"\\u0000\\b\\n\\f\\r\\t"]}'; // Test `JSON.stringify`.

                  if (name == "json-stringify") {
                    var stringify = exports.stringify,
                        stringifySupported = typeof stringify == "function";

                    if (stringifySupported) {
                      // A test function object with a custom `toJSON` method.
                      (value = function () {
                        return 1;
                      }).toJSON = value;
                      attempt(function () {
                        stringifySupported = // Firefox 3.1b1 and b2 serialize string, number, and boolean
                        // primitives as object literals.
                        stringify(0) === "0" && // FF 3.1b1, b2, and JSON 2 serialize wrapped primitives as object
                        // literals.
                        stringify(new Number()) === "0" && stringify(new String()) == '""' && // FF 3.1b1, 2 throw an error if the value is `null`, `undefined`, or
                        // does not define a canonical JSON representation (this applies to
                        // objects with `toJSON` properties as well, *unless* they are nested
                        // within an object or array).
                        stringify(getClass) === undefined && // IE 8 serializes `undefined` as `"undefined"`. Safari <= 5.1.7 and
                        // FF 3.1b3 pass this test.
                        stringify(undefined) === undefined && // Safari <= 5.1.7 and FF 3.1b3 throw `Error`s and `TypeError`s,
                        // respectively, if the value is omitted entirely.
                        stringify() === undefined && // FF 3.1b1, 2 throw an error if the given value is not a number,
                        // string, array, object, Boolean, or `null` literal. This applies to
                        // objects with custom `toJSON` methods as well, unless they are nested
                        // inside object or array literals. YUI 3.0.0b1 ignores custom `toJSON`
                        // methods entirely.
                        stringify(value) === "1" && stringify([value]) == "[1]" && // Prototype <= 1.6.1 serializes `[undefined]` as `"[]"` instead of
                        // `"[null]"`.
                        stringify([undefined]) == "[null]" && // YUI 3.0.0b1 fails to serialize `null` literals.
                        stringify(null) == "null" && // FF 3.1b1, 2 halts serialization if an array contains a function:
                        // `[1, true, getClass, 1]` serializes as "[1,true,],". FF 3.1b3
                        // elides non-JSON values from objects and arrays, unless they
                        // define custom `toJSON` methods.
                        stringify([undefined, getClass, null]) == "[null,null,null]" && // Simple serialization test. FF 3.1b1 uses Unicode escape sequences
                        // where character escape codes are expected (e.g., `\b` => `\u0008`).
                        stringify({
                          "a": [value, true, false, null, "\x00\b\n\f\r\t"]
                        }) == serialized && // FF 3.1b1 and b2 ignore the `filter` and `width` arguments.
                        stringify(null, value) === "1" && stringify([1, 2], null, 1) == "[\n 1,\n 2\n]";
                      }, function () {
                        stringifySupported = false;
                      });
                    }

                    isSupported = stringifySupported;
                  } // Test `JSON.parse`.


                  if (name == "json-parse") {
                    var parse = exports.parse,
                        parseSupported;

                    if (typeof parse == "function") {
                      attempt(function () {
                        // FF 3.1b1, b2 will throw an exception if a bare literal is provided.
                        // Conforming implementations should also coerce the initial argument to
                        // a string prior to parsing.
                        if (parse("0") === 0 && !parse(false)) {
                          // Simple parsing test.
                          value = parse(serialized);
                          parseSupported = value["a"].length == 5 && value["a"][0] === 1;

                          if (parseSupported) {
                            attempt(function () {
                              // Safari <= 5.1.2 and FF 3.1b1 allow unescaped tabs in strings.
                              parseSupported = !parse('"\t"');
                            });

                            if (parseSupported) {
                              attempt(function () {
                                // FF 4.0 and 4.0.1 allow leading `+` signs and leading
                                // decimal points. FF 4.0, 4.0.1, and IE 9-10 also allow
                                // certain octal literals.
                                parseSupported = parse("01") !== 1;
                              });
                            }

                            if (parseSupported) {
                              attempt(function () {
                                // FF 4.0, 4.0.1, and Rhino 1.7R3-R4 allow trailing decimal
                                // points. These environments, along with FF 3.1b1 and 2,
                                // also allow trailing commas in JSON objects and arrays.
                                parseSupported = parse("1.") !== 1;
                              });
                            }
                          }
                        }
                      }, function () {
                        parseSupported = false;
                      });
                    }

                    isSupported = parseSupported;
                  }
                }

                return has[name] = !!isSupported;
              }

              has["bug-string-char-index"] = has["date-serialization"] = has["json"] = has["json-stringify"] = has["json-parse"] = null;

              if (!has("json")) {
                // Common `[[Class]]` name aliases.
                var functionClass = "[object Function]",
                    dateClass = "[object Date]",
                    numberClass = "[object Number]",
                    stringClass = "[object String]",
                    arrayClass = "[object Array]",
                    booleanClass = "[object Boolean]"; // Detect incomplete support for accessing string characters by index.

                var charIndexBuggy = has("bug-string-char-index"); // Internal: Normalizes the `for...in` iteration algorithm across
                // environments. Each enumerated key is yielded to a `callback` function.

                var forOwn = function (object, callback) {
                  var size = 0,
                      Properties,
                      dontEnums,
                      property; // Tests for bugs in the current environment's `for...in` algorithm. The
                  // `valueOf` property inherits the non-enumerable flag from
                  // `Object.prototype` in older versions of IE, Netscape, and Mozilla.

                  (Properties = function () {
                    this.valueOf = 0;
                  }).prototype.valueOf = 0; // Iterate over a new instance of the `Properties` class.

                  dontEnums = new Properties();

                  for (property in dontEnums) {
                    // Ignore all properties inherited from `Object.prototype`.
                    if (isProperty.call(dontEnums, property)) {
                      size++;
                    }
                  }

                  Properties = dontEnums = null; // Normalize the iteration algorithm.

                  if (!size) {
                    // A list of non-enumerable properties inherited from `Object.prototype`.
                    dontEnums = ["valueOf", "toString", "toLocaleString", "propertyIsEnumerable", "isPrototypeOf", "hasOwnProperty", "constructor"]; // IE <= 8, Mozilla 1.0, and Netscape 6.2 ignore shadowed non-enumerable
                    // properties.

                    forOwn = function (object, callback) {
                      var isFunction = getClass.call(object) == functionClass,
                          property,
                          length;
                      var hasProperty = !isFunction && typeof object.constructor != "function" && objectTypes[typeof object.hasOwnProperty] && object.hasOwnProperty || isProperty;

                      for (property in object) {
                        // Gecko <= 1.0 enumerates the `prototype` property of functions under
                        // certain conditions; IE does not.
                        if (!(isFunction && property == "prototype") && hasProperty.call(object, property)) {
                          callback(property);
                        }
                      } // Manually invoke the callback for each non-enumerable property.


                      for (length = dontEnums.length; property = dontEnums[--length];) {
                        if (hasProperty.call(object, property)) {
                          callback(property);
                        }
                      }
                    };
                  } else {
                    // No bugs detected; use the standard `for...in` algorithm.
                    forOwn = function (object, callback) {
                      var isFunction = getClass.call(object) == functionClass,
                          property,
                          isConstructor;

                      for (property in object) {
                        if (!(isFunction && property == "prototype") && isProperty.call(object, property) && !(isConstructor = property === "constructor")) {
                          callback(property);
                        }
                      } // Manually invoke the callback for the `constructor` property due to
                      // cross-environment inconsistencies.


                      if (isConstructor || isProperty.call(object, property = "constructor")) {
                        callback(property);
                      }
                    };
                  }

                  return forOwn(object, callback);
                }; // Public: Serializes a JavaScript `value` as a JSON string. The optional
                // `filter` argument may specify either a function that alters how object and
                // array members are serialized, or an array of strings and numbers that
                // indicates which properties should be serialized. The optional `width`
                // argument may be either a string or number that specifies the indentation
                // level of the output.


                if (!has("json-stringify") && !has("date-serialization")) {
                  // Internal: A map of control characters and their escaped equivalents.
                  var Escapes = {
                    92: "\\\\",
                    34: '\\"',
                    8: "\\b",
                    12: "\\f",
                    10: "\\n",
                    13: "\\r",
                    9: "\\t"
                  }; // Internal: Converts `value` into a zero-padded string such that its
                  // length is at least equal to `width`. The `width` must be <= 6.

                  var leadingZeroes = "000000";

                  var toPaddedString = function (width, value) {
                    // The `|| 0` expression is necessary to work around a bug in
                    // Opera <= 7.54u2 where `0 == -0`, but `String(-0) !== "0"`.
                    return (leadingZeroes + (value || 0)).slice(-width);
                  }; // Internal: Serializes a date object.


                  var serializeDate = function (value) {
                    var getData, year, month, date, time, hours, minutes, seconds, milliseconds; // Define additional utility methods if the `Date` methods are buggy.

                    if (!isExtended) {
                      var floor = Math.floor; // A mapping between the months of the year and the number of days between
                      // January 1st and the first of the respective month.

                      var Months = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334]; // Internal: Calculates the number of days between the Unix epoch and the
                      // first day of the given month.

                      var getDay = function (year, month) {
                        return Months[month] + 365 * (year - 1970) + floor((year - 1969 + (month = +(month > 1))) / 4) - floor((year - 1901 + month) / 100) + floor((year - 1601 + month) / 400);
                      };

                      getData = function (value) {
                        // Manually compute the year, month, date, hours, minutes,
                        // seconds, and milliseconds if the `getUTC*` methods are
                        // buggy. Adapted from @Yaffle's `date-shim` project.
                        date = floor(value / 864e5);

                        for (year = floor(date / 365.2425) + 1970 - 1; getDay(year + 1, 0) <= date; year++);

                        for (month = floor((date - getDay(year, 0)) / 30.42); getDay(year, month + 1) <= date; month++);

                        date = 1 + date - getDay(year, month); // The `time` value specifies the time within the day (see ES
                        // 5.1 section 15.9.1.2). The formula `(A % B + B) % B` is used
                        // to compute `A modulo B`, as the `%` operator does not
                        // correspond to the `modulo` operation for negative numbers.

                        time = (value % 864e5 + 864e5) % 864e5; // The hours, minutes, seconds, and milliseconds are obtained by
                        // decomposing the time within the day. See section 15.9.1.10.

                        hours = floor(time / 36e5) % 24;
                        minutes = floor(time / 6e4) % 60;
                        seconds = floor(time / 1e3) % 60;
                        milliseconds = time % 1e3;
                      };
                    } else {
                      getData = function (value) {
                        year = value.getUTCFullYear();
                        month = value.getUTCMonth();
                        date = value.getUTCDate();
                        hours = value.getUTCHours();
                        minutes = value.getUTCMinutes();
                        seconds = value.getUTCSeconds();
                        milliseconds = value.getUTCMilliseconds();
                      };
                    }

                    serializeDate = function (value) {
                      if (value > -1 / 0 && value < 1 / 0) {
                        // Dates are serialized according to the `Date#toJSON` method
                        // specified in ES 5.1 section 15.9.5.44. See section 15.9.1.15
                        // for the ISO 8601 date time string format.
                        getData(value); // Serialize extended years correctly.

                        value = (year <= 0 || year >= 1e4 ? (year < 0 ? "-" : "+") + toPaddedString(6, year < 0 ? -year : year) : toPaddedString(4, year)) + "-" + toPaddedString(2, month + 1) + "-" + toPaddedString(2, date) + // Months, dates, hours, minutes, and seconds should have two
                        // digits; milliseconds should have three.
                        "T" + toPaddedString(2, hours) + ":" + toPaddedString(2, minutes) + ":" + toPaddedString(2, seconds) + // Milliseconds are optional in ES 5.0, but required in 5.1.
                        "." + toPaddedString(3, milliseconds) + "Z";
                        year = month = date = hours = minutes = seconds = milliseconds = null;
                      } else {
                        value = null;
                      }

                      return value;
                    };

                    return serializeDate(value);
                  }; // For environments with `JSON.stringify` but buggy date serialization,
                  // we override the native `Date#toJSON` implementation with a
                  // spec-compliant one.


                  if (has("json-stringify") && !has("date-serialization")) {
                    // Internal: the `Date#toJSON` implementation used to override the native one.
                    function dateToJSON(key) {
                      return serializeDate(this);
                    } // Public: `JSON.stringify`. See ES 5.1 section 15.12.3.


                    var nativeStringify = exports.stringify;

                    exports.stringify = function (source, filter, width) {
                      var nativeToJSON = Date.prototype.toJSON;
                      Date.prototype.toJSON = dateToJSON;
                      var result = nativeStringify(source, filter, width);
                      Date.prototype.toJSON = nativeToJSON;
                      return result;
                    };
                  } else {
                    // Internal: Double-quotes a string `value`, replacing all ASCII control
                    // characters (characters with code unit values between 0 and 31) with
                    // their escaped equivalents. This is an implementation of the
                    // `Quote(value)` operation defined in ES 5.1 section 15.12.3.
                    var unicodePrefix = "\\u00";

                    var escapeChar = function (character) {
                      var charCode = character.charCodeAt(0),
                          escaped = Escapes[charCode];

                      if (escaped) {
                        return escaped;
                      }

                      return unicodePrefix + toPaddedString(2, charCode.toString(16));
                    };

                    var reEscape = /[\x00-\x1f\x22\x5c]/g;

                    var quote = function (value) {
                      reEscape.lastIndex = 0;
                      return '"' + (reEscape.test(value) ? value.replace(reEscape, escapeChar) : value) + '"';
                    }; // Internal: Recursively serializes an object. Implements the
                    // `Str(key, holder)`, `JO(value)`, and `JA(value)` operations.


                    var serialize = function (property, object, callback, properties, whitespace, indentation, stack) {
                      var value, type, className, results, element, index, length, prefix, result;
                      attempt(function () {
                        // Necessary for host object support.
                        value = object[property];
                      });

                      if (typeof value == "object" && value) {
                        if (value.getUTCFullYear && getClass.call(value) == dateClass && value.toJSON === Date.prototype.toJSON) {
                          value = serializeDate(value);
                        } else if (typeof value.toJSON == "function") {
                          value = value.toJSON(property);
                        }
                      }

                      if (callback) {
                        // If a replacement function was provided, call it to obtain the value
                        // for serialization.
                        value = callback.call(object, property, value);
                      } // Exit early if value is `undefined` or `null`.


                      if (value == undefined) {
                        return value === undefined ? value : "null";
                      }

                      type = typeof value; // Only call `getClass` if the value is an object.

                      if (type == "object") {
                        className = getClass.call(value);
                      }

                      switch (className || type) {
                        case "boolean":
                        case booleanClass:
                          // Booleans are represented literally.
                          return "" + value;

                        case "number":
                        case numberClass:
                          // JSON numbers must be finite. `Infinity` and `NaN` are serialized as
                          // `"null"`.
                          return value > -1 / 0 && value < 1 / 0 ? "" + value : "null";

                        case "string":
                        case stringClass:
                          // Strings are double-quoted and escaped.
                          return quote("" + value);
                      } // Recursively serialize objects and arrays.


                      if (typeof value == "object") {
                        // Check for cyclic structures. This is a linear search; performance
                        // is inversely proportional to the number of unique nested objects.
                        for (length = stack.length; length--;) {
                          if (stack[length] === value) {
                            // Cyclic structures cannot be serialized by `JSON.stringify`.
                            throw TypeError();
                          }
                        } // Add the object to the stack of traversed objects.


                        stack.push(value);
                        results = []; // Save the current indentation level and indent one additional level.

                        prefix = indentation;
                        indentation += whitespace;

                        if (className == arrayClass) {
                          // Recursively serialize array elements.
                          for (index = 0, length = value.length; index < length; index++) {
                            element = serialize(index, value, callback, properties, whitespace, indentation, stack);
                            results.push(element === undefined ? "null" : element);
                          }

                          result = results.length ? whitespace ? "[\n" + indentation + results.join(",\n" + indentation) + "\n" + prefix + "]" : "[" + results.join(",") + "]" : "[]";
                        } else {
                          // Recursively serialize object members. Members are selected from
                          // either a user-specified list of property names, or the object
                          // itself.
                          forOwn(properties || value, function (property) {
                            var element = serialize(property, value, callback, properties, whitespace, indentation, stack);

                            if (element !== undefined) {
                              // According to ES 5.1 section 15.12.3: "If `gap` {whitespace}
                              // is not the empty string, let `member` {quote(property) + ":"}
                              // be the concatenation of `member` and the `space` character."
                              // The "`space` character" refers to the literal space
                              // character, not the `space` {width} argument provided to
                              // `JSON.stringify`.
                              results.push(quote(property) + ":" + (whitespace ? " " : "") + element);
                            }
                          });
                          result = results.length ? whitespace ? "{\n" + indentation + results.join(",\n" + indentation) + "\n" + prefix + "}" : "{" + results.join(",") + "}" : "{}";
                        } // Remove the object from the traversed object stack.


                        stack.pop();
                        return result;
                      }
                    }; // Public: `JSON.stringify`. See ES 5.1 section 15.12.3.


                    exports.stringify = function (source, filter, width) {
                      var whitespace, callback, properties, className;

                      if (objectTypes[typeof filter] && filter) {
                        className = getClass.call(filter);

                        if (className == functionClass) {
                          callback = filter;
                        } else if (className == arrayClass) {
                          // Convert the property names array into a makeshift set.
                          properties = {};

                          for (var index = 0, length = filter.length, value; index < length;) {
                            value = filter[index++];
                            className = getClass.call(value);

                            if (className == "[object String]" || className == "[object Number]") {
                              properties[value] = 1;
                            }
                          }
                        }
                      }

                      if (width) {
                        className = getClass.call(width);

                        if (className == numberClass) {
                          // Convert the `width` to an integer and create a string containing
                          // `width` number of space characters.
                          if ((width -= width % 1) > 0) {
                            if (width > 10) {
                              width = 10;
                            }

                            for (whitespace = ""; whitespace.length < width;) {
                              whitespace += " ";
                            }
                          }
                        } else if (className == stringClass) {
                          whitespace = width.length <= 10 ? width : width.slice(0, 10);
                        }
                      } // Opera <= 7.54u2 discards the values associated with empty string keys
                      // (`""`) only if they are used directly within an object member list
                      // (e.g., `!("" in { "": 1})`).


                      return serialize("", (value = {}, value[""] = source, value), callback, properties, whitespace, "", []);
                    };
                  }
                } // Public: Parses a JSON source string.


                if (!has("json-parse")) {
                  var fromCharCode = String.fromCharCode; // Internal: A map of escaped control characters and their unescaped
                  // equivalents.

                  var Unescapes = {
                    92: "\\",
                    34: '"',
                    47: "/",
                    98: "\b",
                    116: "\t",
                    110: "\n",
                    102: "\f",
                    114: "\r"
                  }; // Internal: Stores the parser state.

                  var Index, Source; // Internal: Resets the parser state and throws a `SyntaxError`.

                  var abort = function () {
                    Index = Source = null;
                    throw SyntaxError();
                  }; // Internal: Returns the next token, or `"$"` if the parser has reached
                  // the end of the source string. A token may be a string, number, `null`
                  // literal, or Boolean literal.


                  var lex = function () {
                    var source = Source,
                        length = source.length,
                        value,
                        begin,
                        position,
                        isSigned,
                        charCode;

                    while (Index < length) {
                      charCode = source.charCodeAt(Index);

                      switch (charCode) {
                        case 9:
                        case 10:
                        case 13:
                        case 32:
                          // Skip whitespace tokens, including tabs, carriage returns, line
                          // feeds, and space characters.
                          Index++;
                          break;

                        case 123:
                        case 125:
                        case 91:
                        case 93:
                        case 58:
                        case 44:
                          // Parse a punctuator token (`{`, `}`, `[`, `]`, `:`, or `,`) at
                          // the current position.
                          value = charIndexBuggy ? source.charAt(Index) : source[Index];
                          Index++;
                          return value;

                        case 34:
                          // `"` delimits a JSON string; advance to the next character and
                          // begin parsing the string. String tokens are prefixed with the
                          // sentinel `@` character to distinguish them from punctuators and
                          // end-of-string tokens.
                          for (value = "@", Index++; Index < length;) {
                            charCode = source.charCodeAt(Index);

                            if (charCode < 32) {
                              // Unescaped ASCII control characters (those with a code unit
                              // less than the space character) are not permitted.
                              abort();
                            } else if (charCode == 92) {
                              // A reverse solidus (`\`) marks the beginning of an escaped
                              // control character (including `"`, `\`, and `/`) or Unicode
                              // escape sequence.
                              charCode = source.charCodeAt(++Index);

                              switch (charCode) {
                                case 92:
                                case 34:
                                case 47:
                                case 98:
                                case 116:
                                case 110:
                                case 102:
                                case 114:
                                  // Revive escaped control characters.
                                  value += Unescapes[charCode];
                                  Index++;
                                  break;

                                case 117:
                                  // `\u` marks the beginning of a Unicode escape sequence.
                                  // Advance to the first character and validate the
                                  // four-digit code point.
                                  begin = ++Index;

                                  for (position = Index + 4; Index < position; Index++) {
                                    charCode = source.charCodeAt(Index); // A valid sequence comprises four hexdigits (case-
                                    // insensitive) that form a single hexadecimal value.

                                    if (!(charCode >= 48 && charCode <= 57 || charCode >= 97 && charCode <= 102 || charCode >= 65 && charCode <= 70)) {
                                      // Invalid Unicode escape sequence.
                                      abort();
                                    }
                                  } // Revive the escaped character.


                                  value += fromCharCode("0x" + source.slice(begin, Index));
                                  break;

                                default:
                                  // Invalid escape sequence.
                                  abort();
                              }
                            } else {
                              if (charCode == 34) {
                                // An unescaped double-quote character marks the end of the
                                // string.
                                break;
                              }

                              charCode = source.charCodeAt(Index);
                              begin = Index; // Optimize for the common case where a string is valid.

                              while (charCode >= 32 && charCode != 92 && charCode != 34) {
                                charCode = source.charCodeAt(++Index);
                              } // Append the string as-is.


                              value += source.slice(begin, Index);
                            }
                          }

                          if (source.charCodeAt(Index) == 34) {
                            // Advance to the next character and return the revived string.
                            Index++;
                            return value;
                          } // Unterminated string.


                          abort();

                        default:
                          // Parse numbers and literals.
                          begin = Index; // Advance past the negative sign, if one is specified.

                          if (charCode == 45) {
                            isSigned = true;
                            charCode = source.charCodeAt(++Index);
                          } // Parse an integer or floating-point value.


                          if (charCode >= 48 && charCode <= 57) {
                            // Leading zeroes are interpreted as octal literals.
                            if (charCode == 48 && (charCode = source.charCodeAt(Index + 1), charCode >= 48 && charCode <= 57)) {
                              // Illegal octal literal.
                              abort();
                            }

                            isSigned = false; // Parse the integer component.

                            for (; Index < length && (charCode = source.charCodeAt(Index), charCode >= 48 && charCode <= 57); Index++); // Floats cannot contain a leading decimal point; however, this
                            // case is already accounted for by the parser.


                            if (source.charCodeAt(Index) == 46) {
                              position = ++Index; // Parse the decimal component.

                              for (; position < length; position++) {
                                charCode = source.charCodeAt(position);

                                if (charCode < 48 || charCode > 57) {
                                  break;
                                }
                              }

                              if (position == Index) {
                                // Illegal trailing decimal.
                                abort();
                              }

                              Index = position;
                            } // Parse exponents. The `e` denoting the exponent is
                            // case-insensitive.


                            charCode = source.charCodeAt(Index);

                            if (charCode == 101 || charCode == 69) {
                              charCode = source.charCodeAt(++Index); // Skip past the sign following the exponent, if one is
                              // specified.

                              if (charCode == 43 || charCode == 45) {
                                Index++;
                              } // Parse the exponential component.


                              for (position = Index; position < length; position++) {
                                charCode = source.charCodeAt(position);

                                if (charCode < 48 || charCode > 57) {
                                  break;
                                }
                              }

                              if (position == Index) {
                                // Illegal empty exponent.
                                abort();
                              }

                              Index = position;
                            } // Coerce the parsed value to a JavaScript number.


                            return +source.slice(begin, Index);
                          } // A negative sign may only precede numbers.


                          if (isSigned) {
                            abort();
                          } // `true`, `false`, and `null` literals.


                          var temp = source.slice(Index, Index + 4);

                          if (temp == "true") {
                            Index += 4;
                            return true;
                          } else if (temp == "fals" && source.charCodeAt(Index + 4) == 101) {
                            Index += 5;
                            return false;
                          } else if (temp == "null") {
                            Index += 4;
                            return null;
                          } // Unrecognized token.


                          abort();
                      }
                    } // Return the sentinel `$` character if the parser has reached the end
                    // of the source string.


                    return "$";
                  }; // Internal: Parses a JSON `value` token.


                  var get = function (value) {
                    var results, hasMembers;

                    if (value == "$") {
                      // Unexpected end of input.
                      abort();
                    }

                    if (typeof value == "string") {
                      if ((charIndexBuggy ? value.charAt(0) : value[0]) == "@") {
                        // Remove the sentinel `@` character.
                        return value.slice(1);
                      } // Parse object and array literals.


                      if (value == "[") {
                        // Parses a JSON array, returning a new JavaScript array.
                        results = [];

                        for (;;) {
                          value = lex(); // A closing square bracket marks the end of the array literal.

                          if (value == "]") {
                            break;
                          } // If the array literal contains elements, the current token
                          // should be a comma separating the previous element from the
                          // next.


                          if (hasMembers) {
                            if (value == ",") {
                              value = lex();

                              if (value == "]") {
                                // Unexpected trailing `,` in array literal.
                                abort();
                              }
                            } else {
                              // A `,` must separate each array element.
                              abort();
                            }
                          } else {
                            hasMembers = true;
                          } // Elisions and leading commas are not permitted.


                          if (value == ",") {
                            abort();
                          }

                          results.push(get(value));
                        }

                        return results;
                      } else if (value == "{") {
                        // Parses a JSON object, returning a new JavaScript object.
                        results = {};

                        for (;;) {
                          value = lex(); // A closing curly brace marks the end of the object literal.

                          if (value == "}") {
                            break;
                          } // If the object literal contains members, the current token
                          // should be a comma separator.


                          if (hasMembers) {
                            if (value == ",") {
                              value = lex();

                              if (value == "}") {
                                // Unexpected trailing `,` in object literal.
                                abort();
                              }
                            } else {
                              // A `,` must separate each object member.
                              abort();
                            }
                          } else {
                            hasMembers = true;
                          } // Leading commas are not permitted, object property names must be
                          // double-quoted strings, and a `:` must separate each property
                          // name and value.


                          if (value == "," || typeof value != "string" || (charIndexBuggy ? value.charAt(0) : value[0]) != "@" || lex() != ":") {
                            abort();
                          }

                          results[value.slice(1)] = get(lex());
                        }

                        return results;
                      } // Unexpected token encountered.


                      abort();
                    }

                    return value;
                  }; // Internal: Updates a traversed object member.


                  var update = function (source, property, callback) {
                    var element = walk(source, property, callback);

                    if (element === undefined) {
                      delete source[property];
                    } else {
                      source[property] = element;
                    }
                  }; // Internal: Recursively traverses a parsed JSON object, invoking the
                  // `callback` function for each value. This is an implementation of the
                  // `Walk(holder, name)` operation defined in ES 5.1 section 15.12.2.


                  var walk = function (source, property, callback) {
                    var value = source[property],
                        length;

                    if (typeof value == "object" && value) {
                      // `forOwn` can't be used to traverse an array in Opera <= 8.54
                      // because its `Object#hasOwnProperty` implementation returns `false`
                      // for array indices (e.g., `![1, 2, 3].hasOwnProperty("0")`).
                      if (getClass.call(value) == arrayClass) {
                        for (length = value.length; length--;) {
                          update(getClass, forOwn, value, length, callback);
                        }
                      } else {
                        forOwn(value, function (property) {
                          update(value, property, callback);
                        });
                      }
                    }

                    return callback.call(source, property, value);
                  }; // Public: `JSON.parse`. See ES 5.1 section 15.12.2.


                  exports.parse = function (source, callback) {
                    var result, value;
                    Index = 0;
                    Source = "" + source;
                    result = get(lex()); // If a JSON string contains multiple tokens, it is invalid.

                    if (lex() != "$") {
                      abort();
                    } // Reset the parser state.


                    Index = Source = null;
                    return callback && getClass.call(callback) == functionClass ? walk((value = {}, value[""] = result, value), "", callback) : result;
                  };
                }
              }

              exports.runInContext = runInContext;
              return exports;
            }

            if (freeExports && !isLoader) {
              // Export for CommonJS environments.
              runInContext(root, freeExports);
            } else {
              // Export for web browsers and JavaScript engines.
              var nativeJSON = root.JSON,
                  previousJSON = root.JSON3,
                  isRestored = false;
              var JSON3 = runInContext(root, root.JSON3 = {
                // Public: Restores the original value of the global `JSON` object and
                // returns a reference to the `JSON3` object.
                "noConflict": function () {
                  if (!isRestored) {
                    isRestored = true;
                    root.JSON = nativeJSON;
                    root.JSON3 = previousJSON;
                    nativeJSON = previousJSON = null;
                  }

                  return JSON3;
                }
              });
              root.JSON = {
                "parse": JSON3.parse,
                "stringify": JSON3.stringify
              };
            } // Export for asynchronous module loaders.


            if (isLoader) {
              define(function () {
                return JSON3;
              });
            }
          }).call(this);
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {}],
      55: [function (_dereq_, module, exports) {
        'use strict';

        var has = Object.prototype.hasOwnProperty,
            undef;
        /**
         * Decode a URI encoded string.
         *
         * @param {String} input The URI encoded string.
         * @returns {String|Null} The decoded string.
         * @api private
         */

        function decode(input) {
          try {
            return decodeURIComponent(input.replace(/\+/g, ' '));
          } catch (e) {
            return null;
          }
        }
        /**
         * Attempts to encode a given input.
         *
         * @param {String} input The string that needs to be encoded.
         * @returns {String|Null} The encoded string.
         * @api private
         */


        function encode(input) {
          try {
            return encodeURIComponent(input);
          } catch (e) {
            return null;
          }
        }
        /**
         * Simple query string parser.
         *
         * @param {String} query The query string that needs to be parsed.
         * @returns {Object}
         * @api public
         */


        function querystring(query) {
          var parser = /([^=?&]+)=?([^&]*)/g,
              result = {},
              part;

          while (part = parser.exec(query)) {
            var key = decode(part[1]),
                value = decode(part[2]); //
            // Prevent overriding of existing properties. This ensures that build-in
            // methods like `toString` or __proto__ are not overriden by malicious
            // querystrings.
            //
            // In the case if failed decoding, we want to omit the key/value pairs
            // from the result.
            //

            if (key === null || value === null || key in result) continue;
            result[key] = value;
          }

          return result;
        }
        /**
         * Transform a query string to an object.
         *
         * @param {Object} obj Object that should be transformed.
         * @param {String} prefix Optional prefix.
         * @returns {String}
         * @api public
         */


        function querystringify(obj, prefix) {
          prefix = prefix || '';
          var pairs = [],
              value,
              key; //
          // Optionally prefix with a '?' if needed
          //

          if ('string' !== typeof prefix) prefix = '?';

          for (key in obj) {
            if (has.call(obj, key)) {
              value = obj[key]; //
              // Edge cases where we actually want to encode the value to an empty
              // string instead of the stringified value.
              //

              if (!value && (value === null || value === undef || isNaN(value))) {
                value = '';
              }

              key = encodeURIComponent(key);
              value = encodeURIComponent(value); //
              // If we failed to encode the strings, we should bail out as we don't
              // want to add invalid strings to the query.
              //

              if (key === null || value === null) continue;
              pairs.push(key + '=' + value);
            }
          }

          return pairs.length ? prefix + pairs.join('&') : '';
        } //
        // Expose the module.
        //


        exports.stringify = querystringify;
        exports.parse = querystring;
      }, {}],
      56: [function (_dereq_, module, exports) {
        'use strict';
        /**
         * Check if we're required to add a port number.
         *
         * @see https://url.spec.whatwg.org/#default-port
         * @param {Number|String} port Port number we need to check
         * @param {String} protocol Protocol we need to check against.
         * @returns {Boolean} Is it a default port for the given protocol
         * @api private
         */

        module.exports = function required(port, protocol) {
          protocol = protocol.split(':')[0];
          port = +port;
          if (!port) return false;

          switch (protocol) {
            case 'http':
            case 'ws':
              return port !== 80;

            case 'https':
            case 'wss':
              return port !== 443;

            case 'ftp':
              return port !== 21;

            case 'gopher':
              return port !== 70;

            case 'file':
              return false;
          }

          return port !== 0;
        };
      }, {}],
      57: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var required = _dereq_('requires-port'),
              qs = _dereq_('querystringify'),
              slashes = /^[A-Za-z][A-Za-z0-9+-.]*:\/\//,
              protocolre = /^([a-z][a-z0-9.+-]*:)?(\/\/)?([\S\s]*)/i,
              whitespace = '[\\x09\\x0A\\x0B\\x0C\\x0D\\x20\\xA0\\u1680\\u180E\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200A\\u202F\\u205F\\u3000\\u2028\\u2029\\uFEFF]',
              left = new RegExp('^' + whitespace + '+');
          /**
           * Trim a given string.
           *
           * @param {String} str String to trim.
           * @public
           */


          function trimLeft(str) {
            return (str ? str : '').toString().replace(left, '');
          }
          /**
           * These are the parse rules for the URL parser, it informs the parser
           * about:
           *
           * 0. The char it Needs to parse, if it's a string it should be done using
           *    indexOf, RegExp using exec and NaN means set as current value.
           * 1. The property we should set when parsing this value.
           * 2. Indication if it's backwards or forward parsing, when set as number it's
           *    the value of extra chars that should be split off.
           * 3. Inherit from location if non existing in the parser.
           * 4. `toLowerCase` the resulting value.
           */


          var rules = [['#', 'hash'], // Extract from the back.
          ['?', 'query'], // Extract from the back.
          function sanitize(address) {
            // Sanitize what is left of the address
            return address.replace('\\', '/');
          }, ['/', 'pathname'], // Extract from the back.
          ['@', 'auth', 1], // Extract from the front.
          [NaN, 'host', undefined, 1, 1], // Set left over value.
          [/:(\d+)$/, 'port', undefined, 1], // RegExp the back.
          [NaN, 'hostname', undefined, 1, 1] // Set left over.
          ];
          /**
           * These properties should not be copied or inherited from. This is only needed
           * for all non blob URL's as a blob URL does not include a hash, only the
           * origin.
           *
           * @type {Object}
           * @private
           */

          var ignore = {
            hash: 1,
            query: 1
          };
          /**
           * The location object differs when your code is loaded through a normal page,
           * Worker or through a worker using a blob. And with the blobble begins the
           * trouble as the location object will contain the URL of the blob, not the
           * location of the page where our code is loaded in. The actual origin is
           * encoded in the `pathname` so we can thankfully generate a good "default"
           * location from it so we can generate proper relative URL's again.
           *
           * @param {Object|String} loc Optional default location object.
           * @returns {Object} lolcation object.
           * @public
           */

          function lolcation(loc) {
            var globalVar;
            if (typeof window !== 'undefined') globalVar = window;else if (typeof global !== 'undefined') globalVar = global;else if (typeof self !== 'undefined') globalVar = self;else globalVar = {};
            var location = globalVar.location || {};
            loc = loc || location;
            var finaldestination = {},
                type = typeof loc,
                key;

            if ('blob:' === loc.protocol) {
              finaldestination = new Url(unescape(loc.pathname), {});
            } else if ('string' === type) {
              finaldestination = new Url(loc, {});

              for (key in ignore) delete finaldestination[key];
            } else if ('object' === type) {
              for (key in loc) {
                if (key in ignore) continue;
                finaldestination[key] = loc[key];
              }

              if (finaldestination.slashes === undefined) {
                finaldestination.slashes = slashes.test(loc.href);
              }
            }

            return finaldestination;
          }
          /**
           * @typedef ProtocolExtract
           * @type Object
           * @property {String} protocol Protocol matched in the URL, in lowercase.
           * @property {Boolean} slashes `true` if protocol is followed by "//", else `false`.
           * @property {String} rest Rest of the URL that is not part of the protocol.
           */

          /**
           * Extract protocol information from a URL with/without double slash ("//").
           *
           * @param {String} address URL we want to extract from.
           * @return {ProtocolExtract} Extracted information.
           * @private
           */


          function extractProtocol(address) {
            address = trimLeft(address);
            var match = protocolre.exec(address);
            return {
              protocol: match[1] ? match[1].toLowerCase() : '',
              slashes: !!match[2],
              rest: match[3]
            };
          }
          /**
           * Resolve a relative URL pathname against a base URL pathname.
           *
           * @param {String} relative Pathname of the relative URL.
           * @param {String} base Pathname of the base URL.
           * @return {String} Resolved pathname.
           * @private
           */


          function resolve(relative, base) {
            if (relative === '') return base;
            var path = (base || '/').split('/').slice(0, -1).concat(relative.split('/')),
                i = path.length,
                last = path[i - 1],
                unshift = false,
                up = 0;

            while (i--) {
              if (path[i] === '.') {
                path.splice(i, 1);
              } else if (path[i] === '..') {
                path.splice(i, 1);
                up++;
              } else if (up) {
                if (i === 0) unshift = true;
                path.splice(i, 1);
                up--;
              }
            }

            if (unshift) path.unshift('');
            if (last === '.' || last === '..') path.push('');
            return path.join('/');
          }
          /**
           * The actual URL instance. Instead of returning an object we've opted-in to
           * create an actual constructor as it's much more memory efficient and
           * faster and it pleases my OCD.
           *
           * It is worth noting that we should not use `URL` as class name to prevent
           * clashes with the global URL instance that got introduced in browsers.
           *
           * @constructor
           * @param {String} address URL we want to parse.
           * @param {Object|String} [location] Location defaults for relative paths.
           * @param {Boolean|Function} [parser] Parser for the query string.
           * @private
           */


          function Url(address, location, parser) {
            address = trimLeft(address);

            if (!(this instanceof Url)) {
              return new Url(address, location, parser);
            }

            var relative,
                extracted,
                parse,
                instruction,
                index,
                key,
                instructions = rules.slice(),
                type = typeof location,
                url = this,
                i = 0; //
            // The following if statements allows this module two have compatibility with
            // 2 different API:
            //
            // 1. Node.js's `url.parse` api which accepts a URL, boolean as arguments
            //    where the boolean indicates that the query string should also be parsed.
            //
            // 2. The `URL` interface of the browser which accepts a URL, object as
            //    arguments. The supplied object will be used as default values / fall-back
            //    for relative paths.
            //

            if ('object' !== type && 'string' !== type) {
              parser = location;
              location = null;
            }

            if (parser && 'function' !== typeof parser) parser = qs.parse;
            location = lolcation(location); //
            // Extract protocol information before running the instructions.
            //

            extracted = extractProtocol(address || '');
            relative = !extracted.protocol && !extracted.slashes;
            url.slashes = extracted.slashes || relative && location.slashes;
            url.protocol = extracted.protocol || location.protocol || '';
            address = extracted.rest; //
            // When the authority component is absent the URL starts with a path
            // component.
            //

            if (!extracted.slashes) instructions[3] = [/(.*)/, 'pathname'];

            for (; i < instructions.length; i++) {
              instruction = instructions[i];

              if (typeof instruction === 'function') {
                address = instruction(address);
                continue;
              }

              parse = instruction[0];
              key = instruction[1];

              if (parse !== parse) {
                url[key] = address;
              } else if ('string' === typeof parse) {
                if (~(index = address.indexOf(parse))) {
                  if ('number' === typeof instruction[2]) {
                    url[key] = address.slice(0, index);
                    address = address.slice(index + instruction[2]);
                  } else {
                    url[key] = address.slice(index);
                    address = address.slice(0, index);
                  }
                }
              } else if (index = parse.exec(address)) {
                url[key] = index[1];
                address = address.slice(0, index.index);
              }

              url[key] = url[key] || (relative && instruction[3] ? location[key] || '' : ''); //
              // Hostname, host and protocol should be lowercased so they can be used to
              // create a proper `origin`.
              //

              if (instruction[4]) url[key] = url[key].toLowerCase();
            } //
            // Also parse the supplied query string in to an object. If we're supplied
            // with a custom parser as function use that instead of the default build-in
            // parser.
            //


            if (parser) url.query = parser(url.query); //
            // If the URL is relative, resolve the pathname against the base URL.
            //

            if (relative && location.slashes && url.pathname.charAt(0) !== '/' && (url.pathname !== '' || location.pathname !== '')) {
              url.pathname = resolve(url.pathname, location.pathname);
            } //
            // We should not add port numbers if they are already the default port number
            // for a given protocol. As the host also contains the port number we're going
            // override it with the hostname which contains no port number.
            //


            if (!required(url.port, url.protocol)) {
              url.host = url.hostname;
              url.port = '';
            } //
            // Parse down the `auth` for the username and password.
            //


            url.username = url.password = '';

            if (url.auth) {
              instruction = url.auth.split(':');
              url.username = instruction[0] || '';
              url.password = instruction[1] || '';
            }

            url.origin = url.protocol && url.host && url.protocol !== 'file:' ? url.protocol + '//' + url.host : 'null'; //
            // The href is just the compiled result.
            //

            url.href = url.toString();
          }
          /**
           * This is convenience method for changing properties in the URL instance to
           * insure that they all propagate correctly.
           *
           * @param {String} part          Property we need to adjust.
           * @param {Mixed} value          The newly assigned value.
           * @param {Boolean|Function} fn  When setting the query, it will be the function
           *                               used to parse the query.
           *                               When setting the protocol, double slash will be
           *                               removed from the final url if it is true.
           * @returns {URL} URL instance for chaining.
           * @public
           */


          function set(part, value, fn) {
            var url = this;

            switch (part) {
              case 'query':
                if ('string' === typeof value && value.length) {
                  value = (fn || qs.parse)(value);
                }

                url[part] = value;
                break;

              case 'port':
                url[part] = value;

                if (!required(value, url.protocol)) {
                  url.host = url.hostname;
                  url[part] = '';
                } else if (value) {
                  url.host = url.hostname + ':' + value;
                }

                break;

              case 'hostname':
                url[part] = value;
                if (url.port) value += ':' + url.port;
                url.host = value;
                break;

              case 'host':
                url[part] = value;

                if (/:\d+$/.test(value)) {
                  value = value.split(':');
                  url.port = value.pop();
                  url.hostname = value.join(':');
                } else {
                  url.hostname = value;
                  url.port = '';
                }

                break;

              case 'protocol':
                url.protocol = value.toLowerCase();
                url.slashes = !fn;
                break;

              case 'pathname':
              case 'hash':
                if (value) {
                  var char = part === 'pathname' ? '/' : '#';
                  url[part] = value.charAt(0) !== char ? char + value : value;
                } else {
                  url[part] = value;
                }

                break;

              default:
                url[part] = value;
            }

            for (var i = 0; i < rules.length; i++) {
              var ins = rules[i];
              if (ins[4]) url[ins[1]] = url[ins[1]].toLowerCase();
            }

            url.origin = url.protocol && url.host && url.protocol !== 'file:' ? url.protocol + '//' + url.host : 'null';
            url.href = url.toString();
            return url;
          }
          /**
           * Transform the properties back in to a valid and full URL string.
           *
           * @param {Function} stringify Optional query stringify function.
           * @returns {String} Compiled version of the URL.
           * @public
           */


          function toString(stringify) {
            if (!stringify || 'function' !== typeof stringify) stringify = qs.stringify;
            var query,
                url = this,
                protocol = url.protocol;
            if (protocol && protocol.charAt(protocol.length - 1) !== ':') protocol += ':';
            var result = protocol + (url.slashes ? '//' : '');

            if (url.username) {
              result += url.username;
              if (url.password) result += ':' + url.password;
              result += '@';
            }

            result += url.host + url.pathname;
            query = 'object' === typeof url.query ? stringify(url.query) : url.query;
            if (query) result += '?' !== query.charAt(0) ? '?' + query : query;
            if (url.hash) result += url.hash;
            return result;
          }

          Url.prototype = {
            set: set,
            toString: toString
          }; //
          // Expose the URL parser and some additional properties that might be useful for
          // others or testing.
          //

          Url.extractProtocol = extractProtocol;
          Url.location = lolcation;
          Url.trimLeft = trimLeft;
          Url.qs = qs;
          module.exports = Url;
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "querystringify": 55,
        "requires-port": 56
      }],
      58: [function (_dereq_, module, exports) {
        (function (global) {
          'use strict';

          var transportList = _dereq_('./transport-list');

          module.exports = _dereq_('./main')(transportList); // TODO can't get rid of this until all servers do

          if ('_sockjs_onload' in global) {
            setTimeout(global._sockjs_onload, 1);
          }
        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {});
      }, {
        "./main": 13,
        "./transport-list": 15
      }]
    }, {}, [58])(58);
  });
}, function (Primus) {
  ;

  (function (Primus, undefined) {
    function spark(Spark, Emitter) {
      'use strict';
      /**
       * `Primus#initialise` reference.
       */

      var initialise = Spark.prototype.initialise;
      /**
       * Initialise the Primus and setup all
       * parsers and internal listeners.
       *
       * @api private
       */

      Spark.prototype.initialise = function init() {
        if (!this.emitter) this.emitter = new Emitter(this);
        if (!this.__initialise) initialise.apply(this, arguments);
      }; // Extend the Spark to add the send method. If `Spark.readable`
      // is not supported then we set the method on the prototype instead.


      if (!Spark.readable) Spark.prototype.send = send;else if (!Spark.prototype.send) Spark.readable('send', send);
      /**
       * Emits to this Spark.
       *
       * @param {String} ev The event
       * @param {Mixed} [data] The data to broadcast
       * @param {Function} [fn] The callback function
       * @return {Primus|Spark} this
       * @api public
       */

      function send(ev, data, fn) {
        /* jshint validthis: true */
        // ignore newListener event to avoid this error in node 0.8
        // https://github.com/cayasso/primus-emitter/issues/3
        if (/^(newListener|removeListener)/.test(ev)) return this;
        this.emitter.send.apply(this.emitter, arguments);
        return this;
      }
    }

    function emitter() {
      'use strict';

      var toString = Object.prototype.toString,
          slice = Array.prototype.slice;
      /**
       * Check if the given `value` is an `Array`.
       *
       * @param {*} value The value to check
       * @return {Boolean}
       */

      var isArray = Array.isArray || function isArray(value) {
        return '[object Array]' === toString.call(value);
      };
      /**
       * Event packets.
       */


      var packets = {
        EVENT: 0,
        ACK: 1
      };
      /**
       * Initialize a new `Emitter`.
       *
       * @param {Primus|Spark} conn
       * @return {Emitter} `Emitter` instance
       * @api public
       */

      function Emitter(conn) {
        if (!(this instanceof Emitter)) return new Emitter(conn);
        this.ids = 1;
        this.acks = {};
        this.conn = conn;
        if (this.conn) this.bind();
      }
      /**
       * Bind `Emitter` events.
       *
       * @return {Emitter} self
       * @api private
       */


      Emitter.prototype.bind = function bind() {
        var em = this;
        this.conn.on('data', function ondata(packet) {
          em.ondata.call(em, packet);
        });
        return this;
      };
      /**
       * Called with incoming transport data.
       *
       * @param {Object} packet
       * @return {Emitter} self
       * @api private
       */


      Emitter.prototype.ondata = function ondata(packet) {
        if (!isArray(packet.data) || packet.id && 'number' !== typeof packet.id) {
          return this;
        }

        switch (packet.type) {
          case packets.EVENT:
            this.onevent(packet);
            break;

          case packets.ACK:
            this.onack(packet);
        }

        return this;
      };
      /**
       * Send a message to client.
       *
       * @return {Emitter} self
       * @api public
       */


      Emitter.prototype.send = function send() {
        var args = slice.call(arguments);
        this.conn.write(this.packet(args));
        return this;
      };
      /**
       * Prepare packet for emitting.
       *
       * @param {Array} arguments
       * @return {Object} packet
       * @api private
       */


      Emitter.prototype.packet = function pack(args) {
        var packet = {
          type: packets.EVENT,
          data: args
        }; // access last argument to see if it's an ACK callback

        if ('function' === typeof args[args.length - 1]) {
          var id = this.ids++;
          this.acks[id] = args.pop();
          packet.id = id;
        }

        return packet;
      };
      /**
       * Called upon event packet.
       *
       * @param {Object} packet object
       * @return {Emitter} self
       * @api private
       */


      Emitter.prototype.onevent = function onevent(packet) {
        var args = packet.data;
        if (this.conn.reserved(args[0])) return this;
        if (packet.id) args.push(this.ack(packet.id));
        this.conn.emit.apply(this.conn, args);
        return this;
      };
      /**
       * Produces an ack callback to emit with an event.
       *
       * @param {Number} packet id
       * @return {Function}
       * @api private
       */


      Emitter.prototype.ack = function ack(id) {
        var conn = this.conn,
            sent = false;
        return function () {
          if (sent) return; // prevent double callbacks

          sent = true;
          conn.write({
            id: id,
            type: packets.ACK,
            data: slice.call(arguments)
          });
        };
      };
      /**
       * Called upon ack packet.
       *
       * @param {Object} packet object
       * @return {Emitter} self
       * @api private
       */


      Emitter.prototype.onack = function onack(packet) {
        var ack = this.acks[packet.id];

        if ('function' === typeof ack) {
          ack.apply(this, packet.data);
          delete this.acks[packet.id];
        }

        return this;
      }; // Expose packets


      Emitter.packets = packets;
      return Emitter;
    }

    if (undefined === Primus) return;
    Primus.$ = Primus.$ || {};
    Primus.$.emitter = {};
    Primus.$.emitter.spark = spark;
    Primus.$.emitter.emitter = emitter;
    spark(Primus, emitter());
  })(Primus);
}]);