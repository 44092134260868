/** Angular Imports */
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

/** Modules Imports */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

/** Components Imports */
import { TOTPModal } from './totp.modal.component';

// *******************************************************************************
//

@NgModule({
    declarations: [TOTPModal],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
    ],
    exports: [TOTPModal],
    entryComponents: [TOTPModal],
})
export class TOTPModule {}
