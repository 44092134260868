<div class="authentication-wrapper authentication-2 px-4">
    <div class="authentication-inner py-5">
        <!-- Form -->
        <div class="card">
            <div class="p-4 p-sm-5" *ngIf="!verified">
                <p class="text-center text-big mb-4">
                    <span
                        ><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon
                    ></span>
                </p>
            </div>
            <div class="p-4 p-sm-5" *ngIf="verified">
                <div
                    class="display-1 lnr lnr-checkmark-circle text-center text-success mb-4"
                ></div>

                <p class="text-center text-big mb-4">
                    Your email address has been successfully confirmed.
                </p>

                <button
                    type="button"
                    class="btn btn-primary btn-block"
                    (click)="login()"
                >
                    Login
                </button>
            </div>
        </div>
        <!-- / Form -->
    </div>
</div>
