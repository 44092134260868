// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BaseService, BaseQuery } from './base.service';

export type { AccountStubFrontend as AccountStub } from 'server/api/account/account';

import type { UserFrontend as User } from 'server/api/user/user';
export type { Address, UserFrontend as User } from 'server/api/user/user';

export interface PassCredentials {
    password: string;
    userId: string;
    token: string;
}

export interface VerifyCredentials {
    userId: string;
    token: string;
}

@Injectable()
export class UserService extends BaseService<User> {
    modelName = 'users';
    constructor(injector: Injector) {
        super(injector);
    }

    changePassword(
        userId: string,
        oldPassword: string,
        newPassword: string
    ): Observable<void> {
        return this.http.put<void>(`/api/users/${userId}/password`, {
            oldPassword,
            newPassword,
        });
    }

    importUsers(userIds: string[], setRole: string): Observable<boolean> {
        return this.http.patch<boolean>(`/api/users/importUsers`, { userIds, setRole });
    }

    get(user: User = { _id: 'me' }): Observable<User> {
        return this.http.get<User>(`/api/users/${user._id}`);
    }

    requestVerificationEmail(userId: string): Observable<any> {
        return this.http.get<any>(`/api/users/${userId}/verify`);
    }

    requestForgotPasswordEmail(email: string): Observable<any> {
        const params = new HttpParams().set('email', email);
        return this.http.get<any>(`/api/users/forgot`, { params: params });
    }

    resetPassword(credentials: PassCredentials): Observable<any> {
        return this.http.put<any>(
            `/api/users/${credentials.userId}/forgot/${credentials.token}`,
            { password: credentials.password }
        );
    }

    verifyEmail(credentials: VerifyCredentials): Observable<any> {
        return this.http.get<any>(
            `/api/users/${credentials.userId}/verify/${credentials.token}`
        );
    }

    getImportable(query?: BaseQuery): Observable<User[]> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        return this.http.get<User[]>(`/api/${this.modelName}/importableUsers`, {
            params: params,
        });
    }

    getMFASetup(): Observable<any> {
        return this.http.get<any>(`/api/users/mfasetup`);
    }

    MFASetup(code: string): Observable<any> {
        return this.http.post<any>(`/api/users/mfasetup`, { code });
    }

    disableMFA(): Observable<any> {
        return this.http.post<any>(`/api/users/disablemfa`, {});
    }

    enableMFA(): Observable<any> {
        return this.http.post<any>(`/api/users/enablemfa`, {});
    }

    resetMFA(userId: string): Observable<any> {
        return this.http.post<any>(`/api/users/${userId}/mfaReset`, {});
    }

    addUserAccounts(userId: string, accountIds: string[]): Observable<User> {
        return this.http.patch<User>(`/api/users/${userId}/addAccounts`, {
            accountIds,
        });
    }

    saveMe(user: User): Observable<User> {
        return this.http.patch<User>(`/api/${this.modelName}/me`, user);
    }
}
