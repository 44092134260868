/** Angular Imports */
import { NgModule } from '@angular/core';

/** App Modules */
import { ConsentModule } from '../consent/consent.module';

/** Services Imports */
import { AbilityGuard } from './ability-guard.service';
import { LoginGuard } from './login-guard.service';
import { VerifyGuard } from './verify-guard.service';

// *******************************************************************************
//

@NgModule({
    providers: [LoginGuard, VerifyGuard, AbilityGuard],
    imports: [ConsentModule],
})
export class AuthModule {}
