<div class="modal-header">
    <h3 class="modal-title">
        <fa-icon [icon]="faClipboardList" [fixedWidth]="true"></fa-icon>Updated Policies
    </h3>
</div>
<div class="modal-body">
    <div style="max-height: 60vh; overflow: auto">
        <div>
            <app-policy-privacy></app-policy-privacy>
        </div>
        <hr style="border-top: 5px solid #4e5155" />
        <div>
            <app-policy-terms></app-policy-terms>
        </div>
        <hr style="border-top: 5px solid #4e5155" />
        <div>
            <app-policy-disclaimer></app-policy-disclaimer>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="ok()" class="btn btn-primary">Accept</button>
</div>
