import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountService } from './account.service';
import { AddonService } from './addon.service';
import { ApiKeyService } from './api-key.service';
import { BaseService } from './base.service';
import { CostCentreService } from './cost-centre.service';
import { CreditNoteService } from './credit-note.service';
import { DiscountService } from './discount.service';
import { EventService } from './event.service';
import { GatewayService } from './gateway.service';
import { InvoiceService } from './invoice.service';
import { PlanService } from './plan.service';
import { ProductService } from './product.service';
import { StatementService } from './statement.service';
import { SubscriptionService } from './subscription.service';
import { TaskService } from './task.service';
import { ThingService } from './thing.service';
import { UserService } from './user.service';
import { WebhookService } from './webhook.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        AccountService,
        AddonService,
        ApiKeyService,
        BaseService,
        CostCentreService,
        CreditNoteService,
        DiscountService,
        EventService,
        GatewayService,
        InvoiceService,
        PlanService,
        ProductService,
        StatementService,
        SubscriptionService,
        TaskService,
        ThingService,
        UserService,
        WebhookService,
    ],
})
export class ServicesModule {}
