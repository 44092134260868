import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LogoService {
    domain: string;
    domainLogo: string;
    domainIcon: string;
    domainSidebar: string;
    lightLogo = false;
    constructor() {
        this.domain = /:\/\/([^\/]+)/
            .exec(window.location.href)[1]
            .split('.')[0];

        if (this.domain.includes('localhost')) {
            this.domain = 'bling';
        }

        this.domainLogo = `assets/img/${this.domain}_logo.svg`;
        this.domainIcon = `assets/img/${this.domain}_icon.svg`;
        this.domainSidebar = `assets/img/${this.domain}_sidebar.svg`;
    }
}
