// @flow
import { Injectable, Injector } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseService } from './base.service';

import type { InvoiceFrontend as Invoice } from '../../server/api/invoice/invoice';
import { HttpHeaders, HttpParams } from '@angular/common/http';
export type { InvoiceFrontend as Invoice } from '../../server/api/invoice/invoice';

export interface Revenue {
    _id?: {
        year?: number;
        month?: number;
    };
    count?: number;
    total?: number;
    date?: Date;
    label?: string;
    value?: number;
}

export interface InvoiceSummary {
    total: number;
    count: number;
}

@Injectable()
export class InvoiceService extends BaseService<Invoice> {
    modelName = 'invoices';

    constructor(injector: Injector) {
        super(injector);
    }

    approve(id: string): Observable<Invoice> {
        return this.http.post<Invoice>(
            `/api/${this.modelName}/${id}/approve`,
            {}
        );
    }

    send(
        id: string,
        sendUser: boolean = true,
        sendMe: boolean = false,
        additionalEmails?: string[]
    ): Observable<Invoice> {
        return this.http.post<Invoice>(`/api/${this.modelName}/${id}/send`, {
            sendUser,
            sendMe,
            additionalEmails,
        });
    }

    pay(id: string, reference?): Observable<Invoice> {
        return this.http.post<Invoice>(`/api/${this.modelName}/${id}/pay`, {
            reference,
        });
    }

    cancel(id: string): Observable<Invoice> {
        return this.http.post<Invoice>(
            `/api/${this.modelName}/${id}/cancel`,
            {}
        );
    }

    getDraft(type: 'admin'): Observable<InvoiceSummary> {
        return this.http.get<InvoiceSummary>(
            `/api/${this.modelName}/${type}/draft`
        );
    }

    getOutstanding(type: 'client' | 'admin'): Observable<InvoiceSummary> {
        return this.http.get<InvoiceSummary>(
            `/api/${this.modelName}/${type}/outstanding`
        );
    }

    getOverdue(type: 'admin'): Observable<InvoiceSummary> {
        return this.http.get<InvoiceSummary>(
            `/api/${this.modelName}/${type}/overdue`
        );
    }

    getFirstDue(): Observable<Invoice> {
        return this.http.get<Invoice>(`/api/${this.modelName}/first`);
    }

    getRevenueHistory(): Observable<Revenue[]> {
        return this.http.get<Revenue[]>(`/api/${this.modelName}/admin/revenue`);
    }

    getTopClients() {
        return this.http.get(`/api/${this.modelName}/admin/topClients`);
    }

    /**
     * Exports invoices to be imported into Xero.
     *
     * @param startDate The date to export invoices from.
     * @param endDate The date to export invoices to.
     * @returns Downloads a .csv file with the exported invoices.
     */
    exportXero(
        startDate: string,
        endDate: string,
        type: 'summary' | 'lineItems'
    ): Promise<Blob> {
        let contentType = 'text/csv';

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: contentType,
        });
        return lastValueFrom(
            this.http.post<Blob>(
                `/api/${this.modelName}/export`,
                { startDate, endDate, type },
                {
                    headers,
                    responseType: 'blob' as 'json',
                }
            )
        );
    }
}
