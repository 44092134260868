// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

import type { StatementFrontend as Statement } from '../../server/api/statement/statement';
export type { StatementFrontend as Statement } from '../../server/api/statement/statement';

@Injectable()
export class StatementService extends BaseService<Statement> {
    modelName = 'statements';

    constructor(injector: Injector) {
        super(injector);
    }

    send(
        id: string,
        sendUser: boolean = true,
        sendMe: boolean = false,
        additionalEmails?: string[]
    ): Observable<Statement> {
        return this.http.post<Statement>(`/api/${this.modelName}/${id}/send`, {
            sendUser,
            sendMe,
            additionalEmails,
        });
    }

    pay(id: string, reference?): Observable<Statement> {
        return this.http.post<Statement>(`/api/${this.modelName}/${id}/pay`, {
            reference,
        });
    }

    cancel(id: string): Observable<Statement> {
        return this.http.post<Statement>(
            `/api/${this.modelName}/${id}/cancel`,
            {}
        );
    }

    generatePayable(userId: string = undefined): Observable<Statement> {
        return this.http.post<Statement>(`/api/${this.modelName}/generate`, {
            userId,
        });
    }

    getBalanceStatement(userId = undefined, fromDate?: Date, toDate?: Date) {
        const params = {};
        if (userId) params['userId'] = userId;
        if (fromDate) params['fromDate'] = fromDate.valueOf();
        if (toDate) params['toDate'] = toDate.valueOf();
        return this.http.get(`/api/${this.modelName}/balance`, {
            params: params,
        });
    }
}
