/** Angular Imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/** Modules Imports */
import { NgCircleProgressModule } from 'ng-circle-progress';

/** Components Imports */
import { CircleTimer } from './circle-timer.component';

// *******************************************************************************
//

@NgModule({
    declarations: [CircleTimer],
    imports: [CommonModule, NgCircleProgressModule.forRoot({})],
    exports: [CircleTimer],
})
export class CircleTimerModule {}
