import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';

//Setup typescript
//manage -> Wildcard for all actions
export type Actions = 'create' | 'view' | 'update' | 'delete' | 'manage';
//all -> Wildcard for all subjects
export type Subjects =
    | 'client'
    | 'affiliate'
    | 'admin'
    | 'superAdmin'
    | 'api'
    | 'ApiKeys'
    | 'Users'
    | 'Accounts'
    | 'Gateways'
    | 'MFA'
    | 'Items'
    | 'Events'
    | 'Subscriptions'
    | 'Invoices'
    | 'Statements'
    | 'CreditNotes'
    | 'ClientDash'
    | 'AdminDash'
    | 'CostCentres'
    | 'Webhooks'
    | 'all';
export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineAbilityForAccount(user, account) {
    const { can, cannot, rules } = new AbilityBuilder<AppAbility>(AppAbility);
    const role = account ? account.role : user.role;

    //This is hard coded for now but can be moved to db in future
    switch (role) {
        case 'superAdmin': {
            can('manage', 'Accounts'); // All actions for accounts
            can('manage', 'Gateways');
            can('manage', 'ApiKeys');
            can('manage', 'Users');

            can('manage', 'api');
            can('manage', 'superAdmin');
            can('manage', 'admin');
            can('manage', 'affiliate');
            can('manage', 'client');

            can('manage', 'MFA');

            can('view', 'Events');
            can('manage', 'Items');
            can('manage', 'Subscriptions');
            can('manage', 'Invoices');
            can('manage', 'Statements');
            can('manage', 'CreditNotes');

            can('view', 'ClientDash');
            can('view', 'AdminDash');

            can('manage', 'Webhooks');
            can('view', 'CostCentres');

            break;
        }
        case 'admin': {
            can('update', 'Accounts'); // All actions for accounts
            can('view', 'Accounts'); // All actions for accounts
            can('manage', 'Gateways');
            can('manage', 'Users');

            can('manage', 'admin');
            can('manage', 'affiliate');
            can('manage', 'client');

            can('manage', 'MFA');

            can('view', 'Events');
            can('manage', 'Items');
            can('manage', 'Subscriptions');
            can('manage', 'Invoices');
            can('manage', 'Statements');
            can('manage', 'CreditNotes');

            can('view', 'ClientDash');
            can('view', 'AdminDash');

            can('view', 'CostCentres');

            break;
        }
        case 'affiliate': {
            can('view', 'Accounts'); // All actions for accounts
            can('manage', 'Users');

            can('manage', 'client');

            can('view', 'Events');
            can('manage', 'Items');
            can('manage', 'Subscriptions');
            can('manage', 'Invoices');
            can('manage', 'Statements');
            can('manage', 'CreditNotes');

            can('view', 'ClientDash');

            break;
        }
        case 'client': {
            can('view', 'Accounts');

            can('view', 'Events');
            can('view', 'Subscriptions');
            can('view', 'Invoices');
            can('create', 'Statements');
            can('view', 'Statements');
            can('view', 'CreditNotes');

            can('view', 'ClientDash');

            break;
        }
    }

    return rules;
}

//export function defineAbilityForUser(user) {
//const { can, cannot, build } = new AbilityBuilder<AppAbility>();
//const role = user.role;

////This is hard coded for now but can be moved to db in future
//if (role == 'admin') {
//can('manage', 'User', ['name', 'email', 'accounts']); // All actions for users
////can('manage', 'Thing', ['info']); // All actions for things in current account
//can('manage', 'Account'); // All actions for accounts
//} else {
//can('read', 'User', { _id: user._id }); // read-only access to this user
//can('read', 'Thing'); // read-only access to everything
//can('read', 'Account'); // read-only access to everything
//}

//cannot('delete', 'User'); //Users cannot be deleted

//return build();
//}
