<div class="authentication-wrapper authentication-2 px-4">
    <div class="authentication-inner py-5">
        <!-- Form -->
        <form class="card" #passwordResetForm="ngForm">
            <div class="p-4 p-sm-5">
                <!-- Logo -->
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-grow-1">
                        <div
                            class="w-100 position-relative app-brand-logo"
                            [class.background]="logoService.lightLogo"
                        >
                            <svg-icon
                                [src]="logoService.domainLogo"
                                [class]="'logo'"
                                [svgStyle]="{ 'margin.em': 1 }"
                                [applyClass]="true"
                            >
                                B'ling Logo
                                <!-- fallback image in CSS -->
                            </svg-icon>
                        </div>
                    </div>
                </div>
                <!-- / Logo -->

                <h5 class="text-center text-muted font-weight-normal mb-4">
                    Reset Your Password
                </h5>

                <hr class="mt-0 mb-4" />

                <div *ngIf="!hasToken && !linkSent">
                    <p>
                        Enter your email address and we will send you a link to
                        reset your password.
                    </p>

                    <div class="form-group">
                        <input
                            id="email"
                            [(ngModel)]="credentials.email"
                            name="email"
                            type="text"
                            class="form-control"
                            placeholder="Enter your email address"
                            email
                            required
                            #email="ngModel"
                        />
                    </div>

                    <button
                        type="button"
                        class="btn btn-primary btn-block"
                        (click)="requestReset()"
                    >
                        Send password reset email
                    </button>
                </div>

                <div *ngIf="linkSent && !hasToken">
                    <p>
                        A reset link has been sent to {{ credentials.email }}.
                        Please check your email and use this link to reset your
                        password.
                    </p>
                    <button
                        type="button"
                        class="btn btn-primary btn-block"
                        (click)="requestReset()"
                    >
                        Resend Link
                    </button>
                </div>

                <div *ngIf="hasToken">
                    <p>Enter and confirm your new password.</p>

                    <div class="form-group">
                        <input
                            id="password"
                            [(ngModel)]="credentials.password"
                            name="newPassword"
                            type="password"
                            class="form-control"
                            autocomplete="new-password"
                            (ngModelChange)="onPasswordChange($event)"
                            [ngClass]="{
                                'is-invalid':
                                    (newPassword.touched ||
                                        newPassword.dirty) &&
                                    newPassword.invalid
                            }"
                            placeholder="Enter your new password"
                            required
                            #newPassword="ngModel"
                            minlength="8"
                        />
                        <ng9-password-strength-bar
                            [passwordToCheck]="credentials.password"
                        ></ng9-password-strength-bar>
                        <div
                            *ngIf="
                                (newPassword.touched || newPassword.dirty) &&
                                newPassword.invalid
                            "
                        >
                            <small
                                class="text-danger"
                                *ngIf="newPassword.errors.required"
                                >Password is required</small
                            >
                            <small
                                class="text-danger"
                                *ngIf="newPassword.errors.minlength"
                                >Password is too short</small
                            >
                        </div>
                        <div *ngIf="feedback && feedback.warning">
                            <small class="text-danger">{{
                                feedback.warning
                            }}</small>
                        </div>
                        <div *ngIf="feedback && feedback.suggestions">
                            <div
                                *ngFor="let suggestion of feedback.suggestions"
                            >
                                <small>*{{ suggestion }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            id="confirmPassword"
                            [(ngModel)]="credentials.confirmPassword"
                            name="confirmPassword"
                            type="password"
                            class="form-control"
                            autocomplete="new-password"
                            [ngClass]="{
                                'is-invalid':
                                    (confirmPassword.touched ||
                                        confirmPassword.dirty) &&
                                    confirmPassword.invalid
                            }"
                            placeholder="Confirm password"
                            required
                            #confirmPassword="ngModel"
                            minlength="5"
                        />
                        <small
                            class="text-danger"
                            *ngIf="
                                (confirmPassword.touched ||
                                    confirmPassword.dirty) &&
                                credentials.confirmPassword !=
                                    credentials.password
                            "
                            >Passwords do not match</small
                        >
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary btn-block"
                        [disabled]="passwordResetForm.invalid || !valid"
                        (click)="resetPassword()"
                    >
                        Reset Password
                    </button>
                    <a
                        href="/authentication/password-reset"
                        class="d-block small"
                        >Resend Link?</a
                    >
                </div>
            </div>
        </form>
        <!-- / Form -->
    </div>
</div>
