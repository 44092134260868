// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

import type { Model } from './base.service';

@Injectable()
export class GatewayService extends BaseService<Model> {
    modelName = 'gateways';

    constructor(injector: Injector) {
        super(injector);
    }

    payfastGetInvoice(id): Observable<any> {
        return this.http.get(`/api/${this.modelName}/payfast/invoice/${id}`);
    }
}
