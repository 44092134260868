/*eslint no-process-env:0*/
const { version } = require('../../../package.json');

const EventTypes = [
    'Subscription Create',
    'Subscription Update',
    'Subscription Cancelled',
    'Subscription Item Cancelled',
    'Subscription Item Activated',
    'Invoice Generated',
    'Invoice Approved',
    'Invoice Sent',
    'Invoice Paid',
    'Invoice Cancelled',
    'Statement Generated',
    'Statement Sent',
    'Statement Paid',
    'Statement Cancelled',
    'CreditNote Sent',
    'Payfast Payment',
    'Cost Centre Paid'
];

const WebhookMethods = ['GET', 'POST', 'PUT'];
const WebhookEvents = ['Invoice Paid'];

export { version };
export { EventTypes };
export { WebhookMethods };
export { WebhookEvents };
export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
// List of user roles
export const userRoles =['client', 'affiliate', 'admin', 'superAdmin']
export const apiRoles = ['api']
export const allRoles = [...userRoles, ...apiRoles]; //TODO Update user roles
export const policyUpdated = {
    privacy: new Date('2022-06-13T00:00:00.000Z'),
    disclaimer: new Date('2022-06-13T00:00:00.000Z'),
    terms: new Date('2022-06-13T00:00:00.000Z'),
};

export default {
    env,
    port,
    userRoles: allRoles,
    policyUpdated,
    version,
    EventTypes,
};
