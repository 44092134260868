// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { EventTypes } from '../app/app.constants';
import type { EventFrontend as Event } from 'server/api/event/event';
export type { EventFrontend as Event } from 'server/api/event/event';


@Injectable()
export class EventService extends BaseService<Event> {
    modelName = 'events';

    constructor(injector: Injector) {
        super(injector);
    }

    getRequiresAction(): Observable<Event[]> {
        return this.http.get<Event[]>(
            `/api/${this.modelName}/admin/requiresAction`
        );
    }
    
    getRequiresActionCount(): Observable<number> {
        return this.http.get<number>(
            `/api/${this.modelName}/admin/requiresActionCount`
        );
    }
}
export { EventTypes };
