/** Angular Imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/** Modules Imports */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/** Components Imports */
import { Disclaimer } from './disclaimer.component';
import { ConsentModal } from './consent.modal.component';
import { Privacy } from './privacy.component';
import { Terms } from './terms.component';

// *******************************************************************************
//

@NgModule({
    declarations: [ConsentModal, Privacy, Disclaimer, Terms],
    imports: [CommonModule, NgbModule, FontAwesomeModule],
    exports: [ConsentModal],
    entryComponents: [ConsentModal],
})
export class ConsentModule {}
