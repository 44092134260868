import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { NotFoundComponent } from './not-found/not-found.component';
import { LoginGuard } from '../components/auth/login-guard.service';
import { VerifyGuard } from '../components/auth/verify-guard.service';
import { UsersModule } from '../app/users/users.module';
import { EventsModule } from '../app/events/events.module';
import { PlansModule } from './plans/plans.module';
import { ProductModule } from './product/product.module';
import { AddonsModule } from './addons/addons.module';
import { DiscountsModule } from './discounts/discounts.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { InvoicesModule } from './invoices/invoices.module';
import { AuthenticationModule } from '../app/authentication/authentication.module';
import { AccountModule } from '../app/account/account.module';
import { AccountResolver } from '../components/auth/account.resolver';
import { InvoicesBlankModule } from './invoices/invoices-blank.module';
import { StatementsModule } from './statements/statements.module';
import { CreditNotesModule } from './credit-notes/credit-notes.module';
import { StatementsBlankModule } from './statements/statements-blank.module';
import { CreditNotesBlankModule } from './credit-notes/credit-notes-blank.module';
import { ApiKeysModule } from './api-keys/api-keys.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CostCentresModule } from './cost-centres/cost-centres.module';
import { WebhooksModule } from './webhooks/webhooks.module';
import { defaultRoute } from './app.constants';
// *******************************************************************************
// Layouts

import { Layout2Component } from './layout/layout-2/layout-2.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';

// *******************************************************************************
// Routes

const routes: Routes = [
    {
        path: 'costCentres',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<CostCentresModule> => {
            return import('./cost-centres/cost-centres.module').then(
                (m) => m.CostCentresModule
            );
        },
    },
    {
        path: 'dashboard',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<DashboardModule> => {
            return import('./dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            );
        },
    },
    {
        path: 'webhooks',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<WebhooksModule> => {
            return import('./webhooks/webhooks.module').then(
                (m) => m.WebhooksModule
            );
        },
    },
    {
        path: 'users',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<UsersModule> => {
            return import('./users/users.module').then((m) => m.UsersModule);
        },
    },
    {
        path: 'events',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<EventsModule> => {
            return import('./events/events.module').then((m) => m.EventsModule);
        },
    },
    {
        path: 'account',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<AccountModule> => {
            return import('./account/account.module').then(
                (m) => m.AccountModule
            );
        },
    },
    {
        path: 'plans',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<PlansModule> => {
            return import('./plans/plans.module').then((m) => m.PlansModule);
        },
    },
    {
        path: 'addons',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<AddonsModule> => {
            return import('./addons/addons.module').then((m) => m.AddonsModule);
        },
    },
    {
        path: 'discounts',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<DiscountsModule> => {
            return import('./discounts/discounts.module').then(
                (m) => m.DiscountsModule
            );
        },
    },
    {
        path: 'products',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<ProductModule> => {
            return import('./product/product.module').then(
                (m) => m.ProductModule
            );
        },
    },
    {
        path: 'subscriptions',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<SubscriptionsModule> => {
            return import('./subscriptions/subscriptions.module').then(
                (m) => m.SubscriptionsModule
            );
        },
    },
    {
        path: 'invoices',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<InvoicesModule> => {
            return import('./invoices/invoices.module').then(
                (m) => m.InvoicesModule
            );
        },
    },
    {
        path: 'statements',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<StatementsModule> => {
            return import('./statements/statements.module').then(
                (m) => m.StatementsModule
            );
        },
    },
    {
        path: 'creditNotes',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<CreditNotesModule> => {
            return import('./credit-notes/credit-notes.module').then(
                (m) => m.CreditNotesModule
            );
        },
    },
    {
        path: 'invoices',
        component: LayoutBlankComponent,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<InvoicesBlankModule> => {
            return import('./invoices/invoices-blank.module').then(
                (m) => m.InvoicesBlankModule
            );
        },
    },
    {
        path: 'statements',
        component: LayoutBlankComponent,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<StatementsBlankModule> => {
            return import('./statements/statements-blank.module').then(
                (m) => m.StatementsBlankModule
            );
        },
    },
    {
        path: 'creditNotes',
        component: LayoutBlankComponent,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<CreditNotesBlankModule> => {
            return import('./credit-notes/credit-notes-blank.module').then(
                (m) => m.CreditNotesBlankModule
            );
        },
    },
    {
        path: 'apiKeys',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<ApiKeysModule> => {
            return import('./api-keys/api-keys.module').then(
                (m) => m.ApiKeysModule
            );
        },
    },
    {
        path: 'authentication',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<AuthenticationModule> => {
            return import('./authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            );
        },
    },
    { path: '', redirectTo: `/${defaultRoute}`, pathMatch: 'full' },
];

// *******************************************************************************
// Base Routes

const baseRoutes: Routes = [
    {
        path: ':accountId',
        resolve: {
            currentAccount: AccountResolver,
        },
        children: routes,
    },
    {
        path: 'invoices',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<InvoicesBlankModule> => {
            return import('./invoices/invoices-blank.module').then(
                (m) => m.InvoicesBlankModule
            );
        },
    },
    {
        path: 'statements',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<StatementsBlankModule> => {
            return import('./statements/statements-blank.module').then(
                (m) => m.StatementsBlankModule
            );
        },
    },
    {
        path: 'creditNotes',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<CreditNotesBlankModule> => {
            return import('./credit-notes/credit-notes-blank.module').then(
                (m) => m.CreditNotesBlankModule
            );
        },
    },
    {
        path: 'authentication',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<AuthenticationModule> => {
            return import('./authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            );
        },
    },

    {
        path: '',
        redirectTo: `accPlaceholder/${defaultRoute}`,
        pathMatch: 'full',
    },
    //Wildcard redirect to hom
    {
        path: '**',
        redirectTo: `accPlaceholder/${defaultRoute}`,
        pathMatch: 'full',
    },

    // 404 Not Found page
    //{ path: '**', component: NotFoundComponent },
];
// *******************************************************************************
//

@NgModule({
    imports: [RouterModule.forRoot(baseRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
