
<!--Adding the class "bg-secondary-darker" and "footer-dark" manually due to issues with the theme-settings.js script -->
<!--<nav class="footer bg-secondary-darker footer-dark" >-->
<nav class="footer no-print" style="background:#e8e8e9">
  <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
    <div class="pt-3">
      <span class="footer-text font-weight-bolder">B'ling</span> ©
      <small>v {{authService.getVersion()}}</small>
    </div>
    <div>
      <a href="https://jerichosystems.co.za/contact-us/" class="footer-link pt-3">Contact</a>
      <a href="/assets/policy/privacy.html" class="footer-link pt-3 ml-4">Privacy Policy</a>
      <a href="/assets/policy/terms.html" class="footer-link pt-3 ml-4">Terms &amp; Conditions</a>
      <a href="/assets/policy/disclaimer.html" class="footer-link pt-3 ml-4">Disclaimer</a>
    </div>
  </div>
</nav>
