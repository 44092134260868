/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/** Modules Imports */
import { lastValueFrom } from 'rxjs';

/** Services Imports */
import { AppService } from 'client/app/app.service';
import { AuthService } from 'client/components/auth/auth.service';
import { UserService } from 'client/services/user.service';

/** Type Imports */
import type { User } from 'client/services/user.service';

// *******************************************************************************
//

@Component({
    selector: 'app-email-request',
    templateUrl: './email-request.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class EmailRequestComponent implements OnInit {
    user: User;
    constructor(
        private appService: AppService,
        public authService: AuthService,
        private userService: UserService
    ) {
        this.appService.pageTitle = 'Confirm Email';
    }

    ngOnInit() {
        this.authService.getCurrentUser().then((user) => {
            this.user = user;
            lastValueFrom(
                this.userService.requestVerificationEmail(this.user._id)
            )
                .then(console.debug)
                .catch(console.error);
        });
    }

    resendEmail() {
        lastValueFrom(this.userService.requestVerificationEmail(this.user._id))
            .then(console.debug)
            .catch(console.error);
    }
}
