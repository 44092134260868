// @flow
import { Injectable, Injector } from '@angular/core';
import { ItemService } from './item.service';

import type { ProductFrontend as Product } from '../../server/api/product/product';
export type { ProductFrontend as Product } from '../../server/api/product/product';

@Injectable()
export class ProductService extends ItemService<Product> {
    modelName = 'products';

    constructor(injector: Injector) {
        super(injector);
    }
}
