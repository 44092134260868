/** Angular Imports */
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

/** Modules Imports */
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import zxcvbn from 'zxcvbn';

/** Services Imports */
import { AppService } from 'client/app/app.service';
import { LogoService } from 'client/components/logoManager/logoManager.service';
import { UserService } from 'client/services/user.service';

// *******************************************************************************
//

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class PasswordResetComponent implements OnInit {
    @ViewChild('passwordResetForm') passwordResetForm: NgForm;
    hasToken = false;
    token: string;
    userId: string;
    linkSent = false;

    credentials = {
        email: '',
        userId: '',
        password: '',
        token: '',
        confirmPassword: '',
    };

    feedback: {
        warning?: string;
        suggestions?: string[];
    };
    valid = false;

    constructor(
        private appService: AppService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        public logoService: LogoService
    ) {
        this.appService.pageTitle = 'Password Reset';
    }

    ngOnInit() {
        firstValueFrom(this.route.paramMap)
            .then((params: ParamMap) => {
                if (params.has('userId') && params.has('token')) {
                    this.credentials.userId = params.get('userId');
                    this.credentials.token = params.get('token');
                    this.hasToken = true;
                }
            })
            .catch(console.error);
    }

    requestReset() {
        lastValueFrom(
            this.userService.requestForgotPasswordEmail(this.credentials.email)
        )
            .then((val) => (this.linkSent = true))
            .catch(console.error);
    }

    resetPassword() {
        lastValueFrom(this.userService.resetPassword(this.credentials))
            .then((val) => {
                this.toastrService.success('Password reset.');
                this.router.navigate(['/authentication/login']);
            })
            .catch((err) => {
                this.toastrService.error('Password reset failed.');
                this.router.navigate(['/authentication/password-reset']);

                console.error(err);
            });
    }

    doLog() {
        console.debug(this);
    }

    onPasswordChange(event) {
        const result = zxcvbn(this.credentials.password);

        if (result && result.score < 2) {
            this.feedback = result.feedback;
            this.valid = false;
        } else {
            this.feedback = undefined;
            this.valid = true;
        }
    }
}
