/** Angular Imports */
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

/** Modules Imports */
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

/** Services Imports */
import { AppService } from 'client/app/app.service';
import { UserService } from 'client/services/user.service';

// *******************************************************************************
//

@Component({
    selector: 'app-email-confirm',
    templateUrl: './email-confirm.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class EmailConfirmComponent implements OnInit {
    faSpinner = faSpinner;

    credentials = {
        userId: '',
        token: '',
    };
    verified = false;
    error = false;

    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private toastrService: ToastrService
    ) {
        this.appService.pageTitle = 'Confirm Email';
    }

    ngOnInit() {
        firstValueFrom(this.route.paramMap)
            .then((params: ParamMap) => {
                if (params.has('userId') && params.has('token')) {
                    this.credentials.userId = params.get('userId');
                    this.credentials.token = params.get('token');

                    lastValueFrom(
                        this.userService.verifyEmail(this.credentials)
                    )
                        .then((val) => {
                            this.verified = true;
                        })
                        .catch((err) => {
                            this.toastrService.error('Verification failed');
                            console.error(err);
                            this.router.navigate([
                                '/authentication/email-request',
                            ]);
                        });
                }
            })
            .catch((err) => console.error(err));
    }

    login() {
        this.router.navigate(['/authentication/login']);
    }
}
