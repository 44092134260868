// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

import type { ApiKeyFrontend as ApiKey } from 'server/api/apiKey/apiKey';
export type { ApiKeyFrontend as ApiKey } from 'server/api/apiKey/apiKey';

@Injectable()
export class ApiKeyService extends BaseService<ApiKey> {
    modelName = 'apiKeys';

    constructor(injector: Injector) {
        super(injector);
    }

    addKeyAccounts(apiKeyId: string, accountIds: string[]): Observable<ApiKey> {
        return this.http.patch<ApiKey>(`/api/apiKeys/${apiKeyId}/addAccounts`, {
            accountIds,
        });
    }
}
