<sidenav [orientation]="orientation" [ngClass]="getClasses()" class="no-print">
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" *ngIf="orientation !== 'horizontal'">
        <span routerLink="/" class="app-brand-logo demo">
            <div
                *ngIf="!(hasAccountImage | async)"
                style="width: 100%; height: 100%"
            >
                <svg-icon
                    [src]="logoService.domainSidebar"
                    [class]="'logo'"
                    [svgStyle]="{ width: '100%', height: '100%' }"
                >
                    B'ling Logo
                    <!-- fallback image in CSS -->
                </svg-icon>
                <svg-icon
                    [src]="logoService.domainIcon"
                    [class]="'icon'"
                    [svgStyle]="{ width: '100%', height: '100%' }"
                >
                    B'ling Icon
                    <!-- fallback image in CSS -->
                </svg-icon>
            </div>
            <div *ngIf="hasAccountImage | async">
                <img
                    [src]="accountImage"
                    alt
                    style="width: 100%; padding: 0.5em"
                />
            </div>
        </span>
        <a
            class="layout-sidenav-toggle sidenav-link text-large ml-auto"
            (click)="toggleSidenav()"
        >
            <i class="ion ion-md-menu align-middle"></i>
        </a>
    </div>
    <div
        class="sidenav-divider mt-0"
        *ngIf="orientation !== 'horizontal'"
    ></div>

    <!-- -------------------------------- Inner -------------------------------- -->
    <div
        class="sidenav-inner"
        [ngClass]="{ 'py-1': orientation !== 'horizontal' }"
    >
        <!-- ------------------------------ Dashboard ------------------------------ -->
        <sidenav-menu
            [icon]="faGaugeLow"
            text="Dashboard"
            [active]="isMenuActive('/dashboard')"
            [open]="isMenuOpen('/dashboard')"
        >
            <sidenav-router-link
                *ngIf="'view' | able: 'ClientDash'"
                [icon]="faGaugeLow"
                route="/accPlaceholder/dashboard/client"
                [active]="isActive('/dashboard/client')"
            >
                Client
            </sidenav-router-link>
            <sidenav-router-link
                *ngIf="'view' | able: 'AdminDash'"
                [icon]="faGaugeLow"
                route="/accPlaceholder/dashboard/admin"
                [active]="isActive('/dashboard/admin')"
            >
                Admin
            </sidenav-router-link>
        </sidenav-menu>

        <!-- -------------------------------- Items -------------------------------- -->
        <sidenav-menu
            *ngIf="'view' | able: 'Items'"
            [icon]="faBoxesStacked"
            text="Items"
            [active]="
                isMenuActive(['/plans', '/products', '/addons', '/discounts'])
            "
            [open]="
                isMenuOpen(['/plans', '/products', '/addons', '/discounts'])
            "
        >
            <sidenav-router-link
                [icon]="faPuzzlePiece"
                route="/accPlaceholder/products/list"
                [active]="isActive('/products/list')"
            >
                Products
            </sidenav-router-link>
            <sidenav-router-link
                [icon]="faPuzzlePieceAlt"
                route="/accPlaceholder/addons/list"
                [active]="isActive('/addons/list')"
            >
                Addons
            </sidenav-router-link>
            <sidenav-router-link
                [icon]="faPuzzle"
                route="/accPlaceholder/plans/list"
                [active]="isActive('/plans/list')"
            >
                Plans
            </sidenav-router-link>
            <sidenav-router-link
                [icon]="faBadgePercent"
                route="/accPlaceholder/discounts/list"
                [active]="isActive('/discounts/list')"
            >
                Discounts
            </sidenav-router-link>
        </sidenav-menu>

        <!-- ---------------------------- Subscription ----------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'Subscriptions'"
            [icon]="faRotateRight"
            route="/accPlaceholder/subscriptions/list"
            [active]="isActive('/subscriptions')"
        >
            Subscriptions
        </sidenav-router-link>

        <!-- ------------------------------ Invoices ------------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'Invoices'"
            [icon]="faFileInvoiceDollar"
            route="/accPlaceholder/invoices/list"
            [active]="isActive('/invoices')"
        >
            Invoices
        </sidenav-router-link>

        <!-- ----------------------------- Statements ------------------------------ -->
        <sidenav-menu
            [icon]="faFileInvoiceDollar"
            text="Statements"
            [active]="isMenuActive('/statements')"
            [open]="isMenuOpen('/statements')"
        >
            <sidenav-router-link
                *ngIf="'view' | able: 'Statements'"
                [icon]="faFileInvoiceDollar"
                route="/accPlaceholder/statements/list"
                [active]="isActive('/statements/list')"
            >
                Payable
            </sidenav-router-link>
            <sidenav-router-link
                *ngIf="'view' | able: 'Statements'"
                [icon]="faFileInvoiceDollar"
                route="/accPlaceholder/statements/balance"
                [active]="isActive('/statements/balance')"
            >
                Balance
            </sidenav-router-link>
        </sidenav-menu>

        <!-- ---------------------------- Credit Notes ----------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'CreditNotes'"
            [icon]="faFileInvoiceDollar"
            route="/accPlaceholder/creditNotes/list"
            [active]="isActive('/creditNotes')"
        >
            Credit Notes
        </sidenav-router-link>

        <!-- -------------------------------- Users -------------------------------- -->
        <sidenav-menu
            [icon]="faUserFriends"
            text="Users"
            [active]="isMenuActive('/users')"
            [open]="isMenuOpen('/users')"
        >
            <sidenav-router-link
                *ngIf="'view' | able: 'Users'"
                [icon]="faList"
                route="/accPlaceholder/users/list"
                [active]="isActive('/users/list')"
            >
                List
            </sidenav-router-link>
            <sidenav-router-link
                [icon]="faPenToSquare"
                route="/accPlaceholder/users/edit"
                [active]="isActive('/users/edit')"
            >
                Edit
            </sidenav-router-link>
            <sidenav-router-link
                [icon]="faKey"
                route="/accPlaceholder/users/settings"
                [active]="isActive('/users/settings')"
            >
                Password Reset / MFA
            </sidenav-router-link>
        </sidenav-menu>

        <!-- ------------------------------- Account ------------------------------- -->
        <sidenav-menu
            *ngIf="'view' | able: 'Accounts'"
            [icon]="faCogs"
            text="Accounts"
            [active]="isMenuActive('/account')"
            [open]="isMenuOpen('/account')"
        >
            <sidenav-router-link
                *ngIf="'view' | able: 'Accounts'"
                [icon]="faList"
                route="/accPlaceholder/account/list"
                [active]="isActive('/account/list')"
            >
                List
            </sidenav-router-link>
            <sidenav-router-link
                *ngIf="'view' | able: 'Gateways'"
                [icon]="faHandDollar"
                route="/accPlaceholder/account/gateways"
                [active]="isActive('/account/gateways')"
            >
                Gateways
            </sidenav-router-link>
        </sidenav-menu>

        <!-- ------------------------------ Api Keys ------------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'ApiKeys'"
            [icon]="faCogs"
            route="/accPlaceholder/apiKeys/list"
            [active]="isActive('/apiKeys')"
        >
            Api Keys
        </sidenav-router-link>

        <!-- ------------------------------ Webhooks ------------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'Webhooks'"
            [icon]="faCogs"
            route="/accPlaceholder/webhooks/list"
            [active]="isActive('/webhooks')"
        >
            Webhooks
        </sidenav-router-link>

        <!-- ---------------------------- Access Events ---------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'Events'"
            [icon]="faClockRotateLeft"
            route="/accPlaceholder/events/list"
            [active]="isActive('/events')"
        >
            Events
        </sidenav-router-link>

        <!-- ---------------------------- Cost Centres ----------------------------- -->
        <sidenav-router-link
            *ngIf="'view' | able: 'CostCentres'"
            [icon]="faCostCentres"
            route="/accPlaceholder/costCentres/list"
            [active]="isActive('/costCentres')"
        >
            Cost Centres
        </sidenav-router-link>
    </div>
</sidenav>
