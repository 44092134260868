// @flow
/** Angular Imports */
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';

/** Modules Imports */
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/** Services Imports */
import { AuthService } from 'client/components/auth/auth.service';
import { LogoService } from 'client/components/logoManager/logoManager.service';

/** Components Imports */
import { TOTPModal } from 'client/components/totp/totp.modal.component';

// *******************************************************************************
//

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = { login: undefined };
    submitted = false;
    returnUrl;

    //static parameters = [AuthService, Router, LogoService];
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public logoService: LogoService,
        private modalService: NgbModal
    ) {}

    login(form) {
        const self = this;
        if (form.invalid) return;

        this.returnUrl =
            this.route.snapshot.queryParams['returnUrl'] ||
            'accPlaceholder/home';

        return this.authService
            .login({
                email: this.user.email,
                password: this.user.password,
            })
            .then((data) => {
                if (data) {
                    if (!data.verified) {
                        return this.router.navigate([
                            'authentication/email-request',
                        ]);
                    } else if (data.mfaRequired && data.mfa) {
                        const modalRef = this.modalService.open(TOTPModal, {
                            backdrop: 'static',
                        });
                        modalRef.componentInstance.mfa = data.mfa;
                        modalRef.result
                            .then(() => {
                                this.router.navigate([this.returnUrl]);
                            })
                            .catch(() => null);
                    } else {
                        this.router.navigate([this.returnUrl]);
                    }
                } else {
                    // TODO: Saved state redirect <09-10-20, Liaan> //
                    this.router.navigate([this.returnUrl]);
                }
                // Logged in, redirect to home
            })
            .catch((err) => {
                console.error(err);
                if (err && err.error && err.error.message) {
                    this.errors.login = err.error.message;
                }
            });
    }
}
