<div class="authentication-wrapper authentication-1 px-4">
    <div class="authentication-inner py-5">
        <!-- Logo -->
        <div class="d-flex justify-content-center align-items-center">
            <div class="flex-grow-1">
                <div class="w-100 position-relative app-brand-logo">
                    <svg-icon
                        [src]="logoService.domainLogo"
                        [class]="'logo'"
                        [applyClass]="true"
                    >
                        B'ling Logo
                        <!-- fallback image in CSS -->
                    </svg-icon>
                </div>
            </div>
        </div>
        <!-- / Logo -->

        <!-- Form -->
        <form
            class="my-5"
            name="loginForm"
            (ngSubmit)="login(loginForm)"
            novalidate
            #loginForm="ngForm"
        >
            <div class="form-group">
                <label class="form-label">Email</label>
                <input
                    style="padding: 0.5em"
                    [(ngModel)]="user.email"
                    name="email"
                    type="text"
                    class="form-control"
                    required
                    #email="ngModel"
                />
            </div>
            <div class="form-group">
                <label
                    class="form-label d-flex justify-content-between align-items-end"
                >
                    <div>Password</div>
                    <a
                        href="/authentication/password-reset"
                        class="d-block small"
                        >Forgot password?</a
                    >
                </label>
                <input
                    style="padding: 0.5em"
                    [(ngModel)]="user.password"
                    name="password"
                    type="password"
                    class="form-control"
                    required
                    #password="ngModel"
                />
            </div>
            <div class="d-flex justify-content-between align-items-center m-0">
                <!--<label class="custom-control custom-checkbox m-0">-->
                <!--<input type="checkbox" class="custom-control-input" name="rememberMe" [(ngModel)]="credentials.rememberMe">-->
                <!--<span class="custom-control-label">Remember me</span>-->
                <!--</label>-->
                <div class="form-group has-error">
                    <p
                        class="help-block"
                        [hidden]="
                            (email.value && password.value) ||
                            !loginForm.submitted
                        "
                    >
                        Please enter your email and password.
                    </p>
                    <p
                        class="help-block"
                        [hidden]="
                            email.valid ||
                            (email.pristine && !loginForm.submitted)
                        "
                    >
                        Please enter a valid email.
                    </p>

                    <p class="help-block">{{ errors.login }}</p>
                </div>
                <button
                    type="submit"
                    [disabled]="!loginForm.form.valid"
                    class="btn btn-primary"
                >
                    Sign In
                </button>
            </div>
        </form>
        <!-- / Form -->

        <!-- TODO: Implement registration process <25-06-20, Liaan> -->
        <!--<div class="text-center text-muted">-->
        <!--Don't have an account yet? <a href="/authentication/register">Sign Up</a>-->
        <!--</div>-->
    </div>
</div>
