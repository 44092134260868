/** Angular Imports */
import { Component, Input, OnInit } from '@angular/core';

/** Modules Imports */
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Services Imports */
import { AuthService } from 'client/components/auth/auth.service';
import { UserService } from 'client/services/user.service';

/** Type Imports */
import type { User } from 'client/services/user.service';
import { lastValueFrom } from 'rxjs';

// *******************************************************************************
//

@Component({
    selector: 'consent-modal',
    templateUrl: './consent.modal.html',
    styleUrls: ['./consent.modal.scss'],
})
export class ConsentModal implements OnInit {
    @Input() public user: User;

    faClipboardList = faClipboardList;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {}

    ok() {
        const update = {
            consent: {
                privacy: new Date(),
                terms: new Date(),
                disclaimer: new Date(),
            },
        };

        lastValueFrom(this.userService.saveMe(update))
            .then((user) => {
                this.authService.currentUser = user;
                this.activeModal.close();
            })
            .catch(console.error);
    }

    doLog() {
        console.debug(this);
    }

    cancel() {
        this.activeModal.close();
    }
}
