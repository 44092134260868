/** Angular Imports */
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/** Modules Imports */
import { Ability } from '@casl/ability';

/** Services Imports */
import { AuthService } from './auth.service';

// *******************************************************************************
//

@Injectable()
export class AbilityGuard implements CanActivate {
    authService;
    router;

    constructor(
        authService: AuthService,
        router: Router,
        private ability: Ability
    ) {
        this.authService = authService;
        this.router = router;
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const self = this;
        if (route.data?.subject) {
            if (!this.authService.currentAccount) {
                const accountId = route.paramMap.get('accountId');
                let account = await this.authService.setCurrentAccount(
                    accountId,
                    state
                );
            }
            // console.log(this.authService.abilityRules);
            this.ability.update(this.authService.abilityRules);

            let action = route.data?.action || 'view';
            let subject = route.data.subject;
            let can = this.ability.can(action, subject);
            if (!can) return this.router.parseUrl('accPlaceholder/home');
            return can;
        } else {
            return true;
        }
    }
}
