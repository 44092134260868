// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

import type { CreditNoteFrontend as CreditNote } from '../../server/api/creditNote/creditNote';
export type { CreditNoteFrontend as CreditNote } from '../../server/api/creditNote/creditNote';

@Injectable()
export class CreditNoteService extends BaseService<CreditNote> {
    modelName = 'creditNotes';

    constructor(injector: Injector) {
        super(injector);
    }

    send(
        id: string,
        sendUser: boolean = true,
        sendMe: boolean = false,
        additionalEmails?: string[]
    ): Observable<CreditNote> {
        return this.http.post<CreditNote>(`/api/${this.modelName}/${id}/send`, {
            sendUser,
            sendMe,
            additionalEmails,
        });
    }
}
