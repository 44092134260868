/** Angular Imports */
import { Component, Input, OnInit } from '@angular/core';

/** Modules Imports */
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faKey, faSave } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Services Imports */
import { AuthService } from 'client/components/auth/auth.service';
import { UserService } from 'client/services/user.service';

// *******************************************************************************
//

@Component({
    selector: 'totp-modal',
    templateUrl: './totp.modal.html',
    styleUrls: ['./totp.modal.scss'],
})
export class TOTPModal implements OnInit {
    @Input() public mfa: { enrolled: boolean };
    @Input() public forced = false;
    @Input() public disableCancel = false;

    faKey = faKey;
    faApple = faApple;
    faAndroid = faAndroid;
    faCopy = faCopy;
    faSave = faSave;

    errorStates: {
        totpFail: boolean;
        totpEnrollFail: boolean;
    } = {
        totpFail: false,
        totpEnrollFail: false,
    };
    qrImage: string;
    key: string;
    verifyOTP: string;
    OTP: string;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        // TODO: Focus target <09-10-20, Liaan> //
        if (this.mfa.enrolled) {
            //Focus OTP
        } else {
            //Focus OTP
        }
    }

    requestSetup() {
        if (!this.mfa.enrolled) {
            //this.$http.get('/api/users/mfasetup').then( (res) => {
            this.userService
                .getMFASetup()
                .toPromise()
                .then((data) => {
                    if (data && data.qrImage && data.key) {
                        this.qrImage = data.qrImage;
                        this.key = data.key;
                    } else {
                        throw new Error('Setup failed');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    verifyCode() {
        // TODO: Validate code is valid  <22-01-20, Liaan> //
        return this.userService
            .MFASetup(this.verifyOTP)
            .toPromise()
            .then((data) => {
                if (data) {
                    return this.mfaLogin(this.verifyOTP);
                }
            })
            .catch((err) => {
                this.errorStates.totpEnrollFail = true;
            });
    }

    mfaLogin(code) {
        const self = this;
        return this.authService
            .mfaLogin({ code: code })
            .then(() => {
                //Reauth primus
                //
                //If initial login - redirect else reload window
            })
            .then(() => {
                if (this.activeModal) {
                    this.activeModal.close();
                }
            })
            .catch((err) => {
                console.error(err);
                this.errorStates.totpFail = true;
                return null;
            });
    }

    copyKey(element) {
        element.select();
        document.execCommand('copy');
        element.setSelectionRange(0, 0);
    }

    ok() {}

    doLog() {
        console.debug(this);
    }

    cancel() {
        this.activeModal.close();
    }
}
