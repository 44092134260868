// @flow
import { Injectable, Injector } from '@angular/core';
import { ItemService } from './item.service';

import type { DiscountFrontend as Discount } from '../../server/api/discount/discount';
export type { DiscountFrontend as Discount } from '../../server/api/discount/discount';

@Injectable()
export class DiscountService extends ItemService<Discount> {
    modelName = 'discounts';

    constructor(injector: Injector) {
        super(injector);
    }
}
