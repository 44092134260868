/** Angular Imports */
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/** Modules Imports */
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/** Services Imports */
import { AuthService } from '../auth/auth.service';

// *******************************************************************************
//

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers = req.headers;
        if (
            this.authService.currentAccount &&
            this.authService.currentAccount.ref
        ) {
            headers = headers.append(
                'X-JS-ACCOUNT',
                this.authService.currentAccount.ref
            );
        }

        let params = req.params;
        const signed_token = this.route.snapshot.queryParams.signed_token;
        if(signed_token) {
            params = params.append('signed_token', signed_token)
        }

        const newReq = req.clone({
            headers: headers,
            params: params
        });
        return next.handle(newReq).pipe(
            tap(
                () => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status == 307 || err.status == 401) {
                            this.authService.logout(
                                this.router.getCurrentNavigation()?.extractedUrl
                                    .queryParams['returnUrl']
                            );
                        }
                        return;
                    }
                }
            )
        );
    }
}
