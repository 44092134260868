/** Angular Imports */
import {
    AfterViewInit,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

/** Modules Imports */
import { Ability } from '@casl/ability';
import {
    faBadgePercent,
    faBoxesStacked,
    faClockRotateLeft,
    faCogs,
    faFileInvoiceDollar,
    faGaugeLow,
    faHandHoldingDollar,
    faKey,
    faList,
    faMoneyCheckDollar,
    faPenToSquare,
    faPuzzle,
    faPuzzlePiece,
    faPuzzlePieceAlt,
    faRotateRight,
    faUserFriends,
} from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';

/** Services Imports */
import { AppService } from '../../app.service';
import { AuthService } from '../../../components/auth/auth.service';
import { LayoutService } from '../layout.service';
import { LogoService } from '../../../components/logoManager/logoManager.service';

// *******************************************************************************
//

interface Account {
    name: string;
    ref: string;
}

@Component({
    selector: 'app-layout-sidenav',
    templateUrl: './layout-sidenav.component.html',
    styles: [':host { display: block; }'],
})
export class LayoutSidenavComponent implements AfterViewInit, OnInit {
    /* -------------------------------------------------------------------------- */
    /*                                 Properties                                 */
    /* -------------------------------------------------------------------------- */
    @Input() orientation = 'vertical';

    @HostBinding('class.layout-sidenav') hostClassVertical = false;
    @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
    @HostBinding('class.flex-grow-0') hostClassFlex = false;

    /* ---------------------------------- Icons --------------------------------- */
    faBadgePercent = faBadgePercent;
    faBoxesStacked = faBoxesStacked;
    faClockRotateLeft = faClockRotateLeft;
    faCogs = faCogs;
    faCostCentres = faMoneyCheckDollar;
    faFileInvoiceDollar = faFileInvoiceDollar;
    faGaugeLow = faGaugeLow;
    faHandDollar = faHandHoldingDollar;
    faKey = faKey;
    faList = faList;
    faPenToSquare = faPenToSquare;
    faPuzzle = faPuzzle;
    faPuzzlePiece = faPuzzlePiece;
    faPuzzlePieceAlt = faPuzzlePieceAlt;
    faRotateRight = faRotateRight;
    faUserFriends = faUserFriends;

    /* --------------------------------- General -------------------------------- */
    currentAccountId: string;
    currentAccount: Account;
    accountImage: string;
    hasAccountImage: Observable<boolean>;

    /* -------------------------------------------------------------------------- */
    /*                                 Constructor                                */
    /* -------------------------------------------------------------------------- */
    constructor(
        private router: Router,
        private appService: AppService,
        private layoutService: LayoutService,
        private ability: Ability,
        private authService: AuthService,
        private http: HttpClient,
        public logoService: LogoService
    ) {
        // Set host classes
        this.ability.update(this.authService.abilityRules);
        this.hostClassVertical = this.orientation !== 'horizontal';
        this.hostClassHorizontal = !this.hostClassVertical;
        this.hostClassFlex = this.hostClassHorizontal;
    }

    /* -------------------------------------------------------------------------- */
    /*                               Init Functions                               */
    /* -------------------------------------------------------------------------- */
    ngOnInit() {
        this.currentAccount = this.authService.currentAccount;
        this.hasAccountImage = this.checkAccountImage();
    }

    checkAccountImage(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.http
                .get(`api/accounts/${this.currentAccount.ref}/image`, {
                    responseType: 'blob',
                })
                .subscribe(
                    (res) => {
                        res.arrayBuffer().then((buff) => {
                            this.accountImage = String.fromCharCode.apply(
                                null,
                                new Uint8Array(buff)
                            );
                        });
                        observer.next(true);
                    },
                    (err) => {
                        observer.next(false);
                    },
                    () => observer.complete()
                );
        });
    }

    ngAfterViewInit() {
        // Safari bugfix
        this.layoutService._redrawLayoutSidenav();
        this.currentAccountId = this.authService.currentAccount.ref;
    }

    getClasses() {
        let bg = this.appService.layoutSidenavBg;

        if (
            this.orientation === 'horizontal' &&
            (bg.includes(' sidenav-dark') || bg.includes(' sidenav-light'))
        ) {
            bg = bg
                .replace(' sidenav-dark', '')
                .replace(' sidenav-light', '')
                .replace('-darker', '')
                .replace('-dark', '');
        }

        return `${
            this.orientation === 'horizontal' ? 'container-p-x ' : ''
        } bg-${bg}`;
    }

    isActive(url) {
        return this.router.url.includes(url);
    }

    isMenuActive(url) {
        let includes = false;
        if (Array.isArray(url)) {
            url.forEach((url) => {
                if (this.router.url.includes(url)) includes = true;
            });
        } else {
            includes = this.router.url.includes(url);
        }

        return includes;
    }

    isMenuOpen(url) {
        let includes = false;
        if (Array.isArray(url)) {
            url.forEach((url) => {
                if (this.router.url.includes(url)) includes = true;
            });
        } else {
            includes = this.router.url.includes(url);
        }

        return includes && this.orientation !== 'horizontal';
    }

    toggleSidenav() {
        this.layoutService.toggleCollapsed();
    }
}
