// @flow
import { Injectable, Injector } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { BaseService } from './base.service';

import type { ItemFrontend as Item } from 'server/api/item/item';
export type {
    ItemFrontend as Item,
    Price,
    FilterOptions,
} from 'server/api/item/item';

@Injectable()
export class ItemService<T extends Item> extends BaseService<T> {
    modelName = 'items';

    constructor(injector: Injector) {
        super(injector);
    }

    updateGroups(items: T[], updateFilters: boolean) {
        return lastValueFrom(
            this.http.patch(`/api/${this.modelName}/groups`, {
                items,
                updateFilters,
            })
        );
    }

    /**
     * Saves sorting groups for a list of items.
     *
     * @param sortingGroups - The item sorting groups object.
     * @returns A promise that resolves when the groups have been updated.
     */
    saveSortingGroups(sortingGroups) {
        const send = [];
        for (const key in sortingGroups) {
            let group = sortingGroups[key];
            for (let i = 0; i < group.length; i++) {
                send.push({ _id: group[i]._id, groupName: key, groupIndex: i });
            }
        }

        return this.http.patch(`/api/${this.modelName}/sortingGroups`, {
            items: send,
        });
    }
}
