// @flow
import { Injectable, Injector } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { BaseService } from './base.service';

import type { Model } from './base.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class CostCentreService extends BaseService<Model> {
    modelName = 'costCentres';

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the list of unique cost centre names from the server.
     *
     * @returns A promise resolving to a list of unique cost centres.
     */
    names(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(`/api/${this.modelName}`));
    }

    /**
     * Gets a list of invoices for a specific cost centre.
     *
     * @param costCentre - The cost centre to get invoices for.
     * @param startDate - The startDate to fetch invoices from.
     * @param endDate - The endDate to fetch invoices to.
     * @returns A promise resolving to a list of invoices.
     */
    invoices(costCentre: string, startDate: string, endDate: string) {
        let params = new HttpParams();
        params = params.set('startDate', startDate);
        params = params.set('endDate', endDate);
        return lastValueFrom(
            this.http.get<any[]>(
                `/api/${this.modelName}/invoices/${costCentre}`,
                { params }
            )
        );
    }

    /**
     * Exports invoices to be imported into Xero.
     *
     * @param startDate The date to export invoices from.
     * @param endDate The date to export invoices to.
     * @returns Downloads a .csv file with the exported invoices.
     */
    exportInvoices(
        costCentre: string,
        startDate: string,
        endDate: string
    ): Promise<Blob> {
        let contentType = 'text/csv';

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: contentType,
        });
        return lastValueFrom(
            this.http.post<Blob>(
                `/api/${this.modelName}/export/${costCentre}`,
                { startDate, endDate },
                {
                    headers,
                    responseType: 'blob' as 'json',
                }
            )
        );
    }

    /**
     * Marks invoices as having been paid for a cost centre.
     *
     * @param invoices - The list of invoices to mark as paid.
     * @param ref - The payment reference.
     * @returns A promise resolving when the invoices have been updated.
     */
    markPaid(invoices: any[], ref: string) {
        return lastValueFrom(
            this.http.post<any>(`/api/${this.modelName}/markPaid`, {
                ref,
                invoices,
            })
        );
    }
}
